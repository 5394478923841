import Config from "@/Configuration/Config";
import axios from 'axios'

export default {
    allUrl: Config.apiBaseUrl + "customer/getcustomers?clientId=",
    activateUrl: Config.apiBaseUrl + "customer/activate?customerId=",
    deactivateUrl: Config.apiBaseUrl + "customer/deactivate?customerId=",
    addUrl: Config.apiBaseUrl + "customer/add",
    updateUrl: Config.apiBaseUrl + "customer/update",
    deleteUrl: Config.apiBaseUrl + "customer/delete?customerId=",
    getAppUsersUrl: Config.apiBaseUrl + "syncappuser/getunlinkedusers?clientGuid=",
    syncUrl: Config.apiBaseUrl + "customer/sync?customerId=",
    getCustomerUrl: Config.apiBaseUrl + "customer/getcustomer?customerId=",
    unlinkUrl: Config.apiBaseUrl + "customer/unlink?customerId=",
    addAccountUrl: Config.apiBaseUrl + "customer/addaccount",
    deleteAccountUrl: Config.apiBaseUrl + "customer/deleteaccount?accountId=",
    getAccountsUrl: Config.apiBaseUrl + "customer/getaccounts?customerId=",
    getUserTransactionsUrl: Config.apiBaseUrl + "customer/getusertransactions?userId=",
    downloadTransactionsUrl: Config.apiBaseUrl + "customer/transactions/download",
    all(filter) {
        return axios.get(this.allUrl + filter.clientId + "&status=" + filter.status + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    add(customer) {
        return axios.post(this.addUrl, customer)
    },
    update(customer) {
        return axios.put(this.updateUrl, customer)
    },
    activate(customerId) {
        return axios.get(this.activateUrl + customerId);
    },
    deActivate(customerId) {
        return axios.get(this.deactivateUrl + customerId);
    },
    delete(customerId) {
        return axios.delete(this.deleteUrl + customerId)
    },
    getAppUsers(clientId) {
        return axios.get(this.getAppUsersUrl + clientId)
    },
    sync(customerId, userId) {
        return axios.get(this.syncUrl + customerId + "&userId=" + userId)
    },
    getCustomer(customerId) {
        return axios.get(this.getCustomerUrl + customerId)
    },
    unlink(customerId) {
        return axios.get(this.unlinkUrl + customerId);
    },
    addAccount(account) {
        return axios.post(this.addAccountUrl, account)
    },
    getAccounts(filter) {
        return axios.get(this.getAccountsUrl + filter.customerId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize)
    },
    deleteAccount(accId) {
        return axios.delete(this.deleteAccountUrl + accId)
    },
    getTransactions(filter) {

        return axios.get(this.getUserTransactionsUrl + filter.userId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize)
    },
    donwnloadTransactions(model) {
        return axios.post(this.downloadTransactionsUrl, model)
    }
}