import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    allurl: Config.apiBaseUrl + "note/getnotes?proId=",
    getallurl: Config.apiBaseUrl + "note/getnotes?proId=",
    geturl: Config.apiBaseUrl + "note/getnotebyid?noteId=",
    getbyPropertyUrl: Config.apiBaseUrl + "note/getnotebypropertyid?propertyId=",

    addurl: Config.apiBaseUrl + "note/add",
    updateurl: Config.apiBaseUrl + "note/update",
    deleteurl: Config.apiBaseUrl + "note/delete?noteId=",
    getAllCategoriesUrl: Config.apiBaseUrl + "notescategory/getnotescategory?clientId=",
    getAllNotesCategories: Config.apiBaseUrl + "notescategory/all?clientId=",
    all(filter) {
        return axios.get(this.allurl + filter.propertyId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll(proId) {
        return axios.get(this.getallurl + proId + "&limit=1500");
    },
    getbyId(id) {
        return axios.get(this.geturl + id);
    },
    add(note) {
        return axios.post(this.addurl, note);
    },
    update(note) {
        return axios.put(this.updateurl, note);
    },
    delete(noteId) {
        return axios.delete(this.deleteurl + noteId);
    },
    getAllCategories(filter) {
        return axios.get(this.getAllCategoriesUrl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getByPropertyId(propertyId) {
        return axios.get(this.getbyPropertyUrl + propertyId);
    },
    getNotesCategories(clientId) {
        return axios.get(this.getAllCategoriesUrl+clientId + "&limit=100&");

    },
    getClientNoteCategories(clientId) {
        return axios.get(this.getAllNotesCategories + clientId);
    }
}