<template>
  <div class="container-fluid">
    <div class="d-flex">
      <ToolBar :show-bread="false" clear-id="customerId"
               :showSelect="false" :ShowAddBtn="false" routeName="contact"
               :btnText="getMessageByCode('add_contact') ?? 'Add Contact'">
        <template v-slot:select>
          <div v-if="clientId === null || clientId === 0" class="d-flex flex-column flex-grow-1">
            <span class="text-small">{{ getMessageByCode('client') ?? 'Client' }}</span>
            <el-select  class="w-100" filterable @change="getRents()"
                       v-model="filter.clientId">
              <el-option v-for="item in clients"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
                <template #default>
                  <div class="row">
                    <el-tooltip v-if="item.name.length > 20" :content="item.name">
                      <span class="col-7">{{ getClientName(item.name) }}</span>
                    </el-tooltip>
                    <span v-else class="col-7">{{ getClientName(item.name) }}</span>
                    <span class="text-small col-5">{{ item.market }}</span>
                  </div>
                </template>
              </el-option>
            </el-select>
          </div>
        </template>
      </ToolBar>
    </div>
    <div class="row justify-content-end">
      <div class="col-12 col-md-6 d-flex">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-small">{{ getMessageByCode('month') ?? 'Month' }}</span>
          <el-date-picker
              v-model="selectedMonth"
              type="month"
              class="w-100"
              :editable="false"
              :clearable="false"
              @change="changeMonth()"
          />
        </div>
        <div class="d-flex flex-column flex-grow-1 ms-2">
          <span class="text-small">{{ getMessageByCode('status') ?? 'Status' }}</span>
          <el-select v-model="filter.status" class="w-100" @change="getRents">
            <el-option v-for="status in statuses" :key="status.value" :label="status.text"
                       :value="status.value"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <el-table :data="rents"
                :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe>
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              size="small"
          >
          </el-pagination>
        </template>
        <el-table-column :label="getMessageByCode('tenant') ?? 'Tenant'" prop="tenant" min-width="200"
                         sortable>
        </el-table-column>
        <el-table-column sortable :label="getMessageByCode('property') ?? 'Property'" prop="property" min-width="200"
                         >
        </el-table-column>
        <el-table-column sortable :label="getMessageByCode('rentDate') ?? 'Rent Date'" prop="rentDate" min-width="200">
          <template #default="scope">
            <span>{{ getFormattedDate(scope.row.rentDate) }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable :label="getMessageByCode('stats') ?? 'Status'" prop="status" min-width="200">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.isPaid">Paid</el-tag>
            <el-tag type="danger" v-else>Unpaid</el-tag>
          </template>
        </el-table-column>

        <!--      <el-table-column align="center" fixed="right" :label="getMessageByCode('actions') ?? 'Actions'" width="100">
                <template #default="scope">
                  <div class="d-flex justify-content-center">
                    &lt;!&ndash;                  <el-tooltip content="View">&ndash;&gt;
                    &lt;!&ndash;                    <i @click="takeRow(scope.row, 'view')"&ndash;&gt;
                    &lt;!&ndash;                       class="mgc_eye_2_line text-primary action-btn"&ndash;&gt;
                    &lt;!&ndash;                    ></i>&ndash;&gt;
                    &lt;!&ndash;                  </el-tooltip>&ndash;&gt;
                    <el-tooltip content="Edit">
                      <i @click="takeRow(scope.row, 'edit')"
                         class="mgc_edit_line text-primary action-btn ms-2"
                      ></i>
                    </el-tooltip>
                    <el-tooltip content="Delete">
                      <i @click="takeRow(scope.row, 'delete')"
                         class="mgc_delete_2_line text-danger action-btn ms-2"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>-->
      </el-table>
    </div>


    <n-spin v-if="loading" size="small" stroke="#000" :stroke-width="15" description="Loading"
            class="cstm-spin"></n-spin>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getLanguage} from "@/Utility/getLanguage";
import clientService from "@/Services/ComponentsServices/clientService";
import {NSpin} from "naive-ui";
import {ShowMessage} from "@/Utility/Utility";
import configService from "@/Services/ComponentsServices/configService";
import moment from "moment";
import optionService from "@/Services/ComponentsServices/optionService";
import pmsRentService from "@/Services/ComponentsServices/pmsRentService";
import getClientName from "../../Utility/getClientName";

export default {
  name: "CustomersList",
  components: {NSpin, ToolBar},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      translations: [],
      monthRange: [
        new Date(), new Date(),
      ],
      clients: [],
      rents: [],
      dateFormat: "DD/MM/YYYY",
      statuses: [
        {text: "All", value: -1},
        {text: "Paid", value: 1},
        {text: "Unpaid", value: 0},
      ],
      rent: {
        "id": 0,
        "tenantId": 0,
        "propertyId": 0,
        "rentDate": new Date(),
        "rent": 0,
        "status": 0
      },
      selectedMonth: new Date(),
      clientId: Number(sessionStorage.getItem("clientId")) ?? 0,
      filter: {
        clientId: Number(sessionStorage.getItem("clientId") ?? 0),
        month: 1,
        tenantId: 0,
        propertyId: 0,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1,
        clientGuid: 0,
        category: 0,
      },
    }
  },
  methods: {
    getClientName,
    changeMonth() {
      console.log(this.selectedMonth.getMonth())
      this.filter.month = this.selectedMonth.getMonth();
      this.getRents();
    },
    getFormattedDate(date) {
      return moment(date).format(this.dateFormat)
    },
    takeRow(rent, type) {
      this.rent = JSON.parse(JSON.stringify(rent));
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;

      }
    },
    async addRent() {
      this.addDialog = false;
      this.loading = true;
      try {
        let response = await pmsRentService.addRent(this.rent)
        if (response?.data !== null) {
          ShowMessage("success", "Rent Added");
          await this.getRents();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Rent Contact Failed");
      }
      this.loading = false;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getRents();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getRents();
    },
    async getRents() {
      this.loading = true;
      try {
        let response = await pmsRentService.getRents(this.filter);
        this.rents = response?.data?.items ?? [];
      } catch (e) {
        ShowMessage("error", e?.response?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async getClients() {
      try {
        this.loading = true;
        if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
          let response = await clientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            this.filter.clientGuid = this.clients[0].clientGuid;
            this.filter.clientId = this.clients[0].id;
            await this.getRents();
          }
        }

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async update() {
      this.loading = true;
      this.addDialog = false;
      try {
        let response = await pmsRentService.updateRent(this.rent)
        if (response?.data?.id > 0) {
          ShowMessage("success", "Rent Updated")
          await this.getRents();
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },

    async deleteContact() {
      this.loading = true;
      this.deleteDialog = false;
      try {
        let response = await pmsRentService.deleteRent(this.rent.id);
        if (response?.data) {
          ShowMessage("success", "Rent Deleted");
          await this.getRents();
        }
      } catch (e) {
        ShowMessage("error", "Rent delete failed");
        console.log(e)
      }
      this.loading = false;
    },

    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }

        let response3 = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetContact() {
      this.rent = {
        "id": 0,
        "tenantId": this.tenants[0].id,
        "propertyId": this.properties[0].id,
        "rentDate": new Date(),
        "rent": 0,
        "status": 0
      };
      if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
        if (this.clients.length > 0) {
          this.rent.clientId = this.clients[0].id;
        }
      }
    },
    async getListOptions() {
      try {
        this.loader = true;
        let response = await optionService.getOptionsByHeaderId(0, 43)
        this.honorifics = response.data ?? {};
        if (this.honorifics.length > 0) {
          if (this.rent.title === "") {
            this.rent.title = this.honorifics[0].optTitle;
          }
        }
        this.loader = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loader = false;
      }
    },
  },
  async created() {
    await this.getClients();
    await this.getConfigs();
  }
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.el-select__wrapper.is-focused {
  box-shadow: #2A5699 !important;
}
</style>