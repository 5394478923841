<template>
  <div class="footer">
    <div class="text-small">
                    <span>
                      <span>&#169;</span><span>{{ ' ' + product.productName }}</span> <span>{{ ' ' + product.edition }}</span> <span>{{
                        new Date().getFullYear()
                      }}</span><span>{{ ' ' + product.productVersion }}</span>
                    </span>
    </div>
  </div>
</template>
<script>
import loginCoreService from "@/Services/ComponentsServices/loginCoreService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "FooterComp",
  data() {
    return {
      product: {
        "id": 0,
        "productName": "Light CRM",
        "productVersion": "v1.01.1",
        "edition": "UAT",
        "release": 0,
        "build": 0,
        "patch": 0
      },
    }
  },
  methods: {
    async getProduct() {
      try {
        let response = await loginCoreService.getProduct();
        this.product = response?.data ?? {
          "id": 0,
          "productName": "Light CRM",
          "productVersion": "v1.01.1",
          "edition": "UAT",
          "release": 0,
          "build": 0,
          "patch": 0
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "")
      }
    },
  }
}
</script>

<style scoped>
.footer {
  background: #e2e3e5;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 20px;
}
</style>