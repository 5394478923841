<template>
  <div class="container-fluid p-2 p-md-0 h-100 bg-gray">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-md-4 text-center sign-in-col card py-4">
        <div class="mb-3">
          <h3 class="fw-bold">Reset Password</h3>
        </div>
        <div class="col-12 col-md-10 m-0 p-0">
          <el-form ref="myForm" :model="User" require-asterisk-position="right" :rules="formRules" label-position="top">
            <el-form-item label="New password" prop="password">
              <el-input
                  v-model="User.password"
                  :type="showPass ? 'text' : 'password'"
                  size="large"
                  tabindex="2"
              >
                <template #append>
                  <el-button @click="showPass = !showPass">
                    <i v-if="showPass" class="bi bi-eye"></i>
                    <i v-else class="bi bi-eye-slash"></i>
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="Confirm password" prop="confirmPassword">
              <el-input
                  v-model="User.confirmPassword"
                  :type="showCPass ? 'text' : 'password'"
                  size="large"
                  tabindex="2"
                  @keyup.enter="submitForm()"
              >
                <template #append>
                  <el-button @click="showCPass = !showCPass">
                    <i v-if="showCPass" class="bi bi-eye"></i>
                    <i v-else class="bi bi-eye-slash"></i>
                  </el-button>
                </template>
              </el-input>
              <div class="pswd-indicator">
                <span>Check your password strength</span>
                <el-popover
                    placement="top-start"
                    :width="230"
                    trigger="hover"
                    content="this is content, this is content, this is content"
                >
                  <template #reference>
                    <i class="bi bi-info-circle ms-2" style="cursor: pointer"></i>
                  </template>
                  <template #default>
                    <div class="pswd-indicator">
                      <div>
                        <i v-if="User.password.length < 8" class="bi bi-x-circle-fill me-2 text-danger"></i>
                        <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                        <span :class="User.password.length < 8 ? 'text-secondary' : 'text-success'">At least eight characters</span>
                      </div>
                      <div>
                        <i v-if="!specialCh" class="bi bi-x-circle-fill me-2 text-danger"></i>
                        <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                        <span
                            :class="!specialCh ? 'text-secondary' : 'text-success'">At least one special character</span>
                      </div>
                      <div>
                        <i v-if="!hasCapitalChar" class="bi bi-x-circle-fill me-2 text-danger"></i>
                        <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                        <span :class="!hasCapitalChar ? 'text-secondary' : 'text-success'">At least one capital character</span>
                      </div>
                      <div>
                        <i v-if="!hasNumber" class="bi bi-x-circle-fill me-2 text-danger"></i>
                        <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                        <span :class="!hasNumber ? 'text-secondary' : 'text-success'">At least One number</span>
                      </div>
                    </div>
                  </template>
                </el-popover>
              </div>
            </el-form-item>
            <el-form-item class="mt-5">
              <el-button
                  :disabled="loading || !User.password || !User.confirmPassword || !passwordOk()"
                  :loading="loading"
                  class="mbtn-primary rounded-1 w-100"
                  size="large"
                  @click="submitForm"
              >
                {{ loading ? "Changing Password" : "Continue" }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border" style="position: absolute; top: 42%; left: 48%"></div>
  </div>
</template>
<script>
import axios from "axios";
import loginServices from "@/Services/loginServices";
import {ShowMessage} from "@/Utility/Utility";
import {jwtDecode} from "jwt-decode";

export default {
  name: "ResetPassword",
  data() {
    return {
      showPass: false,
      showPassC: false,
      showCPass: false,
      loading: true,
      specialCh: false,
      hasCapitalChar: false,
      hasNumber: false,
      passwordChanged: false,
      retypePassword: "",
      User: {
        userName: "",
        password: "",
        confirmPassword: '',
      },
      formRules: {
        userName: [
          {required: true, message: 'Please enter a username', trigger: 'blur'},
        ],
        password: [
          {required: true, message: 'Please enter a password', trigger: 'blur'},
          {
            min: 6,
            max: 50,
            message: 'Password length should be between 6 and 50 characters',
            trigger: ['blur', 'change'],
          },
        ],
        confirmPassword: [
          {required: true, message: 'Please confirm your password', trigger: ['blur', 'change'],},
          {validator: this.validatePassword, trigger: ['blur', 'change'],},
        ],
      },
    }
  },
  methods: {
    passwordOk() {

      return this.specialCh &&
          this.hasCapitalChar &&
          this.hasNumber &&
          this.User.password.length >= 8 &&
          this.User.password === this.User.confirmPassword;
    },
    containsSpecialCharacters(inputString) {
      const regex = /[^a-zA-Z0-9\s]/;
      const capitalCharRegex = /[A-Z]/;
      const numberRegex = /[0-9]/;

      this.specialCh = regex.test(inputString);
      this.hasCapitalChar = capitalCharRegex.test(inputString);
      this.hasNumber = numberRegex.test(inputString);
    },
    validatePassword(rule, value, callback) {
      if (value === this.User.password) {
        callback();
      } else {
        callback(new Error('Password does not match'));
      }
    },
    submitForm() {
      this.$refs.myForm.validate(valid => {
        if (valid && this.passwordOk()) {
          this.changePassword();
        } else {
          ShowMessage("error", "Please ensure the passwords match and meet the strength requirements.");
        }
      });
    },

    async changePassword() {
      try {
        this.loading = true;

        // eslint-disable-next-line no-unused-vars
        let response = await loginServices.changePassword(this.User);
        console.log(response)
        if(response?.data?.success){
          ShowMessage("success", response?.data?.message ?? "Password change failed, please try again later");
        }
        else {
          ShowMessage("error", response?.data?.message ?? "Password changed successfully you can now login to the Light CRM.");
        }

        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e.message);
        ShowMessage("error", "Something went wrong");
      }
    },
    setUser(username) {
      this.User.userName = username;
    }
  },
  watch: {
    'User.password': function (newVal) {
      this.containsSpecialCharacters(newVal);
    }
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token !== null) {
      const user = jwtDecode(token)
     this.User.userName = user.email;
      console.log(this.User.userName)
      sessionStorage.setItem("token", token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token");
    }
    // Remove the token from the URL
    setTimeout(function () {
      let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({path: newUrl}, '', newUrl);
    }, 1);
  },

}
</script>
<style scoped>

</style>