import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    getUnlinkedUsersUrl: Config.apiBaseUrl + "syncappuser/getappusers?clientId=",
    getLinkedUsersUrl: Config.apiBaseUrl + "syncappuser/getlinkedcustomers?clientId=",
    getFreeCustomersUrl: Config.apiBaseUrl + "syncappuser/getfreecustomers?clientId=",
    verifyGuidUrl: Config.apiBaseUrl + "syncappuser/verifyGuid",
    changePasswordUrl: Config.apiBaseUrl + "syncappuser/changepassword",

    getUnlinkedUsers(filter) {
        return axios.get(this.getUnlinkedUsersUrl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },

    getLinkedUsers(filter) {
        return axios.get(this.getLinkedUsersUrl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getFreeCustomers(clientId) {
        return axios.get(this.getFreeCustomersUrl + clientId)
    },
    verifyGuid(model) {
        return axios.post(this.verifyGuidUrl, model);
    },
    changePassword(model) {
        return axios.post(this.changePasswordUrl, model)
    }

}