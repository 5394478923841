<template>
  <div class="container-fluid">
    <ToolBar
        :show-bread="false"
        :options="SelectOptions"
        @selection-changed="getCompliances"
        :showSelect="true"
        :ShowAddBtn="complianceCreate"
        @addbtn-clicked="methodCalled"
        :btnText="getMessageByCode('add')??'Add'">
      <template v-slot:select>
        <el-select v-if="clientId< 1" class="w-100" filterable @change="ChangeClient" v-model="filter.clientId">
          <el-option v-for="item in Clients"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
            <template #default>
              <div class="row">
                <el-tooltip v-if="item.name.length > 20" :content="item.name">
                  <span class="col-7">{{ getClientName(item.name) }}</span>
                </el-tooltip>
                <span v-else class="col-7">{{ getClientName(item.name) }}</span>
                <span class="text-small col-5">{{ item.market }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="d-flex justify-content-end">
            <el-button class="bg-danger text-white fw-normal me-2 border-0"
                       @click="$store.state.deleteDialog = !$store.state.deleteDialog"
                       v-if="selectedIds.length > 1">
              <i class="bi bi-trash me-2"></i>
              {{ selectedIds.length }} selected
            </el-button>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-2 ms-auto d-flex justify-content-end">
          <el-input class="align-self-end" :placeholder="getMessageByCode('search')??'Search here..'"
                    @clear="getCompliances" v-model="filter.search"
                    @keyup="getCompliances"
                    clearable>
            <template #append>
              <el-button @click="getCompliances">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <el-table
              stripe
              style="width: 100%"
              header-cell-class-name="tbl-header"
              :data="Compliances"

              @selection-change="handleSelectionChange"
          >
            <template #append>
              <div class="d-md-flex justify-content-between align-items-center">
                <PaginationComp
                    @size-changed="handleSizeChange"
                    @page-changed="handleCurrentChange"
                    :total="filter.totalCount"
                    :page="filter.currentPage"
                    :page-size="filter.pageSize"
                />
                <el-tooltip
                    class="box-item"
                    effect="light"
                    content="Refresh"
                    placement="bottom"
                >
                  <el-button class="me-5 border-0 btn-gray refresh-btn" @click="getCompliances">
                    <span class="bi bi-arrow-counterclockwise"></span>
                  </el-button>
                </el-tooltip>
              </div>
            </template>
            <el-table-column type="selection"/>
            <el-table-column sortable min-width="200" class="text-capitalize" prop="title" :label="getMessageByCode('title')?? 'Title'"/>
            <el-table-column sortable min-width="180" class="text-capitalize" align="right" prop="defaulRenewType" :label="getMessageByCode('ren_type')?? 'Renew Type'">
              <template #default="scope">
                <span>{{ RenewTypeTitle(scope.row.defaulRenewType) }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable min-width="180" class="text-capitalize" align="right" prop="defaultRenewValue" :label="getMessageByCode('ren_val')?? 'Renew Value'">
              <template #default="scope">
                <span>{{ RenewValueTitle(scope.row.defaultRenewValue) }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable class="text-capitalize" :label="getMessageByCode('default')?? 'Default'" align="center">
              <template #default="scope">
                <el-tag class="btn btn-sm btn-s-secondary" v-if="scope.row.isDefault">Yes</el-tag>
                <el-tag class="btn btn-sm btn-d-secondary" v-else>No</el-tag>
              </template>
            </el-table-column>
            <el-table-column sortable min-width="100" class="text-capitalize" :label="getMessageByCode('required')?? 'Required'" align="center">
              <template #default="scope">
                <el-tag class="btn btn-sm btn-s-secondary" v-if="scope.row.isRequired">Yes</el-tag>
                <el-tag class="btn btn-sm btn-d-secondary" v-else>No</el-tag>
              </template>
            </el-table-column>
            <el-table-column sortable min-width="100" class="text-capitalize" :label="getMessageByCode('visible')?? 'Visible'" align="center">
              <template #default="scope">
                <el-tag class="btn btn-sm btn-s-secondary" v-if="scope.row.isVisibleToProvider">Yes</el-tag>
                <el-tag class="btn btn-sm btn-d-secondary" v-else>No</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                :label="getMessageByCode('actions')?? 'Actions'"
                align="center"
                width="100"
                v-if="complianceDel || complianceEdit"
            >
              <template #default="scope">
                <el-space spacer="|" alignment="center" >
                  <i v-show="complianceEdit" @click="editCompliance(scope.row,'edit')" class="mgc_edit_line text-primary action-btn"></i>

                  <i v-show="complianceDel"
                      class="mgc_delete_2_line text-danger action-btn"
                      @click="editCompliance(scope.row,'del')"
                  ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border"></div>
    <AddCompliance @addSuccess="getCompliances()" :compliance-id="complianceId"/>
    <DeleteCompliance :selectedIds="selectedIds" :title="complianceName" @delete-success="getCompliances"/>

    <el-dialog
        v-model="deleteDialog"
        class="text-capitalize"
        :title="getMessageByCode('delete')+' '+getMessageByCode('compl')"
        width="30%"
    >
      <span>{{getMessageByCode('do_u_del')??'Do you want to delete'}} {{ Compliance?.title }}</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteDialog = false">{{getMessageByCode('cancel') ??'Cancel' }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteDialog = false, deleteCompliance()">
          {{getMessageByCode('delete') ??'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import PaginationComp from "@/components/UI/Pagination";
import AddCompliance from "@/components/ComponentsDialogs/AddCompliance";
import complianceService from "@/Services/ComponentsServices/complianceService";
import ClientService from "@/Services/ComponentsServices/clientService";
import optionService from "@/Services/ComponentsServices/optionService";
import DeleteCompliance from "@/components/Compliance/Delete/DeleteCompliance.vue";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";
import {ShowMessage} from "@/Utility/Utility";
import getClientName from "../../Utility/getClientName";

export default {
  name: "ComplianceList",
  components: {DeleteCompliance, AddCompliance, PaginationComp, ToolBar},
  data() {
    return {
      clientId: sessionStorage.getItem("clientId"),
      complianceCreate: false,
      deleteDialog: false,
      addDialog: false,
      complianceDel: false,
      complianceEdit: false,
      complianceId: 0,
      Compliances: [],
      complianceName: "",
      selectedIds: [],
      translations: [],
      RenewTypes: [],
      RenewValues: [],
      Clients: [],
      showDialog: false,
      loading: false,
      Compliance: {},
      SelectOptions: [
        {label: "All", value: 1},
        {label: "Active", value: 2},
        {label: "InActive", value: 3},
      ],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    };
  },
  methods: {
    getClientName,
    RenewTypeTitle(optId) {
      const renewType = this.RenewTypes.find(type => type.optId === optId);
      return renewType ? renewType.optTitle : 'Not found';
    },
    RenewValueTitle(optId) {
      const renewType = this.RenewValues.find(type => type.optId === optId);
      return renewType ? renewType.optTitle : 'Not found';
    },
    ChangeClient() {
      this.getCompliances();
    },
    methodCalled() {
      this.complianceId = 0;
      this.$store.state.addCompDlg = true;

    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },

    handleSizeChange(newSize) {
      this.$store.state.filter.pageSize = newSize;
      this.getCompliances();
    },
    handleCurrentChange(newPage) {
      this.$store.state.filter.currentPage = newPage;
      this.getCompliances();
    },
    editCompliance(row, type) {
      this.Compliance = {...row};
      if(type === 'del'){
        this.deleteDialog = true;
      }
      if(type === 'edit'){
        this.addDialog = true;
      }
    },
    async deleteCompliance() {
      try {
        // eslint-disable-next-line no-unused-vars
         let response = await complianceService.deleteCompliance(this.Compliance.id);
          ShowMessage("success", "Compliance Deleted Successfully");

        this.getCompliances();
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
    async getCompliances() {
      try {
        this.loading = true;
        let response = await complianceService.all(this.filter)
        this.Compliances = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage =response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;

        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          this.$store.state.loading = true;
          let response = await ClientService.getAll();
          this.Clients = response.data.items ?? [];
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
          }
        }
        this.getCompliances();
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    async getRenewValues() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          let response = await optionService.getOptionsByHeaderId(this.filter.clientId,24)
          this.RenewValues = response.data ?? [];
          if (this.RenewValues.length > 0) {
            this.Compliance.defaultRenewValue = this.RenewValues[0].optId;
          }
        }
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    async getRenewTypes() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          let response = await optionService.getOptionsByHeaderId(this.filter.clientId,25)
          this.RenewTypes = response.data ?? [];
          if (this.RenewTypes.length > 0) {
            this.Compliance.defaulRenewType = this.RenewTypes[0].optId;
          }
        }
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.complianceDel = checkPermission("MD_COMP_DEL");
    this.complianceCreate = checkPermission("MD_COMP_CREATE");
    this.complianceEdit = checkPermission("MD_COMP_EDIT");
    this.getClients();
    this.getRenewValues();
    this.getRenewTypes();
  },
};
</script>

<style scoped></style>
