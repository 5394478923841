<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false"
             :showSelect="false" :ShowAddBtn="false" routeName="agreements"
             :btnText="getMessageByCode('add') ?? 'Add'">
      <template v-slot:select>
        <div class="d-flex w-100 align-items-end justify-content-end">
          <div class="w-100 " v-if="clientId === null || clientId === 0">
            <span class="text-small">{{ getMessageByCode('client') ?? 'Client' }}</span>
            <el-select class="w-100" filterable @change="getTenants()"
                       v-model="filter.clientId">
              <el-option
                  v-for="item in clients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
                <template #default>
                  <div class="row">
                    <el-tooltip v-if="item.name.length > 20" :content="item.name">
                      <span class="col-7">{{ getClientName(item.name) }}</span>
                    </el-tooltip>
                    <span v-else class="col-7">{{ getClientName(item.name) }}</span>
                    <span class="text-small col-5">{{ item.market }}</span>
                  </div>
                </template>
              </el-option>

            </el-select>
          </div>
          <el-button class="ms-2" @click="addDialog = true;resetAgreement();" type="primary">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
        </div>
      </template>
    </ToolBar>

    <div class="row justify-content-end align-items-center py-1">
      <div class="col-12 col-md-4 d-flex justify-content-end align-items-end">
        <div class="w-100">
          <span class="text-small">{{ getMessageByCode('tenant') ?? 'Tenant' }}</span>
          <el-select v-model="filter.tenantId" filterable class="w-100"
                     placeholder="Select Contact" @change="getAgreements()"
          >
            <el-option
                v-for="item in tenants"
                :key="item.id"
                :label="getTenantName(item)"
                :value="item.id"
            />
          </el-select>
        </div>

      </div>
    </div>
    <el-table :data="agreements" :default-sort="{ prop: 'name', order: 'ascending' }"
              :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
              header-cell-class-name="tbl-header"
              stripe>
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            size="small"
        >
        </el-pagination>
      </template>
      <el-table-column show-overflow-tooltip sortable class="text-capitalize"
                       :label="getMessageByCode('tenant') ?? 'Tenant'" prop="tenant"
                       min-width="150">
        <template #default="scope">
          <span class="text-primary text-decoration-underline action-btn" style="font-size: 13px !important;"
                @click="goToTenant(scope.row.tenantId)">{{ scope.row.tenant }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip sortable class="text-capitalize"
                       :label="getMessageByCode('property') ?? 'Property'"
                       prop="property"
                       min-width="200">
        <template #default="scope">
          <span class="text-primary text-decoration-underline action-btn" style="font-size: 13px !important;"
                @click="goToProperty(scope.row.propertyId)">{{ scope.row.property }}</span>
        </template>
      </el-table-column>
      <el-table-column sortable class="text-capitalize" :label="getMessageByCode('city') ?? 'City'" prop="city"
                       width="120"></el-table-column>
      <el-table-column sortable class="text-capitalize" :label="getMessageByCode('postCode') ?? 'Post Code'"
                       prop="postCode"
                       width="120"></el-table-column>
      <el-table-column sortable width="100" class="text-capitalize"
                       :label="getMessageByCode('active') ?? 'Active'"
                       prop="isActive"
                       min-width="200">
        <template #default="scope">
          <div class="text-center">
            <el-tag size="small" type="success" v-if="scope.row.isActive">Yes</el-tag>
            <el-tag size="small" type="danger" v-else>No</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable width="140" class="text-capitalize"
                       :label="getMessageByCode('tenancyStart') ?? 'Tenancy Start'"
                       prop="tenancyStart">
        <template #default="scope">
          <span>{{ getFormattedDate(scope.row.startDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column sortable width="130" class="text-capitalize"
                       :label="getMessageByCode('tenancyEnd') ?? 'Tenancy End'"
                       prop="tenancyEnd">
        <template #default="scope">
          <span>{{ getFormattedDate(scope.row.endDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column sortable width="100" class="text-capitalize"
                       :label="getMessageByCode('rent') ?? 'Rent'+ ' ('+currency+')'"
                       prop="rent"
                       min-width="200">
        <template #default="scope">
          <div class="text-end">
            <span>{{ getFormattedValue(scope.row.rent) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable class="text-capitalize"
                       :label="getMessageByCode('commission') ?? 'Commission' + ' ('+currency+')'" prop="commission"
                       width="150">
        <template #default="scope">
          <div class="text-end">
            <span>{{ getFormattedValue(scope.row.commission) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" class="text-capitalize"
                       :label="getMessageByCode('actions') ?? 'Actions'" width="130">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <el-tooltip content="Export Pdf">
              <i
                  class="mgc_pdf_line text-warning action-btn"
                  @click="editAgreement(scope.row,'pdf')"
              ></i>
            </el-tooltip>
            <i
                @click="editAgreement(scope.row, 'edit')"
                class="mgc_edit_line text-primary action-btn"

            ></i>

            <i @click="editAgreement(scope.row, 'delete')"
               class="mgc_delete_2_line text-danger action-btn"
            ></i>
          </el-space>
        </template>
      </el-table-column>
    </el-table>


    <!--    Dialogs-->
    <el-dialog v-model="addDialog" style="width: auto; max-width: 700px"
               :title="getMessageByCode('add_agree') ?? 'Add Agreement'" align-center>
      <template #default>
        <el-form label-position="top" require-asterisk-position="right">
          <div class="row">
            <el-form-item v-if="clientId === null || clientId === 0" class="col-12 col-md-6"
                          :label="getMessageByCode('client') ?? 'Client'">
              <el-select v-model="filter.clientId" filterable class="w-100"
                         placeholder="Select" @change="changeClient()"
              >
                <el-option
                    v-for="item in clients"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('tenant') ?? 'Tenant'">
              <el-select v-model="agreement.tenantId" filterable class="w-100"
                         placeholder="Select Tenant"
              >
                <el-option
                    v-for="item in tenants"
                    :key="item.id"
                    :label="getTenantName(item) + ''"
                    :value="item.id"
                    :disabled="item.id < 1"
                >
                  <template #default>
                    <span>{{ getTenantName(item) }}</span> <span v-if="item.activeAgreement"
                                                                 class="text-small fw-normal text-black">(Rented)</span>
                  </template>


                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('property') ?? 'Property'">
              <el-select v-model="agreement.propertyId" filterable class="w-100"
                         placeholder="Select Property"
              >
                <el-option
                    v-for="item in properties"
                    :key="item.id"
                    :label="item.propertyName"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('start_date') ?? 'Start Date'">
              <el-date-picker class="w-100" :format="dateFormat" v-model="agreement.startDate"
                              @change="changeStartDate()"
                              :editable="false"></el-date-picker>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('end_date') ?? 'End Date'">
              <el-date-picker clearable class="w-100" :format="dateFormat" v-model="agreement.endDate"
                              @clear="agreement.endDate = null" @change="changeEndDate()"
                              :editable="false"></el-date-picker>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('leave_date') ?? 'Leave Date'">
              <el-date-picker class="w-100" :format="dateFormat" v-model="agreement.leaveDate"
                              @change="changeLeaveDate()" :editable="false"></el-date-picker>
            </el-form-item>
            <div class="col-12 col-md-3">
             
            </div>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('rent') ?? 'Rent'">
              <el-input type="number" class="w-100" controls-position="right"
                        v-model.number="rent">
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('commission') ?? 'Commission'">
              <el-input type="number" class="w-100" controls-position="right"
                        v-model.number="commission">
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('deposit') ?? 'Deposit'">
              <el-input type="number" class="w-100" controls-position="right"
                        v-model.number="securityDeposit">
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('deposit_day') ?? 'Payment Day'">
              <el-input type="number" class="w-100" controls-position="right" :max="30" v-model="agreement.paymentDay"
                        @input="validatePaymentDay"></el-input>
              <el-collapse-transition>
                <span v-if="paymentDayError" class="text-small text-danger">Payment day must be between 1 and 30</span>
              </el-collapse-transition>
            </el-form-item>
           
            <el-form-item class="col-12" :label="getMessageByCode('notes') ?? 'Notes'">
              <el-input type="textarea" :rows="3" v-model="agreement.notes"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button v-if="agreement.id < 1" type="primary" class="ms-2" @click="addDialog = false; addAgreement()"
                     :disabled="agreement.tenantId < 1 || (agreement.paymentDay < 1 || agreement.paymentDay > 30)">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="addDialog = false; updateAgreement()"
                     :disabled="agreement.tenantId < 1 || (agreement.paymentDay < 1 || agreement.paymentDay > 30)">
            {{ getMessageByCode('update') ?? 'Update' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
        v-model="deleteDialog"
        :title="getMessageByCode('delete') ?? 'Delete' +' '+ getMessageByCode('agreement') ?? 'Agreement'"
        width="30%"
    >
      <template #header>
        <span>{{ getMessageByCode('delete') ?? ' Delete' }} </span>
        <span> {{ getMessageByCode('agreement') ?? ' Agreement' }}</span>
      </template>
      <span>{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }}</span> <span
        class="text-danger"> selected agreement?</span>

      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteDialog = false">{{
            getMessageByCode('cancel') ?? 'Cancel'
          }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteDialog = false; deleteAgreement()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getLanguage} from "@/Utility/getLanguage";
import tenancyAgreementService from "@/Services/ComponentsServices/tenancyAgreementService";
import contactService from "@/Services/ComponentsServices/contactService";
import {ShowMessage} from "@/Utility/Utility";
import clientService from "@/Services/ComponentsServices/clientService";
import configService from "@/Services/ComponentsServices/configService";
import pmsPropertiesService from "@/Services/ComponentsServices/pmsPropertiesService";
import moment from "moment/moment";
import getClientName from "../../Utility/getClientName";

export default {
  name: "TenancyAgreement",
  components: {ToolBar},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      paymentDayError: false,
      translations: [],
      clients: [],
      dateFormat: "DD/MM/YYYY",
      currency: "£",
      currencyPosition: "left",
      agreement: {
        "id": 0,
        "clientId": "",
        "tenantId": "",
        "startDate": new Date(),
        "endDate": new Date(),
        "propertyId": 0,
        "landlordContactId": 0,
        "rent": 0.00,
        "commission": 0.00,
        "paymentDay": 1,
        "notes": "",
        "securityDeposit": 0,
        "leaveDate": null,
        "isActive": true,
      },
      agreements: [],
      contacts: [],
      tenants: [
        {
          "id": 0,
          "firstName": "All",
          "lastName": "",
        }
      ],
      properties: [],
      clientId: Number(sessionStorage.getItem("clientId")) ?? 0,
      filter: {
        clientId: Number(sessionStorage.getItem("clientId") ?? 0),
        contactId: 0,
        tenantId: 0,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1,
        clientGuid: 0,
        category: 0,
        tenantType: 1
      },
    }
  },
  computed: {
    rent: {
      get: function () {
        return this.agreement.rent.toFixed(2)
      },
      set: function (newValue) {
        return this.agreement.rent = newValue
      }
    },
    commission: {
      get: function () {
        return this.agreement.commission.toFixed(2)
      },
      set: function (newValue) {
        return this.agreement.commission = newValue
      }
    },
    securityDeposit: {
      get: function () {
        return this.agreement.securityDeposit.toFixed(2)
      },
      set: function (newValue) {
        return this.agreement.securityDeposit = newValue
      }
    },

  },
  methods: {
    getClientName,
    getFormattedDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format(this.dateFormat);
    },
    goToProperty(propertyId) {
      this.$store.state.pageId = propertyId;
      this.$router.push('/addpmsproperty')
    },
    goToTenant(tenantId) {
      this.$router.push({path: '/contacts', query: {fromTenancy: true, pageId: tenantId}});
    },
    changeLeaveDate() {
      if (this.agreement.leaveDate < this.agreement.startDate) {
        ShowMessage("error", "Leave date cannot be smaller than Start Date");
        this.agreement.leaveDate = new Date();
      }
    },
    changeStartDate() {
      if (this.agreement.startDate > this.agreement.endDate) {
        ShowMessage("error", "Start date cannot be greater than End Date");
        this.agreement.startDate = new Date();
      }
    },
    changeEndDate() {
      if (this.agreement.endDate < this.agreement.startDate) {
        ShowMessage("error", "End date cannot be smaller than Start Date");
        this.agreement.endDate = new Date();
      }
    },
    getFormattedValue(value) {
      return value.toFixed(2);
    },
    editAgreement(agreement, type) {
      this.agreement = JSON.parse(JSON.stringify(agreement));
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
      }
    },
    getTenantName(item) {
      return item.firstName + ' ' + item.surname;
    },
    async changeClient() {
      this.agreement.clientId = Number(this.filter.clientId);
      await this.getTenants();
      await this.getFreeProperties()
    },
    validatePaymentDay() {
      this.paymentDayError = this.agreement.paymentDay > 31;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
    },
    async getAgreements() {
      this.loading = true;
      try {
        let response = await tenancyAgreementService.getTenantAgreements(this.filter);
        this.agreements = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async addAgreement() {
      this.loading = true;
      try {
        let response = await tenancyAgreementService.addAgreement(this.agreement);
        if (response?.data !== null) {
          ShowMessage("success", "Agreement Added");
          await this.getAgreements();
          await this.getFreeProperties();
        }

      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    }, async updateAgreement() {
      this.loading = true;
      try {
        let response = await tenancyAgreementService.updateAgreement(this.agreement);
        if (response?.data !== null) {
          ShowMessage("success", "Agreement Updated");
          await this.getAgreements();
          await this.getFreeProperties();
        }

      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getContacts() {
      this.loading = true;
      try {
        this.filter.contactId = "";
        let response = await contactService.all(this.filter);
        this.contacts = response?.data?.items ?? [];
        if (this.contacts.length > 0) {
          this.agreement.contactId = this.contacts[0].id;

        } else {
          this.agreement.contactId = "";
        }
      } catch (e) {
        ShowMessage("error", e?.response?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async getClients() {
      try {
        this.loading = true;
        if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
          let response = await clientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            this.filter.clientGuid = this.clients[0].clientGuid;
            this.filter.clientId = this.clients[0].id;
            this.agreement.clientId = this.clients[0].id;
          }
        }
        await this.getTenants();
        await this.getFreeProperties();
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getTenants() {
      try {
        let response = await contactService.getAllContacts(this.filter.clientId, 1);
        this.tenants = [
          {
            "id": 0,
            "firstName": "All",
            "surname": "",
          }
        ];
        if (response?.data?.items?.length > 0) {
          this.tenants = this.tenants.concat(response?.data?.items ?? []);
        }
        if (this.tenants.length > 0) {
          this.agreement.tenantId = this.tenants.length > 1 ? this.tenants[1].id : this.agreement.id = 0;
          this.filter.tenantId = this.tenants[0].id;
        } else {
          this.agreement.tenantId = "";
        }
        await this.getAgreements();
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    async getFreeProperties() {
      try {

        let response = await pmsPropertiesService.getFreeProperties(this.filter.clientId);
        this.properties = response?.data ?? [];
        if (this.properties.length > 0) {
          this.agreement.propertyId = this.properties[0].id;
        } else {
          this.agreement.propertyId = ""
        }
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    async deleteAgreement() {
      this.loading = true;
      try {
        let response = await tenancyAgreementService.deleteAgreement(this.agreement.id);
        if (response.status === 200) {
          ShowMessage("success", "Agreement Deleted Successfully");
          await this.getAgreements();
        }

      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response.data.message);
      }
      this.loading = false;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }
        let response = await configService.getByKey(id, "CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey(id, "CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetAgreement() {
      this.agreement = {
        "id": 0,
        "clientId": this.clients[0]?.id ?? this.clientId,
        "contactId": 0,
        "tenantId": this.tenants[1]?.id ?? "",
        "startDate": new Date(),
        "endDate":null,
        "propertyId": this.properties[0]?.id,
        "landlordContactId": 0,
        "rent": 0,
        "commission": 0,
        "paymentDay": 1,
        "notes": "",
        "securityDeposit": 0,
        "leaveDate": null,
        "isActive": true,
      };
    }
  },
  created() {
    this.getClients();
    this.getConfigs();
  }
}

</script>

<style scoped>

</style>