import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    addUrl: Config.apiBaseUrl + "customernote/add",
    updateUrl: Config.apiBaseUrl + "customernote/update",
    getNotesUrl: Config.apiBaseUrl + "customernotes/getcustomernotes?customerId=",
    getUrl: Config.apiBaseUrl + "customernotes/getcustomernote?customerId=",
    deleteUrl: Config.apiBaseUrl + "customernotes/delete?customerId=",
    // Categories
    addCategoryUrl: Config.apiBaseUrl + "customernote/category/add",
    updateCategoryUrl: Config.apiBaseUrl + "customernote/category/update",
    getNotesCategoriesUrl: Config.apiBaseUrl + "customernotes/category/getcategories?clientId=",
    getCategoryUrl: Config.apiBaseUrl + "customernotes/category/getcustomernote?categoryId=",
    deleteCategoryUrl: Config.apiBaseUrl + "customernotes/category/delete?categoryId=",


    addNote(note) {
        return axios.post(this.addUrl, note)
    },
    updateNote(note) {
        return axios.put(this.updateUrl, note)
    },
    getNotes(filter) {
        return axios.get(this.getNotesUrl + filter.customerId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getNote(noteId) {
        return axios.get(this.getUrl + noteId)
    },
    deleteNote(noteId) {
        return axios.delete(this.deleteUrl + noteId);
    },
    // Categories
    addNoteCategory(category) {
        return axios.post(this.addCategoryUrl, category)
    },
    updateNoteCategory(category) {
        return axios.put(this.updateCategoryUrl, category)
    },
    getNotesCategories(filter) {
        return axios.get(this.getNotesCategoriesUrl + filter.clientId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getNoteCategory(catId) {
        return axios.get(this.getCategoryUrl + catId)
    },
    deleteNoteCategory(catId) {
        return axios.delete(this.deleteCategoryUrl + catId);
    }
}