<template>
  <div class="container-fluid pb-3">
    <ToolBar :ShowAddBtn="false" :show-bread="false" :show-select="false"/>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card  p-3 py-4 shadow-sm h-100" style="min-height: 304px">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="text-capitalize">{{ getMessageByCode('clientsByMarket') ?? 'Clients By Markets' }}</h4>
            <div class="d-icon-div btn-bl-secondary">
              <i class="bi bi-building"></i>
            </div>
          </div>
          <el-divider class="my-2" border-style="dashed"></el-divider>
          <n-table class="text-small fw-500" size="small" :bordered="false" :single-line="false">
            <thead>
            <tr>
              <th>{{ getMessageByCode('market') ?? 'Market' }}</th>
              <th>{{ getMessageByCode('active') ?? 'Active' }}</th>
              <th>{{ getMessageByCode('inactive') ?? 'InActive' }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="market in data.clientsByMarket" :key="market.title">
              <td>{{ market.marketName }}</td>
              <td class="text-end">{{ market.activeClients }}</td>
              <td class="text-end">{{ market.inactiveClients }}</td>
            </tr>
            </tbody>
          </n-table>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4  mt-2 mt-md-0">
        <div class="card  p-3 py-4 shadow-sm h-100" style="min-height: 304px">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="text-capitalize">{{ getMessageByCode('userByMarket') ?? 'Users By Markets' }}</h4>
            <div class="d-icon-div btn-bl-secondary">
              <i class="bi bi-people"></i>
            </div>
          </div>
          <el-divider class="my-2" border-style="dashed"></el-divider>
          <n-table class="text-small fw-500" size="small" :bordered="false" :single-line="false">
            <thead>
            <tr>
              <th>{{ getMessageByCode('market') ?? 'Market' }}</th>
              <th>{{ getMessageByCode('active') ?? 'Active' }}</th>
              <th>{{ getMessageByCode('inactive') ?? 'InActive' }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="market in data.usersByMarket" :key="market.title">
              <td>{{ market.marketName }}</td>

              <td class="text-end">{{ market.activeUsers }}</td>
              <td class="text-end">{{ market.inactiveUsers }}</td>
            </tr>
            </tbody>
          </n-table>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4  mt-2 mt-md-0">
        <div class="card  p-3 py-4 shadow-sm h-100" style="min-height: 304px">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="text-capitalize">{{ getMessageByCode('usersByClients') ?? 'Users By Client' }}</h4>
            <div class="d-icon-div btn-bl-secondary">
              <i class="bi bi-person-check"></i>
            </div>
          </div>
          <el-divider class="my-2" border-style="dashed"></el-divider>
          <n-table class="text-small fw-500" size="small" :bordered="false" :single-line="false">
            <thead>
            <tr>
              <th>{{ getMessageByCode('market') ?? 'Market' }}</th>
              <th>{{ getMessageByCode('client') ?? 'Client' }}</th>
              <th>{{ getMessageByCode('active') ?? 'Active' }}</th>
              <th>{{ getMessageByCode('inactive') ?? 'InActive' }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="market in data.usersByClients" :key="market.title">
              <td>
                <el-tooltip :content="market.marketName">
                  <span>{{ truncateString(market.marketName) }}</span>
                </el-tooltip>
              </td>
              <td>
                <el-tooltip :content="market.clientName">
                  <span>{{ truncateString(market.clientName) }}</span>
                </el-tooltip>
              </td>
              <td class="text-end">{{ market.activeUsers }}</td>
              <td class="text-end">{{ market.inactiveUsers }}</td>
            </tr>
            </tbody>
          </n-table>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <h4 class="py-4 h4HeadingBefore d-flex align-items-center">
        {{ getMessageByCode('housingManagement') ?? 'Housing Management' }}</h4>
      <div class="row mt-1">
        <div class="col-12 col-md-6 col-lg-4">
          <router-link to="properties" class="text-decoration-none">
            <div class="card p-3 py-4 shadow-sm h-100">
              <div class="d-flex justify-content-between align-items-center">
                <h3> {{ getMessageByCode('properties') ?? 'Properties' }}</h3>
                <div class="d-icon-div btn-bl-secondary">
                  <i class="bi bi-building-fill"></i>
                </div>
              </div>
              <div class="mt-4">
                <div class="row">
                  <div class="col-12 d-flex justify-content-between align-items-center w-100">
                    <span class="text-caption mfw-bold">{{ getMessageByCode('properties') ?? 'Properties' }}</span>
                    <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                        data?.housing?.totalProperty ?? "0"
                      }}</span>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 d-flex justify-content-between align-items-center w-100">
                    <span class="text-caption mfw-bold">{{ getMessageByCode('assigned') ?? 'Assigned' }}</span>
                    <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                        data?.housing?.assignedProperty ?? "0"
                      }}</span>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 d-flex justify-content-between align-items-center w-100">
                    <span class="text-caption mfw-bold">{{ getMessageByCode('percentage') ?? 'Percentage' }}</span>
                    <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                        data?.housing?.occupancyProperty ?? "0"
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-12 mt-3 mt-md-0 mt-lg-0 col-md-6 col-lg-4">
          <div class="card p-3 py-4 shadow-sm h-100">
            <div class="d-flex justify-content-between align-items-center">
              <h3>{{ getMessageByCode('rooms') ?? 'Rooms' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-bank2"></i>
              </div>
            </div>
            <div class="mt-4">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('total_rooms') ?? 'Total Rooms' }}</span>
                  <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                      data?.housing?.totalRoom ?? "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('assigned') ?? 'Assigned' }}</span>
                  <span
                      class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      data?.housing?.assignedRoom ?? "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('percentage') ?? 'Percentage' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      data?.housing?.occupancyRoom ?? "0"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 mt-3 mt-lg-0 col-lg-4">
          <div class="card p-3 py-4 shadow-sm h-100">
            <div class="d-flex justify-content-between align-items-center">
              <h3>{{ getMessageByCode('tenants') ?? 'Tenants' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-person-fill"></i>
              </div>
            </div>
            <div class="mt-4">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('total_tenants') ?? 'Total Tenants' }}</span>
                  <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                      data?.housing?.totalTenant ?? "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('assigned') ?? 'Assigned' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      data?.housing?.assignedTenant ?? "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('percentage') ?? 'Percentage' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      data?.housing?.occupancyTenant ?? "0"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <h4 class="py-4 h4HeadingBefore d-flex align-items-center">
        {{ getMessageByCode('propertyManagement') ?? 'Property Management' }}</h4>
      <div class="row mt-1">
        <div class="col-12 col-md-6 col-lg-4">
          <router-link to="pmsproperties" class="text-decoration-none">
            <div class="card p-3 py-4 shadow-sm">
              <div class="d-flex justify-content-between align-items-center">
                <h3> {{ getMessageByCode('properties') ?? 'Properties' }}</h3>
                <div class="d-icon-div btn-bl-secondary">
                  <i class="bi bi-building-fill"></i>
                </div>
              </div>
              <div class="mt-4">
                <div class="row">
                  <div class="col-12 d-flex justify-content-between align-items-center w-100">
                    <span class="text-caption mfw-bold">{{ getMessageByCode('properties') ?? 'Properties' }}</span>
                    <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                        data?.pms?.totalProperty ?? "0"
                      }}</span>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 d-flex justify-content-between align-items-center w-100">
                    <span class="text-caption mfw-bold">{{ getMessageByCode('assigned') ?? 'Assigned' }}</span>
                    <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                        data?.pms?.assignedProperty ?? "0"
                      }}</span>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 d-flex justify-content-between align-items-center w-100">
                    <span class="text-caption mfw-bold">{{ getMessageByCode('percentage') ?? 'Percentage' }}</span>
                    <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                        data?.pms?.occupancyProperty ?? "0"
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-12 mt-3 mt-lg-0 col-lg-4">
          <div class="card p-3 py-4 shadow-sm">
            <div class="d-flex justify-content-between align-items-center">
              <h3>{{ getMessageByCode('tenants') ?? 'Tenants' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-person-fill"></i>
              </div>
            </div>
            <div class="mt-4">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('total_tenants') ?? 'Total Tenants' }}</span>
                  <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                      data?.pms?.totalTenant ?? "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100 h-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('assigned') ?? 'Assigned' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      data?.pms?.assignedTenant ?? "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100 h-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('percentage') ?? 'Percentage' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      data?.pms?.occupancyTenant ?? "0"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 mt-3 mt-lg-0 col-lg-4">
          <div class="card p-3 py-4 shadow-sm h-100">
            <div class="d-flex justify-content-between align-items-center">
              <h3>{{ getMessageByCode('contacts') ?? 'Contacts' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-person-fill"></i>
              </div>
            </div>
            <div class="mt-4">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('totalContacts') ?? 'Total Contacts' }}</span>
                  <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                      data?.pms?.totalContacts ?? "0"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <h4 class="py-4 h4HeadingBefore d-flex align-items-center">
        {{ getMessageByCode('accountancy') ?? 'Accountancy Management' }}</h4>
      <div class="row mt-1">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <router-link to="contacts" class="text-decoration-none">
              <div class="card p-3 py-4 shadow-md ">
                <div class="d-flex justify-content-between align-items-center">
                  <h3> {{ getMessageByCode('contacts') ?? 'Contacts' }}</h3>
                  <div class="d-icon-div btn-bl-secondary">
                    <i class="bi bi-people"></i>
                  </div>
                </div>
                <div class="row">
                  <span class="f-card-heading">{{ data?.accountancy?.contacts ?? 0 }}</span>
                  <span>Contacts</span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <router-link to="customers" class="text-decoration-none">
              <div class="card p-3 py-4 shadow-md">
                <div class="d-flex justify-content-between align-items-center">
                  <h3> {{ getMessageByCode('customers') ?? 'Customers' }}</h3>
                  <div class="d-icon-div btn-bl-secondary">
                    <i class="bi bi-person-add"></i>
                  </div>
                </div>
                <div class="row">
                  <span class="f-card-heading">{{ data?.accountancy?.customers ?? 0 }}</span>
                  <span>Customers</span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <router-link to="app-users" class="text-decoration-none">
              <div class="card p-3 py-4 shadow-md">
                <div class="d-flex justify-content-between align-items-center">
                  <h3> {{ getMessageByCode('appUsers') ?? 'App Users' }}</h3>
                  <div class="d-icon-div btn-bl-secondary">
                    <i class="bi bi-phone"></i>
                  </div>
                </div>
                <div class="row">
                  <span class="f-card-heading">{{ data?.accountancy?.linkedAppUsers ?? 0 }} / {{ data?.accountancy?.appUsers ?? 0 }}</span>
                  <span>Linked Users / Free Users</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card p-3 py-4 shadow-md ">
              <div class="d-flex justify-content-between align-items-center">
                <h3> {{ getMessageByCode('transactions') ?? 'Transactions' }}</h3>
                <div class="d-icon-div btn-bl-secondary">
                  <i class="bi bi-currency-pound"></i>
                </div>
              </div>
              <div class="row">
                <span class="f-card-heading">{{ data?.accountancy?.totalTransactions ?? 0 }}</span>
                <span>Total Transactions</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card p-3 py-4 shadow-md ">
              <div class="d-flex justify-content-between align-items-center">
                <h3> {{ getMessageByCode('accounts') ?? 'Accounts' }}</h3>
                <div class="d-icon-div btn-bl-secondary">
                  <i class="bi bi-currency-pound"></i>
                </div>
              </div>
              <div class="row">
                <span class="f-card-heading">{{ data?.accountancy?.totalAccounts ?? 0 }}</span>
                <span>Total Accounts</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import dashboardService from "@/Services/ComponentsServices/dashboardService";
import {getLanguage} from "@/Utility/getLanguage";
import {NTable} from "naive-ui";

export default {
  name: "SADashboard",
  components: {NTable, ToolBar},
  computed: {
    BreedCrumb() {
      return this.$route.meta.breadcrumbs
    }
  },
  data() {
    return {
      loading: false,
      translations: [],
      data: {},
    }
  },
  methods: {
    truncateString(str) {
      if (str.length > 15) {
        return str.slice(0, 15) + '...';
      } else {
        return str;
      }
    },
    async getSuperAdminData() {
      try {
        this.loading = true;
        let response = await dashboardService.getSuperAdminData();
        this.data = response?.data;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.getSuperAdminData();
    this.translations = getLanguage();
  }
};
</script>

<style scoped>
.d-loader {
  margin-top: -10px;
}
</style>
