<template>
  <div class="container-fluid p-2 p-md-0 h-100 bg-gray">
    <div class="row h-100 px-3 px-md-0 justify-content-center align-items-center">
      <div v-if="validGuid && !passwordChanged" class="col-12 col-md-4 text-center sign-in-col card py-4">
        <div class="mb-3">
          <div class="logo py-4">
            <span v-if="!logo">Logo</span>
            <img v-else :src="logo" :class="['app-responsive-logo']" alt="logo" :width="logoWidth" :height="logoHeight">
          </div>
          <h3 class="fw-bold">Reset your app password</h3>
        </div>
        <div class="col-12 col-md-10 m-0 p-0">
          <el-form ref="myForm" :model="User" require-asterisk-position="right" :rules="formRules" label-position="top">
            <el-form-item label="New password" prop="password">
              <el-input
                  v-model="User.password"
                  :type="showPass ? 'text' : 'password'"
                  size="large"
                  tabindex="2"
              >
                <template #append>
                  <el-button @click="showPass = !showPass">
                    <i v-if="showPass" class="bi bi-eye"></i>
                    <i v-else class="bi bi-eye-slash"></i>
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="Confirm password" prop="confirmPassword">
              <el-input
                  v-model="User.confirmPassword"
                  :type="showCPass ? 'text' : 'password'"
                  size="large"
                  tabindex="2"
                  @keyup.enter="changePassword"
              >
                <template #append>
                  <el-button @click="showCPass = !showCPass">
                    <i v-if="showCPass" class="bi bi-eye"></i>
                    <i v-else class="bi bi-eye-slash"></i>
                  </el-button>
                </template>
              </el-input>
              <div class="pswd-indicator">
                <span>Check your password strength</span>
                <el-popover
                    placement="top-start"
                    :width="230"
                    trigger="hover"
                    content="this is content, this is content, this is content"
                >
                  <template #reference>
                    <i class="bi bi-info-circle ms-2" style="cursor: pointer"></i>
                  </template>
                  <template #default>
                    <div class="pswd-indicator">
                      <div>
                        <i v-if="User.password.length < 8" class="bi bi-x-circle-fill me-2 text-danger"></i>
                        <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                        <span :class="User.password.length < 8 ? 'text-secondary' : 'text-success'">At least eight characters</span>
                      </div>
                      <div>
                        <i v-if="!specialCh" class="bi bi-x-circle-fill me-2 text-danger"></i>
                        <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                        <span
                            :class="!specialCh ? 'text-secondary' : 'text-success'">At least one special character</span>
                      </div>
                      <div>
                        <i v-if="!hasCapitalChar" class="bi bi-x-circle-fill me-2 text-danger"></i>
                        <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                        <span :class="!hasCapitalChar ? 'text-secondary' : 'text-success'">At least one capital character</span>
                      </div>
                      <div>
                        <i v-if="!hasNumber" class="bi bi-x-circle-fill me-2 text-danger"></i>
                        <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                        <span :class="!hasNumber ? 'text-secondary' : 'text-success'">At least One number</span>
                      </div>
                    </div>
                  </template>
                </el-popover>
              </div>
            </el-form-item>
            <el-form-item class="mt-5">
              <el-button
                  :disabled="loading || !User.password || !User.confirmPassword || !passwordOk()"
                  :loading="loading"
                  class="mbtn-primary rounded-1 w-100"
                  size="large"
                  @click="submitForm"
              >
                {{  "Continue" }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div v-if="passwordChanged" class="col-12 col-md-4 text-center sign-in-col card py-4">
        <n-result status="success" title="Success">
          <template #icon>
            <img width="100" src="@/assets/svg/happy.svg" alt="success">

          </template>
          <template #default>
            <span>
              Congratulations, you've reset your app
              password. Please continue on your app.
            </span>
          </template>
        </n-result>
      </div>
      <div v-if="!validGuid && !passwordChanged && !fetchingGuid"
           class="col-12 col-md-4 text-center sign-in-col card py-4">
        <n-result status="error" title="Error">
          <template #icon>
            <img width="100" src="@/assets/svg/sad.svg" alt="success">

          </template>
          <template #default>
            <span>
              Sorry,
The link for the password reset is invalid or expired. <br>
              Please submit a new request from the app. The email request is valid for 60
minutes.
            </span>
          </template>
        </n-result>
      </div>
      <div v-if="fetchingGuid"
           class="col-12 col-md-4 text-center sign-in-col card py-4">
        <div class="py-4">
          <n-spin stroke="2A5699" description="Please wait while we check the link..."></n-spin>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import {ShowMessage} from "@/Utility/Utility";
import syncAppService from "@/Services/ComponentsServices/syncAppService";
import configService from "@/Services/ComponentsServices/configService";
import {NResult} from "naive-ui";
import {NSpin} from "naive-ui";

export default {
  name: "PasswordReset",
  components: {NSpin, NResult},
  data() {
    return {
      showPass: false,
      showCPass: false,
      loading: false,
      specialCh: false,
      hasCapitalChar: false,
      hasNumber: false,
      passwordChanged: false,
      validGuid: false,
      logoWidth: 150,
      logoHeight: 150,
      fetchingGuid: true,
      logo: "",
      User: {
        email: "",
        password: "",
        confirmPassword: '',
        guid: "",
      },
      resetModel: {
        "id": 0,
        "email": "",
        "ip": "",
        "requestDate": "2025-02-06T04:20:14.225Z",
        "passwordChanged": false,
        "guid": null
      },
      formRules: {
        userName: [
          {required: true, message: 'Please enter a username', trigger: 'blur'},
        ],
        password: [
          {required: true, message: 'Please enter a password', trigger: 'blur'},
          {
            min: 6,
            max: 50,
            message: 'Password length should be between 6 and 50 characters',
            trigger: ['blur', 'change'],
          },
        ],
        confirmPassword: [
          {required: true, message: 'Please confirm your password', trigger: ['blur', 'change'],},
          {validator: this.validatePassword, trigger: ['blur', 'change'],},
        ],
      },
    }
  },
  methods: {
    passwordOk() {
      return this.specialCh &&
          this.hasCapitalChar &&
          this.hasNumber &&
          this.User.password.length >= 8 &&
          this.User.password === this.User.confirmPassword;
    },
    containsSpecialCharacters(inputString) {
      const regex = /[^a-zA-Z0-9\s]/;
      const capitalCharRegex = /[A-Z]/;
      const numberRegex = /[0-9]/;

      this.specialCh = regex.test(inputString);
      this.hasCapitalChar = capitalCharRegex.test(inputString);
      this.hasNumber = numberRegex.test(inputString);
    },
    validatePassword(rule, value, callback) {
      if (value === this.User.password) {
        callback();
      } else {
        callback(new Error('Password does not match'));
      }
    },
    submitForm() {
      this.$refs.myForm.validate(valid => {
        if (valid && this.passwordOk()) {
          this.changePassword();
        } else {
          ShowMessage("error", "Please ensure the passwords match and meet the strength requirements.");
        }
      });
    },
    async changePassword() {
      try {
        this.loading = true;

        // eslint-disable-next-line no-unused-vars
        let response = await syncAppService.changePassword(this.User);
        if (response?.data?.success) {
          ShowMessage("success", response?.data?.message ?? "Password changed successfully");
          this.User = {
            email: "",
            password: "",
            confirmPassword: '',
            guid: "",
          }

          this.passwordChanged = true;
          this.validGuid = false;
        } else {
          ShowMessage("error", response?.data?.message ?? "Sorry, the link for the password change is invalid or expired. Please submit a new request from your app.");
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e.message);
        ShowMessage("error", "Something went wrong");
      }
    },
    async verifyGuid() {
      try {
        this.fetchingGuid = true;
        let response = await syncAppService.verifyGuid(this.resetModel);
        if (response?.data?.success) {
          this.User.email = response?.data?.syncAppUser?.email;
          if (this.User.email) {
            this.validGuid = true;
            this.passwordChanged = false;
          }
          this.fetchingGuid = false;
        } else {
          this.fetchingGuid = false;
          console.log(this.validGuid, this.passwordChanged, this.fetchingGuid)
        }
      } catch (e) {
        this.fetchingGuid = false;
        console.log(this.validGuid, this.passwordChanged, this.fetchingGuid)
        // ShowMessage("error", e?.data?.message ?? "Sorry, the link for the password change is invalid or expired. Please submit a new request from your app.");
      }
    },
    async checkGuid() {
      this.User.guid = sessionStorage.getItem("sessionGuid");
      this.resetModel.guid = sessionStorage.getItem("sessionGuid");
      await this.verifyGuid();
    },
    getGuid() {
      let urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('guid');
      sessionStorage.setItem("sessionGuid", token);
      // Remove the token from the URL
      setTimeout(function () {
        let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        window.history.replaceState({path: newUrl}, '', newUrl);
      });
    },
    async getLogo() {
      try {
        // Fetch the logo configuration
        let logo = await configService.getByKey(0, "SYXTLOGO");
        // Extract the Base64 string (assuming it's in logo.data.value)
        this.logo = logo.data.value;

        const img = new Image();
        img.src = this.logo; // Use the Base64 string as the image source

        // Wait for the image to load
        img.onload = () => {
          this.logoWidth = img.width;
          this.logoHeight = img.height;
        };

        // Handle image loading errors
        img.onerror = () => {
          console.error("Failed to load the Base64 image.");
        };

      } catch (error) {
        console.error("Error fetching the logo:", error);
      }
    }
  },
  watch: {
    'User.password': function (newVal) {
      this.containsSpecialCharacters(newVal);
    }
  },
  mounted() {
    this.getLogo();
    this.getGuid();
    this.checkGuid();
  }
}
</script>
<style scoped>

</style>