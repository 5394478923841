import Config from "@/Configuration/Config";
import axios from 'axios'

export default {
    allurl: Config.apiBaseUrl + "client/getclients?status=",
    getallurl: Config.apiBaseUrl + "client/getclients?status=1&limit=5000&isOurClient=1",
    geturl: Config.apiBaseUrl + "client/getclientbyid?clientId=",
    addurl: Config.apiBaseUrl + "client/add",
    updateurl: Config.apiBaseUrl + "client/update",
    deleteurl: Config.apiBaseUrl + "client/delete?clientId=",
    activateurl: Config.apiBaseUrl + "client/activate?clientId=",
    deactivateurl: Config.apiBaseUrl + "client/deactivate?clientId=",
    registerUrl: Config.apiBaseUrl + "client/register",
    getLanguageUrl: Config.apiBaseUrl + "user/getclientlanguage?iso=",
    getGuidUrl: Config.apiBaseUrl + "client/getguid?clientId=",
    //auth codes url
    getCodesUrl: Config.apiBaseUrl + "client/authcode/getcodes?clientGuid=",
    addCodeUrl: Config.apiBaseUrl + "client/authcode/add",
    updateCodeUrl: Config.apiBaseUrl + "client/authcode/update",
    enableCodeUrl: Config.apiBaseUrl + "client/authcode/enable?codeId=",
    disableCodeUrl: Config.apiBaseUrl + "client/authcode/disable?codeId=",
    deleteCodeUrl: Config.apiBaseUrl + "client/authcode/delete?codeId=",
    //accountancy
    getAllClientsForAccountancyUrl: Config.apiBaseUrl + "client/getallforaccountancy",
    all(filter) {
        return axios.get(this.allurl + filter.status + "&search=" + filter.search + "&isOurClient=" + filter.isOurClient + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getallurl);
    },
    getGuid(clientId) {
        return axios.get(this.getGuidUrl + clientId)
    },
    getbyId(id) {
        return axios.get(this.geturl + id);
    },
    addClient(client) {
        return axios.post(this.addurl, client);
    },
    updateClient(client) {
        return axios.put(this.updateurl, client);
    },
    deleteClient(id) {
        return axios.delete(this.deleteurl + id);
    },
    activate(id) {
        return axios.get(this.activateurl + id);
    },
    deactivate(id) {
        return axios.get(this.deactivateurl + id);
    },
    register(client) {
        return axios.post(this.registerUrl, client)
    },
    getClientLanguage(iso) {
        return axios.get(this.getLanguageUrl + iso);
    },
    /// Auth Codes Methods,
    getAuthCodes(filter) {
        return axios.get(this.getCodesUrl + filter.guid + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    addAuthCode(authCode) {
        return axios.post(this.addCodeUrl, authCode);
    },
    updateAuthCode(authCode) {
        return axios.post(this.updateCodeUrl, authCode);
    },
    activateAuthCode(codeId) {
        return axios.get(this.enableCodeUrl + codeId);
    },
    deActivateAuthCode(codeId) {
        return axios.get(this.disableCodeUrl + codeId);
    },
    deleteAuthCode(codeId) {
        return axios.delete(this.deleteCodeUrl + codeId);
    },
    getAllForAccountancy() {
        return axios.get(this.getAllClientsForAccountancyUrl);
    }
}