<template>
  <div class="common-layout m-0 p-0">
    <el-container style="height: 100vh;">
      <el-aside v-if="$store.state.loggedIn" class="d-none d-md-block" width="200">
        <Sidebar/>
      </el-aside>
      <el-container>
        <el-header v-if="$store.state.loggedIn" class="m-0 p-0">
          <HeaderComp/>
        </el-header>
        <el-main class="m-0 p-0">
          <router-view/>
        </el-main>
        <el-footer v-if="$store.state.loggedIn" class="m-0 p-0 mt-1">
          <FooterComp/>
        </el-footer>
      </el-container>
    </el-container>
  </div>


</template>
<script>

import HeaderComp from "@/components/Navigation/Header";
import Sidebar from "@/components/Navigation/Sidebar";
import {mapState} from "vuex";
import configService from "@/Services/ComponentsServices/configService";
import FooterComp from "@/components/Navigation/Footer.vue";

export default {
  components: {
    FooterComp,
    HeaderComp,
    Sidebar
  },
  data() {
    return {
      collapsed: false,
      clientId: 0,
      favIcon: "",
    }
  },
  computed: {
    ...mapState(["sidebarCollapsed"]), // Map the state to a local computed property
  },
  watch: {
    sidebarCollapsed(newVal) {
      this.collapsed = !!newVal;
    },
  },
  methods: {
    async changeFavIcon() {
      let res = await configService.getByKey(this.clientId, "FavIcon");
      this.updateFavicon(res?.data?.value)
    },
    updateFavicon(imageString) {
      // Create a link element
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link");

      // Set the attributes for the link element
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = imageString;

      // Update the favicon in the document's head
      document.head.appendChild(link);
    },
  },
  created() {
    this.changeFavIcon();
  }
}
</script>
<style>
@import "./assets/bootstrap/css/bootstrap.css";
@import "./assets/MingCute/fonts/MingCute.css";
@import "./assets/Style/Style.css";
@import './assets/Themes/elementplus.css';
</style>
