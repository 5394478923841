<template>
  <div class="container-fluid">

    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <!--          <el-select v-if="clientId == null || clientId == 0" class="w-100" filterable @change="ChangeClient" v-model="filter.clientId">-->
          <!--            <el-option-->
          <!--                v-for="item in Clients"-->
          <!--                :key="item.id"-->
          <!--                :label="item.name"-->
          <!--                :value="item.id"-->
          <!--            />-->
          <!--          </el-select>-->
          <!--          <router-link @click="$store.state.pageId = 0" to="/add-user">-->
          <!--            <el-button-->
          <!--                class="border-0 mbtn-primary ms-2 text-capitalize">{{getMessageByCode('add_user')??'Add User'}}-->
          <!--            </el-button>-->
          <!--          </router-link>-->
          <el-button @click="addRoleDialog = true"
              class="border-0 mbtn-primary ms-2 text-capitalize">{{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-12 col-md-3 d-flex justify-content-end">
        <el-select v-if="clientId == null || clientId < 1" filterable @change="changeClient" v-model="filter.clientId">
          <el-option v-for="item in Clients"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
            <template #default>
              <div class="row">
                <el-tooltip v-if="item.name.length > 20" :content="item.name">
                  <span class="col-7">{{ getClientName(item.name) }}</span>
                </el-tooltip>
                <span v-else class="col-7">{{ getClientName(item.name) }}</span>
                <span class="text-small col-5">{{ item.market }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
      </div>
      <div class="col-12 col-md-4 mb-2 d-flex">
        <el-input :placeholder="getMessageByCode('search')??' Search here...'" @clear="getRoles" v-model="filter.search"
                  @keyup="getRoles"
                  clearable>
          <template #append>
            <el-button @click="getRoles">
              <template #icon>
                <i class="bi bi-search"></i>
              </template>
            </el-button>
          </template>
        </el-input>
      </div>
    </div>
    <el-tabs type="border-card" class="demo-tabs" v-model="activeTab" @tab-click="handleTabClick">
      <el-tab-pane name="tab1">
        <template #label>
            <span class="custom-tabs-label">
              <el-icon>
                <i class="bi bi-list-check me-2"></i>
              </el-icon>
                 <span>{{ getMessageByCode('roles') ?? 'Roles' }}</span>
             </span>
        </template>
        <el-table :data="Roles"
                  :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                  header-cell-class-name="tbl-header"
                  stripe @selection-change="handleSelectionChange">
          <template #append>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="filter.totalCount"
                :page="filter.currentPage"
                :page-size="filter.pageSize"
                :page-sizes="[10, 20, 30, 40]"
                layout=" prev, pager, next,sizes"
                class="bg-gray p-2"
                background
                small
            >
            </el-pagination>
          </template>
          <el-table-column align="center" type="selection" width="55"/>
          <el-table-column sortable :label="getMessageByCode('name')??'Name'" prop="name" min-width="200"/>
          <el-table-column sortable :label="getMessageByCode('desc')??'Description'" prop="description" min-width="200"/>
          <!--          <el-table-column label="Client" prop="clientName" min-width="200"/>-->
          <el-table-column v-if="providerId < 1" align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="140">
            <template #default="scope">
              <el-space alignment="center" spacer="|">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="View Permissions"
                    placement="bottom"
                >
                  <i
                      class="mgc_eye_2_line text-dark action-btn"
                      @click="editRole('view',scope.row)"
                  ></i>
                </el-tooltip>
                <i
                    class="mgc_edit_line text-primary action-btn"
                    @click="editRole('edit',scope.row)"
                ></i>

              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Config">
        <template #label>
            <span class="custom-tabs-label">
              <el-icon>
                <i class="mgc_user_1_line me-2"></i>
              </el-icon>
                 <span class="text-capitalize">{{ getMessageByCode('users_in_role') ?? 'Users in role' }}</span>
             </span>
        </template>
        <el-collapse v-model="activeCollapseIndex" accordion>
          <el-collapse-item v-for="(user, index) in Users" :key="index" :name="'item'+index" class="bg-light">
            <template #title>
              <span class="fw-bold">{{ user.roleName }}</span>
            </template>
            <div class="d-flex justify-content-end">
              <div class="col-12">
                <el-table v-loading="loading" :data="user.users"
                          :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                          header-cell-class-name="tbl-header"
                          stripe @selection-change="handleSelectionChange">
                  <el-table-column :label="getMessageByCode('username')??'Username'" prop="username"></el-table-column>
                  <el-table-column :label="getMessageByCode('role')??'Role'" prop="role"
                                   min-width="50"></el-table-column>
                </el-table>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
    </el-tabs>
    <div v-if="loading" class="spinner-border d-loader"></div>


    <el-dialog v-model="editDialog" title="Edit Role" width="400">

      <div class="row">
        <div class="col-12">
          <el-form label-position="top">
            <el-form-item>
              <label>Role Name</label>
              <el-input v-model="role.name"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="editDialog = false" class="me-2"> Cancel</el-button>
          <el-button type="primary" @click="updateRole()">Update</el-button>
        </div>
      </template>

    </el-dialog>

    <el-dialog v-model="addRoleDialog" draggable width="450">
      <template #header>
        <span v-if="role.id < 1">{{ getMessageByCode('create_role') ?? 'Create Role' }}</span>
        <span v-else>{{ getMessageByCode('edit_role') ?? 'Edit Role' }}</span>
      </template>
      <template #default>
        <el-form label-position="top">
          <el-form-item label="Role Name">
            <el-input v-model="role.name"></el-input>
          </el-form-item>
          <el-form-item v-if="clientId == null || clientId == 0" label="Client">
            <el-select class="w-100" filterable @change="changeClient(role.clientId)" v-model="role.clientId">
              <el-option
                  :disabled="item.id === -1"
                  v-for="item in Clients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Role Description">
            <el-input v-model="role.description"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addRoleDialog = false">{{getMessageByCode('cancel') ?? 'Cancel'}}</el-button>
          <el-button type="primary" class="ms-2" @click="addRole(), addRoleDialog = false">{{getMessageByCode('save') ?? 'Save'}}</el-button>
        </div>
      </template>


    </el-dialog>


  </div>
</template>
<script>
// import ToolBar from "@/components/Layout/ToolBar.vue";
import roleService from "@/Services/ComponentsServices/roleService";
import clientService from "@/Services/ComponentsServices/clientService";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import getClientName from "../../Utility/getClientName";

export default {
  name: "RolesList",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      editDialog: false,
      addRoleDialog: false,
      activeTab: "tab1",
      activeCollapseIndex: "item0",
      myTab: 0,
      Roles: [],
      translations: [],
      Users: [],
      Clients: [ {
          id: 0,
          name: "Super Admin"
        }
      ],
      clientId: sessionStorage.getItem("clientId"),
      role: {
        id: 0,
        name: "",
        description: "",
        clientId: sessionStorage.getItem("clientId")
      },
      selectedIds: [],
      providerId: sessionStorage.getItem("providerId"),
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: sessionStorage.getItem("providerId") ?? 0,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    getClientName,
    handleTabClick(tab) {
      if (Number(tab.index) === 0) {
        this.myTab = tab.index;
        this.getRoles();
      } else if (Number(tab.index) === 1) {
        this.myTab = tab.index;
        this.getUsers();
      }
    },
    changeClient(clientId) {
        this.$store.state.clientId = clientId;
      if (Number(this.myTab) === 0) {
        this.getRoles();
      }
      if (Number(this.myTab) === 1) {
        this.getUsers();
      }
    },
    editRole(type, role) {
      this.$store.state.roleId = role.securityRoleId;
      this.$store.state.securityRoleId = role.securityRoleId;
      this.$store.state.roleName = role.name;
      this.role = {...role};
      switch (type) {
        case "view":
          this.$router.push('/roles-permissions');
          break;
        case "edit":
          this.editDialog = true;
          break;
        case "del":
          break;
      }

    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getRoles();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getRoles();
    },
    async getRoles() {
      try {
        this.loading = true;
        let response = await roleService.getByClient(this.filter);
        this.Roles = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error(e)
      }
    },
    async updateRole() {
      try {
        // eslint-disable-next-line no-unreachable
        this.loading = true;
        this.role.isActice = true;
        // eslint-disable-next-line no-unused-vars
        let response = await roleService.update(this.role);
        this.editDialog = false;
        this.getRoles();
        ShowMessage("success", "Role Updated");
        this.loading = false;
      } catch {
        ShowMessage("error", "Something went wrong!.");
        this.loading = false;
      }
    },
    async getUsers() {
      try {
        this.loading = true;
        let response = await roleService.getRoleUsers(this.filter.clientId);
        this.Users = response.data ?? [];

        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error(e)
      }
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0 || sessionStorage.getItem('clientId') < 1) {

          let response = await clientService.getAll()
          this.Clients = this.Clients.concat(response.data.items);
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
            this.role.clientId =  this.Clients[0].id;
          }
        }
        this.getRoles();
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async addRole(){
      try {
        // eslint-disable-next-line no-unused-vars
        let response = await roleService.add(this.role);

        ShowMessage("success", "Role added");
        this.getRoles();
      }
      catch (e) {
        ShowMessage("error",e?.response?.data?.message??'Something went wrong');
      }
    }
  },
  created() {
    this.$store.state.clientId = sessionStorage.getItem("clientId");
    this.translations = getLanguage();
    this.getClients();
    this.getRoles();
  }
}
</script>

<style scoped>
.el-collapse-item__header {
  background-color: gainsboro !important;
}
</style>