<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row align-items-center py-2">
        <div class="col-12 col-md-8">
          <BreadCrumb :id="property.id" :showBread="true" :view="edit"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="pmsproperties">
            <el-button bg class="btn btn-light" text>
              <i class="bi bi-chevron-left me-2"></i>
              <span>{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
            </el-button>
          </router-link>
          <el-button v-else bg class="btn btn-light" text @click="checkProperty()">
            <i class="bi bi-chevron-left me-2"></i>
            <span>{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
          </el-button>
          <el-button v-if="edit" bg class="border-0 mbtn-primary ms-2" text @click="edit = !edit">
            <span>{{ getMessageByCode('edit') ?? 'Edit' }}</span>
          </el-button>
          <el-button v-if="property.id > 0 && !edit"
                     :disabled="edit || property.clientId < 1|| !property.reference || property.landlordId < 1"
                     class="border-0 mbtn-primary ms-2"
                     @click="updateProperty">
            <span>{{ getMessageByCode('upd_prop') ?? 'Update Property' }}</span>
          </el-button>
          <el-button v-if="property.id < 1" class="border-0 mbtn-primary ms-2"
                     @click="addProperty">
            <span>{{ getMessageByCode('add_prop') ?? 'Add Property' }}</span>
          </el-button>

        </div>
      </div>
    </div>
    <div class="container-fluid m-0 p-0">
      <el-tabs v-model="activeTab" @tab-click="handleTabClick" type="border-card">
        <el-tab-pane :label="getMessageByCode('details') ?? 'Details'" :name="0">
          <el-form ref="propertyForm" :model="property" hide-required-asterisk label-position="top">
            <div class="row d-flex align-items-stretch h-100">
              <span class="col-12 me-5 text-caption alert alert-light"><i class="bi bi-info-circle-fill me-2 "></i>Fields with Asterisk(*) are mandatory</span>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('prop') ?? 'Property' }}</span>
                    <span class="bi bi-building-add"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item v-if="clientId < 1">
                      <template #label>
                        <span class="required">{{ getMessageByCode('client') ?? 'Client' }}</span>
                      </template>
                      <el-select v-model="property.clientId" :disabled="edit" class="w-100" filterable
                                 placeholder="Select Client" @change="getLandlords()"
                      >
                        <el-option
                            v-for="item in clients"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="required">{{ getMessageByCode('landlord') ?? 'Landlord' }}</span>
                      </template>
                      <el-select v-model="property.landlordId" :disabled="edit" class="w-100" filterable
                                 placeholder="Select Landlord"
                      >
                        <el-option
                            v-for="item in landords"
                            :key="item.id"
                            :label="item.firstName +' '+item.surname"
                            :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="required">{{ getMessageByCode('category') ?? 'Category' }}</span>
                      </template>
                      <el-select v-model="property.categoryId" :disabled="edit" class="w-100" filterable
                                 placeholder="Select Category"
                      >
                        <el-option
                            v-for="item in  categories"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="">{{ getMessageByCode('prop_name') ?? 'Property Name' }}</span>
                      </template>
                      <el-input :disabled="edit" v-model="property.propertyName"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="required">{{ getMessageByCode('reference') ?? 'Reference' }}</span>
                      </template>
                      <el-input :disabled="edit" v-model="property.reference"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('propertyType') ?? 'Property Type'">
                      <el-select :disabled="edit" v-model="property.typeOfProperty">
                        <el-option v-for="type in types"
                                   :key="type.optId"
                                   :label="type.optTitle"
                                   :value="type.optId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('address') ?? 'Address' }}</span>
                    <span class="bi bi-sign-turn-right"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item :label="getMessageByCode('address') +' '+ 1 ?? 'Address' + 1">
                      <el-input :disabled="edit" v-model="property.address1"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('address') +' '+ 2 ?? 'Address' + 2">
                      <el-input :disabled="edit" v-model="property.address2"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('address') +' '+ 3 ?? 'Address' + 3">
                      <el-input :disabled="edit" v-model="property.address3"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('city/town')  ?? 'City/Town'">
                      <el-input :disabled="edit" v-model="property.city"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('region')  ?? 'Region'">
                      <el-input :disabled="edit" v-model="property.region"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('postcode')  ?? 'Postcode'">
                      <el-input :disabled="edit" v-model="property.postcode"></el-input>
                    </el-form-item>


                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('attributes') ?? 'Attributes' }}</span>
                    <span class="bi bi-sign-turn-right"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item :label="getMessageByCode('price') ?? 'Price'">
                      <template #label>
                        <span>{{ getMessageByCode('price') ?? 'Price' }}</span><span class="text-small text-secondary"> (eg. 999.99)</span>
                      </template>
                      <el-input :disabled="edit" :value="property.price.toFixed(2)" v-model="property.price"
                                class="w-100"
                                type="number">
                        <template #suffix>
                          {{ currency }}
                        </template>
                      </el-input>

                    </el-form-item>
                    <el-form-item :label="getMessageByCode('beds') ?? 'No of Beds'">
                      <el-select :disabled="edit" v-model="property.numberOfBeds">
                        <el-option v-for="bed in beds"
                                   :key="bed.key"
                                   :label="bed.value"
                                   :value="bed.key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('bathroom') ?? 'No of Bathrooms'">
                      <el-select :disabled="edit" v-model="property.numberOfBathrooms">
                        <el-option v-for="bed in bathrooms"
                                   :key="bed.key"
                                   :label="bed.value"
                                   :value="bed.key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('propertyStatus') ?? 'Property Status'">
                      <el-select :disabled="edit" v-model="property.propertyStatus">
                        <el-option v-for="bed in propertyStatus"
                                   :key="bed.optId"
                                   :label="bed.optTitle"
                                   :value="bed.optId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('status') ?? 'Status'">
                      <el-select :disabled="edit" v-model="property.status">
                        <el-option v-for="bed in status2"
                                   :key="bed.optId"
                                   :label="bed.optTitle"
                                   :value="bed.optId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 d-flex align-items-stretch h-100" style="min-height: 200px">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('propImages') ?? 'Property Images' }}</span>
                    <span class="bi bi-image"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <div class="d-flex justify-content-end">
                      <el-button type="primary" @click="uploadImage = true">
                        {{ getMessageByCode('uploadimages') ?? 'Upload Image(s)' }}
                      </el-button>
                    </div>
                    <div class="mt-2">
                      <el-form-item v-if="property.images.length >0">
                        <n-table class="text-small" size="small" :bordered="false" :single-line="false">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>{{ getMessageByCode('file') ?? 'File' }}</th>
                            <th>{{ getMessageByCode('category') ?? 'Category' }}</th>
                            <th>{{ getMessageByCode('image') ?? 'Image' }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(img,index) in property.images" :key="img.fileName">
                            <td style="width: 25px">{{ index + 1 }}</td>
                            <td>
                              <el-tooltip :content="img.fileName">
                                <span>{{ truncateFileName(img.fileName) }}</span>
                              </el-tooltip>
                            </td>
                            <td>{{ getImageCategoryName(img.categoryId) }}</td>
                            <td class="d-flex justify-content-around align-items-center">
                              <n-image
                                  width="30"
                                  height="30"
                                  :src="img.fileData"
                                  class="me-2"
                              />
                              <i class="bi bi-x-circle action-btn" @click="removeImage(img)"></i>
                            </td>
                          </tr>
                          </tbody>
                        </n-table>

                        <!--                        <ul class="list-group  w-100">-->
                        <!--                          <li class="list-group-item  d-flex align-items-center justify-content-between"-->
                        <!--                              v-for="(img,index) in property.images"-->
                        <!--                              :key="index">-->
                        <!--                            <div class="text-caption">-->
                        <!--                              <span class="me-1">{{ index }}.</span>-->
                        <!--                              <el-tooltip :content="img.fileName">-->
                        <!--                                <span>{{ truncateFileName(img.fileName) }}</span>-->
                        <!--                              </el-tooltip>-->
                        <!--                            </div>-->
                        <!--                            <div>-->
                        <!--                              <span>{{ getImageCategoryName(img.categoryId) }}</span>-->
                        <!--                            </div>-->
                        <!--                            <div class="d-flex align-items-center ">-->
                        <!--                              <n-image-->
                        <!--                                  width="30"-->
                        <!--                                  height="30"-->
                        <!--                                  :src="img.fileData"-->
                        <!--                                  class="me-2"-->
                        <!--                              />-->
                        <!--                              <i class="bi bi-x-circle action-btn" @click="removeImage(img)"></i>-->
                        <!--                            </div>-->
                        <!--                          </li>-->
                        <!--                        </ul>-->
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('tenancy') ?? 'Tenancy'" :name="1">
          <div class="d-flex justify-content-end py-1">
            <el-button v-if="!loading && !agreements.some(agreement => agreement.isActive)" type="primary"
                       @click="addAgreementDialog = true; resetAgreement();">
              {{ getMessageByCode('add') ?? 'Add' }}
            </el-button>
          </div>
          <el-table :data="agreements" :default-sort="{ prop: 'name', order: 'ascending' }"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column show-overflow-tooltip sortable class="text-capitalize"
                             :label="getMessageByCode('tenant') ?? 'Tenant'" prop="tenant"
                             min-width="150">
              <template #default="scope">
          <span class="text-primary text-decoration-underline action-btn" style="font-size: 13px !important;"
                @click="goToTenant(scope.row.tenantId)">{{ scope.row.tenant }}</span>
              </template>
            </el-table-column>
            <!--            <el-table-column show-overflow-tooltip sortable class="text-capitalize"
                                         :label="getMessageByCode('property') ?? 'Property'"
                                         prop="property"
                                         min-width="200">
                          <template #default="scope">
                      <span class="text-primary text-decoration-underline action-btn" style="font-size: 13px !important;"
                            @click="goToProperty(scope.row.propertyId)">{{ scope.row.property }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column sortable class="text-capitalize" :label="getMessageByCode('city') ?? 'City'" prop="city"
                                         width="120"></el-table-column>
                        <el-table-column sortable class="text-capitalize" :label="getMessageByCode('postCode') ?? 'Post Code'"
                                         prop="postCode"
                                         width="120"></el-table-column>-->
            <el-table-column sortable width="100" class="text-capitalize"
                             :label="getMessageByCode('active') ?? 'Active'"
                             prop="isActive"
                             min-width="200">
              <template #default="scope">
                <div class="text-center">
                  <el-tag size="small" type="success" v-if="scope.row.isActive">Yes</el-tag>
                  <el-tag size="small" type="danger" v-else>No</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column sortable width="140" class="text-capitalize"
                             :label="getMessageByCode('tenancyStart') ?? 'Tenancy Start'"
                             prop="tenancyStart">
              <template #default="scope">
                <span>{{ getFormattedDate(scope.row.startDate) }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable width="130" class="text-capitalize"
                             :label="getMessageByCode('tenancyEnd') ?? 'Tenancy End'"
                             prop="tenancyEnd">
              <template #default="scope">
                <span>{{ getFormattedDate(scope.row.endDate) }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable width="100" class="text-capitalize"
                             :label="getMessageByCode('rent') ?? 'Rent'+ ' ('+currency+')'"
                             prop="rent"
                             min-width="200">
              <template #default="scope">
                <div class="text-end">
                  <span>{{ getFormattedValue(scope.row.rent) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column sortable class="text-capitalize"
                             :label="getMessageByCode('commission') ?? 'Commission' + ' ('+currency+')'"
                             prop="commission"
                             width="150">
              <template #default="scope">
                <div class="text-end">
                  <span>{{ getFormattedValue(scope.row.commission) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" class="text-capitalize"
                             :label="getMessageByCode('actions') ?? 'Actions'" width="130">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <el-tooltip content="Export Pdf">
                    <i
                        class="mgc_pdf_line text-warning action-btn"
                        @click="editAgreement(scope.row,'pdf')"
                    ></i>
                  </el-tooltip>
                  <i
                      @click="editAgreement(scope.row, 'edit')"
                      class="mgc_edit_line text-primary action-btn"

                  ></i>

                  <i @click="editAgreement(scope.row, 'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('moneyTrail') ?? 'Money Trail'" :name="2">
          <div class="d-flex justify-content-end py-1">
            <el-button type="primary"
                       @click="addTrailDialog = true; resetTrail();">
              {{ getMessageByCode('add') ?? 'Add' }}
            </el-button>
          </div>
          <el-table :data="trails"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column :show-overflow-tooltip="true" :label="getMessageByCode('tenant') ?? 'Contact'"
                             prop="contact" min-width="120"
                             sortable>
            </el-table-column>
            <el-table-column sortable :show-overflow-tooltip="true" :label="getMessageByCode('property') ?? 'Property'"
                             prop="property" min-width="250"/>
            <el-table-column sortable :show-overflow-tooltip="true" :label="getMessageByCode('category') ?? 'Category'"
                             prop="category" min-width="120"/>
            <el-table-column :show-overflow-tooltip="true" sortable
                             :label="getMessageByCode('rent') ?? 'Rent'+ ' ('+currency+')'"
                             prop="amount" width="100">
              <template #default="scope">
                <div class="text-end">
                  <span>{{ scope.row.amount.toFixed(2) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column sortable :label="getMessageByCode('date') ?? 'Date'" prop="date" min-width="120">
              <template #default="scope">
                <span>{{ getFormattedDateTime(scope.row.addedDate) }}</span>
              </template>
            </el-table-column>

            <el-table-column sortable :label="getMessageByCode('income') ?? 'Income' + '?'" prop="in" min-width="100">
              <template #default="scope">
                <div class="text-center">
                  <n-tag size="small" :bordered="false" type="success" v-if="scope.row.in">
                    Yes
                  </n-tag>
                  <n-tag v-else size="small" :bordered="false" type="error">
                    No
                  </n-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions') ?? 'Actions'" width="100">
              <template #default="scope">
                <div class="d-flex justify-content-center">
                  <el-tooltip content="Export Pdf">
                    <i
                        class="mgc_pdf_line text-warning action-btn"
                        @click="takeTrail(scope.row,'pdf')"
                    ></i>
                  </el-tooltip>
                  <el-tooltip content="Edit">
                    <i @click="takeTrail(scope.row, 'edit')"
                       class="mgc_edit_line text-primary action-btn ms-2"
                    ></i>
                  </el-tooltip>
                  <el-tooltip content="Delete">
                    <i @click="takeTrail(scope.row, 'delete')"
                       class="mgc_delete_2_line text-danger action-btn ms-2"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('notes') ?? 'Notes'" :name="3">
          <div class="d-flex justify-content-end py-1">
            <el-button type="primary" @click="addNoteDialog = true; resetNote();">
              {{ getMessageByCode('addNote') ?? 'Add Note' }}
            </el-button>
          </div>
          <el-table :data="property.notes"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column width="45">
              <template #default="scope">
                <el-tooltip :content="getMessageByCode('unpin') ?? 'Unpin'" v-if="scope.row.pinned">
                  <i @click="unPinNote(scope.row)" class="bi bi-pin-angle-fill primary-color action-btn"></i>
                </el-tooltip>
                <el-tooltip v-else :content="getMessageByCode('pin') ?? 'Pin'">
                  <i @click="pinNote(scope.row)" class="bi bi-pin-angle  primary-color action-btn"></i>
                </el-tooltip>

              </template>
            </el-table-column>
            <el-table-column sortable :label="getMessageByCode('dateTime')??'Date Time'" width="170" prop="addedDate">
              <template #default="scope">
                <span>{{ getFormattedCheckedDate(scope.row.addedDate) }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="150" :label="getMessageByCode('note')??'Note'" prop="note" sortable/>


            <el-table-column sortable :label="getMessageByCode('reminderDate')??'Reminder Date'" width="150"
                             prop="reminderDate">
              <template #default="scope">
                <div class="text-end">
                  <span>{{ getFormattedDate(scope.row.reminderDate) }}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column sortable :label="getMessageByCode('checkedDate')??'Checked Date'" width="200"
                             prop="checkedDate">
              <template #default="scope">
                <el-switch v-if="scope.row.requireCheck && !scope.row.isChecked" v-model="scope.row.isChecked"
                           @change="markChecked(scope.row)"></el-switch>
                <span v-else> {{ getFormattedCheckedDate(scope.row.checkedDate) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i
                      class="mgc_edit_line text-primary action-btn"
                      @click="editNote(scope.row,'edit')"
                  ></i>
                  <i @click="editNote(scope.row,'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('documents') ?? 'Documents'" :name="4">
          <div class="d-flex justify-content-end py-1">
            <el-button type="primary" @click="addDocumentDialog = true;resetDocument()">{{
                getMessageByCode('addDocument') ?? 'Add Document'
              }}
            </el-button>
          </div>
          <el-table :data="property.documents"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column show-overflow-tooltip sortable min-width="150"
                             :label="getMessageByCode('fileName')??'File Name'" prop="fileName"
            />

            <el-table-column show-overflow-tooltip sortable width="150"
                             :label="getMessageByCode('fileExtension')??'File Extension'"
                             prop="fileExtension"></el-table-column>

            <el-table-column show-overflow-tooltip sortable width="250"
                             :label="getMessageByCode('category')??'Category'"
                             prop="category">
            </el-table-column>


            <el-table-column show-overflow-tooltip sortable width="150"
                             :label="getMessageByCode('fileSize')??'File Size'" prop="documentSize"
            >
              <template #default="scope">
                <span>{{ formatFileSize(scope.row.fileSize) }}</span>
              </template>
            </el-table-column>


            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <el-tooltip :content="getMessageByCode('download') ?? 'Download'">
                    <i class="bi bi-download action-btn" @click="downloadDocument(scope.row)"></i>
                  </el-tooltip>
                  <i
                      class="mgc_edit_line text-primary action-btn"
                      @click="editDocument(scope.row,'edit')"
                  ></i>
                  <i @click="editDocument(scope.row,'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('receipts') ?? 'Receipts'" :name="5">
          <div class="d-flex justify-content-end py-1">
            <el-button type="primary" @click="addReceiptDialog = true;resetReceipt()">{{
                getMessageByCode('addReceipt') ?? 'Add Receipt'
              }}
            </el-button>
          </div>
          <el-table :data="receipts"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column show-overflow-tooltip sortable width="150" :label="getMessageByCode('tenant')??'Tenant'"
                             prop="tenant"></el-table-column>
            <el-table-column show-overflow-tooltip sortable min-width="250"
                             :label="getMessageByCode('property')??'Property'" prop="property"
            />


            <el-table-column show-overflow-tooltip sortable width="120"
                             :label="currency + ' ' + (getMessageByCode('amount') || 'Amount')"
                             prop="amount">

              <template #default="scope">
                <div class="text-end">
                  <span>{{ scope.row.amount.toFixed(2) }}</span>
                </div>
              </template>

            </el-table-column>


            <el-table-column show-overflow-tooltip sortable width="200" :label="getMessageByCode('date_time')??'Date & Time'"
            >
              <template #default="scope">
                <span>{{ getFormattedDateTime(scope.row.receiptDate) }}</span>
              </template>
            </el-table-column>
<!--            <el-table-column show-overflow-tooltip sortable width="100" :label="getMessageByCode('time')??'Time'"-->
<!--            >-->
<!--              <template #default="scope">-->
<!--                <span>{{ getFormattedTime(scope.row.receiptDate) }}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->


            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <el-tooltip content="Export Pdf">
                    <i
                        class="mgc_pdf_line text-warning action-btn"
                        @click="editReceipt(scope.row,'pdf')"
                    ></i>
                  </el-tooltip>

                  <i
                      class="mgc_edit_line text-primary action-btn"
                      @click="editReceipt(scope.row,'edit')"
                  ></i>
                  <i @click="editReceipt(scope.row,'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('emails') ?? 'Emails'" :name="6">
          <div class="d-flex justify-content-end py-1">
            <el-button type="primary" @click="addEmailDialog = true;resetEmail()">{{
                getMessageByCode('sendEmail') ?? 'Send Email'
              }}
            </el-button>
          </div>
          <el-table :data="emails"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column show-overflow-tooltip sortable width="200"
                             :label="getMessageByCode('sentDate')??'Sent Date'"
                             prop="sentDate">
              <template #default="scope">
                <span>{{ getEmailFormattedDate(scope.row.sentDate) }}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip sortable width="200" :label="getMessageByCode('to')??'To'"
                             prop="to">
              <template #default="scope">
                <span>{{ scope.row.toName }}</span>
                <n-popover trigger="hover">
                  <template #trigger>
                    <i class="bi bi-envelope ms-2 action-btn text-primary"></i>
                  </template>
                  <span>{{ scope.row.to }}</span>
                </n-popover>
              </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('subject')??'Subject'"
                             prop="subject"></el-table-column>

            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <el-tooltip :content="getMessageByCode('attachments') ?? 'Attachments'">
                    <i @click="editEmail(scope.row,'attach')" class="action-btn bi bi-paperclip ms-2"
                       style="font-size: 13px; rotate: 45deg"></i>
                  </el-tooltip>
                  <i
                      class="mgc_eye_2_line text-primary action-btn"
                      @click="editEmail(scope.row,'edit')"
                  ></i>
                  <i @click="editEmail(scope.row,'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>


    <!--    Dialogs-->
    <el-dialog v-model="uploadImage" :title="getMessageByCode('uploadImage') ?? 'Upload Image/s'"
               style="width: auto; max-width: 500px">
      <el-form label-position="top" require-asterisk-position="right">
        <el-form-item :label="getMessageByCode('category') ?? 'Category'">
          <el-select v-model="image.categoryId">
            <el-option v-for="type in imageCategories"
                       :key="type.optId"
                       :label="type.optTitle"
                       :value="type.optId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="getMessageByCode('description') ?? 'Description'">
          <el-input type="textarea" :rows="3" v-model="image.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="image.fileName">
            <template #prepend>
              <el-upload id="upload-photo" multiple accept="image/*" :key="fileInputKey" :auto-upload="false"
                         @input="onFileInput" :show-file-list="false"
                         class="mb-0">
                <template #trigger>
                  <el-button type="primary">{{ getMessageByCode('selectimages') ?? 'Select Image(s)' }}</el-button>
                </template>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-collapse-transition>
          <el-form-item v-if="property.images.length >0">
            <ul class="list-group  w-100">
              <li class="list-group-item py-0 d-flex justify-content-between" v-for="(img,index) in property.images"
                  :key="index">
                <div class="text-caption">
                  <span class="me-1">{{ index }}.</span>
                  <el-tooltip :content="img.fileName">
                    <span>{{ truncateFileName(img.fileName) }}</span>
                  </el-tooltip>
                </div>
                <i class="bi bi-x-circle action-btn" @click="removeImage(img)"></i>
              </li>
            </ul>
          </el-form-item>
        </el-collapse-transition>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg class="ms-2" @click="clearImages('cancel'); uploadImage = false">Cancel</el-button>
          <el-button type="primary" class="ms-2" @click="uploadImage = false;clearImages('ok')">Ok</el-button>
        </div>
      </template>
      <n-spin class="fileUpload" stroke="#2a5699" size="small" v-if="uploadingFiles"></n-spin>
    </el-dialog>
    <el-dialog v-model="addNoteDialog"
               :title="note.id > 0 ?  getMessageByCode('updateNote') ?? 'Update Note' :  getMessageByCode('addNote') ?? 'Add Note'"
               style="width: auto; max-width: 500px">
      <el-form label-position="top" require-asterisk-position="right">
        <!--        <el-form-item :label="getMessageByCode('assignedTo') ?? 'Assigned To'">
                  <el-select v-model="note.assignedTo">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>-->
        <el-form-item :label="getMessageByCode('note') ?? 'Note'">
          <el-input type="textarea" :rows="5" v-model="note.note"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div class="row w-100">
              <span class="col">{{ getMessageByCode('reminderDate') ?? 'Reminder Date' }}</span>
              <div class="col d-flex justify-content-between">
                <span>{{ getMessageByCode('requiredCheck') ?? 'Required Check?' }}</span>
                <span>{{ getMessageByCode('pinned') ?? 'Pinned?' }}</span>
              </div>
            </div>
          </template>
          <div class="row w-100">
            <el-date-picker v-model="note.reminderDate" :format="dateFormat" :editable="false"
                            class="w-75 col"></el-date-picker>
            <div class="col d-flex justify-content-between">
              <div class="d-flex justify-content-end">
                <el-switch @change="note.requireCheck ? note.reminderDate = new Date() : note.reminderDate = null"
                           v-model="note.requireCheck"></el-switch>
              </div>
              <div class="d-flex justify-content-end">
                <el-switch v-model="note.pinned"></el-switch>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg class="ms-2" @click="addNoteDialog = false">Cancel</el-button>
          <el-button type="primary" class="ms-2" @click="addNoteDialog = false;addPropertyNote()">
            {{
              getMessageByCode('addNote') ?? 'Add Note'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addDocumentDialog"
               :title="document.id > 0 ?  getMessageByCode('updateDocument') ?? 'Update Document' :  getMessageByCode('addDocument') ?? 'Add Document'"
               style="width: auto; max-width: 500px">
      <el-form label-position="top" require-asterisk-position="right">
        <el-form-item :label="getMessageByCode('category') ?? 'Category'">
          <el-select v-model="document.categoryId">
            <el-option v-for="item in documentCategories"
                       :key="item.optId"
                       :label="item.optTitle"
                       :value="item.optId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="getMessageByCode('document') ?? 'Document'">
          <el-input :disabled="document.id > 0" v-model="document.fileName">
            <template #prepend>
              <el-upload :auto-upload="false" :on-change="handleFileChange" :show-file-list="false"
                         class="mb-0">
                <template #trigger>
                  <el-button :disabled="document.id > 0" type="primary">
                    {{ getMessageByCode('select_file') ?? 'select file' }}
                  </el-button>
                </template>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg class="ms-2" @click="addDocumentDialog = false">Cancel</el-button>
          <el-button v-if="document.id < 1" type="primary" class="ms-2"
                     @click="addDocumentDialog = false;addPropertyDocument()">
            {{
              getMessageByCode('addDocument') ?? 'Add Document'
            }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="addDocumentDialog = false;updatePropertyDocument()">
            {{
              getMessageByCode('updateDocument') ?? 'Update Document'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addReceiptDialog" :center="true"
               :title="receipt.id > 0 ?  getMessageByCode('updateReceipt') ?? 'Update Receipt' :  getMessageByCode('addReceipt') ?? 'Add Receipt'"
               style="width: auto; max-width: 500px">
      <el-form label-position="top" require-asterisk-position="right">
        <el-form-item :label="getMessageByCode('property') ?? 'Property'">
          <el-input v-model="property.propertyName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="getMessageByCode('tenant') ?? 'Tenant'">
          <el-select v-model="receipt.tenantId">
            <el-option v-for="item in pmsTenants"
                       :key="item.id"
                       :label="getTenantName(item)"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <div class="row">
          <el-form-item class="col-6" :label="getMessageByCode('amount') ?? 'Amount'">
            <el-input type="number" v-model="receipt.amount">

            </el-input>
          </el-form-item>
          <el-form-item class="col-6" :label="getMessageByCode('receiptDate') ?? 'Receipt Date'">
            <el-date-picker class="w-100" :format="dateFormat" :editable="false" v-model="receipt.receiptDate">
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item :label="getMessageByCode('notes') ?? 'Notes'">
          <el-input type="textarea" :rows="3" v-model="receipt.notes"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg class="ms-2" @click="addReceiptDialog = false">Cancel</el-button>
          <el-button v-if="receipt.id < 1" type="primary" class="ms-2"
                     @click="addReceiptDialog = false;addReceipt()">
            {{
              getMessageByCode('addReceipt') ?? 'Add Receipt'
            }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="addReceiptDialog = false;updateReceipt()">
            {{
              getMessageByCode('updateReceipt') ?? 'Update Receipt'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addEmailDialog" align-center
               :title="email.id > 0 ?  getMessageByCode('viewEmail') ?? 'View Email' :  getMessageByCode('addEmail') ?? 'Add Email'"
               style="width: auto; max-width: 670px">
      <el-form label-position="top" require-asterisk-position="right" class="row">

        <el-form-item required class="col-12 col-md-6">
          <div class="d-flex align-items-center w-100">
            <el-select class="" filterable :disabled="email.id > 0" v-model="email.contactId"
                       :placeholder="getMessageByCode('to') ?? 'To'">
              <el-option v-for="item in contacts"
                         :key="item.id"
                         :label="getTenantName(item)"
                         :value="item.id">
                <template #default>
                  <div class="row">
                    <span class="col-7">{{ getTenantName(item) }}</span>
                    <span class="text-small col-5">{{ item.category }}</span>
                  </div>
                </template>
              </el-option>
            </el-select>
            <!--            <el-tooltip-->
            <!--                v-if="email.id < 1 && (clientMarket === 'Housing Management' || clientMarket === 'Property Management')"-->
            <!--                :content="getMessageByCode('addProperty') ?? 'Add Property'">-->
            <!--              <el-button :disabled="email.id > 0" @click="propertyDisplay = !propertyDisplay; email.propertyId  = ''"-->
            <!--                         :type="propertyDisplay ? 'primary' : 'default'"-->
            <!--                         class="px-2 ms-1">-->
            <!--                <i class="bi bi-house-add fs-3 action-btn"></i>-->
            <!--              </el-button>-->
            <!--            </el-tooltip>-->
            <el-tooltip v-if="email.id < 1" :content="getMessageByCode('addCC') ?? 'Add CC'">
              <el-button :disabled="email.id > 0" @click="ccDisplay = !ccDisplay; email.copyTo = ''"
                         :type="ccDisplay ? 'primary' : 'default'" class="px-2 ms-1">
                <i class="bi bi-cc-circle fs-3 action-btn"></i>
              </el-button>
            </el-tooltip>
          </div>
        </el-form-item>
        <!--        <transition name="el-zoom-in-left">-->
        <!--          <el-form-item v-if="propertyDisplay || (email.id > 1 && email.propertyId > 0)" class="col-12 col-md-6">-->
        <!--            <el-select :disabled="email.id > 0" placeholder="Select Property" v-model="email.propertyId" clearable-->
        <!--                       @clear="email.propertyId = ''">-->
        <!--              <el-option v-for="item in properties"-->
        <!--                         :key="item.id"-->
        <!--                         :label="item.propertyName"-->
        <!--                         :value="item.id"></el-option>-->
        <!--            </el-select>-->
        <!--          </el-form-item>-->
        <!--        </transition>-->
        <el-collapse-transition>
          <el-form-item v-if="ccDisplay || (email.id > 1 && email.copyTo)" class="mt-1">
            <el-input :placeholder="getMessageByCode('cc') ?? 'CC'" :disabled="email.id > 0"
                      v-model="email.copyTo"></el-input>
          </el-form-item>
        </el-collapse-transition>
        <el-form-item required class="mt-1">
          <el-input :placeholder="getMessageByCode('subject') ?? 'Subject'" :disabled="email.id > 0"
                    v-model="email.subject" :maxlength="250" show-word-limit></el-input>
        </el-form-item>
        <el-form-item :disabled="email.id > 0" class="mt-1">

          <div v-if="email.id < 1" class="w-100">
            <ckeditor

                :disabled="email.id > 1"
                :editor="editor"
                class="w-100"
                v-model="email.body"
                :config="editorConfig"
                style="width: 100% !important; min-height: 150px !important;">
            </ckeditor>
          </div>

          <div v-else style="border: 1px solid #e2e3e5; padding: 5px; border-radius: 5px; width: 100%"
               v-html="email.body"></div>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <el-upload v-if="email.id < 1" id="upload-photo" multiple accept="image/*" :key="fileInputKey"
                           :auto-upload="false"
                           @input="onFileInput2" :show-file-list="false"
                           class="mb-0">
                  <template #trigger>
                    <el-button type="primary" size="small" class="mt-1">
                      {{ getMessageByCode('addAttachments') ?? 'Add Attachments' }} <i class="bi bi-upload ms-2"></i>
                    </el-button>
                  </template>
                </el-upload>
                <span
                    v-if="email.id > 1 && email.attachments.length > 0">{{
                    getMessageByCode('attachments') ?? 'Attachments'
                  }}</span>
              </div>
              <div class="d-flex align-items-center">
                <span class="fs-normal me-2">{{ getMessageByCode('addSignature') ?? 'Add Signature' }}?</span>
                <el-checkbox v-model="email.addSignature"></el-checkbox>
              </div>
            </div>
          </template>
          <template #default>
            <div class="container-fluid">
              <div class="row">
                <div
                    class="col-md-6 mb-0 "
                    v-for="(attach, index) in email.attachments"
                    :key="index"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <span class="fs-normal">{{ index + 1 }})</span>
                      <el-tooltip :content="attach.fileName">
                        <span class="ms-2 fs-normal">{{ truncateString(attach.fileName) }}</span>
                      </el-tooltip>
                      <span class="ms-2 fs-normal">({{ SizeCalc.FormattedSize(attach.fileSize) }})</span>

                    </div>
                    <el-tooltip v-if="email.id < 1" :content="getMessageByCode('remove') ?? 'Remove'">
                      <n-button @click="removeAttachment(attach)" tertiary size="tiny" class="ms-2" circle>
                        <i class="bi bi-x"></i>
                      </n-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <div v-if="email.id < 1">
            <el-button text bg class="ms-2" @click="addEmailDialog = false">Cancel</el-button>
            <el-button type="primary" class="ms-2"
                       :disabled="email.contactId < 1 || !email.subject || !email.body"
                       @click="addEmail()">
              {{
                getMessageByCode('sendEmail') ?? 'Send Email'
              }}
            </el-button>
          </div>
          <el-button v-else type="primary" class="ms-2"
                     @click="addEmailDialog = false">
            {{
              getMessageByCode('close') ?? 'Close'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="deleteNoteDialog"
               :title="getMessageByCode('deleteNote') ?? 'Delete Note'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold text-danger"> {{
               note.note
             }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteNoteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteNoteDialog = false; deletePropertyNote()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteEmailDialog"
               :title="getMessageByCode('deleteEmail') ?? 'Delete Email'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold ">selected email?</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteEmailDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteEmailDialog = false; deleteEmail()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDocumentDialog"
               :title="getMessageByCode('deleteDocument') ?? 'Delete Document'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold text-danger"> {{
               document.fileName
             }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDocumentDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteDocumentDialog = false; deletePropertyDocument()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteReceiptDialog"
               :title="getMessageByCode('deleteReceipt') ?? 'Delete Receipt'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} selected receipt?</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteReceiptDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteReceiptDialog = false; deleteReceipt()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="viewAttachments"
               :title="getMessageByCode('attachments') ?? 'Attachments'"
               style="width: auto; max-width: 500px">
      <n-list v-if="email.attachments.length > 0" bordered>
        <n-list-item v-for="(attachment,index) in email.attachments" :key="attachment.id" class="py-1">
          <div class="d-flex justify-content-between align-items-center fs-normal">
            <div>
              <span>{{ index + 1 }})</span>
              <span class="ms-1">{{ attachment.fileName }}</span>
              <span class="text-sm ms-2">({{ SizeCalc.FormattedSize(attachment.fileSize) }})</span>
            </div>
            <div>
              <el-tooltip :content="getMessageByCode('download') ?? 'Download'">
                <i class="bi bi-download action-btn text-primary" @click="downloadAttachment(attachment)"></i>
              </el-tooltip>
            </div>
          </div>
        </n-list-item>
      </n-list>
      <span v-else>
        {{ getMessageByCode('noAttachments') ?? 'No attachments available for the email' }}
      </span>
    </el-dialog>
    <!--   Add Agreement Dialog-->
    <el-dialog v-model="addAgreementDialog" style="width: auto; max-width: 700px"
               :title="getMessageByCode('add_agree') ?? 'Add Agreement'" align-center>
      <template #default>
        <el-form label-position="top" require-asterisk-position="right">
          <div class="row">


            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('tenant') ?? 'Tenant'">
              <el-select v-model="agreement.tenantId" filterable class="w-100"
                         placeholder="Select Tenant"
              >
                <el-option
                    v-for="item in tenants"
                    :key="item.id"
                    :label="getTenantName(item) + ''"
                    :value="item.id"
                    :disabled="item.id < 1"
                >
                  <template #default>
                    <span>{{ getTenantName(item) }}</span> <span v-if="item.activeAgreement"
                                                                 class="text-small fw-normal text-black">(Rented)</span>
                  </template>


                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('property') ?? 'Property'">
              <el-input v-model="property.propertyName" disabled></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('start_date') ?? 'Start Date'">
              <el-date-picker class="w-100" :format="dateFormat" v-model="agreement.startDate"
                              @change="changeStartDate()"
                              :editable="false"></el-date-picker>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('end_date') ?? 'End Date'">
              <el-date-picker clearable class="w-100" :format="dateFormat" v-model="agreement.endDate"
                              @clear="agreement.endDate = null" @change="changeEndDate()"
                              :editable="false"></el-date-picker>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('leave_date') ?? 'Leave Date'">
              <el-date-picker class="w-100" :format="dateFormat" v-model="agreement.leaveDate"
                              @change="changeLeaveDate()" :editable="false"></el-date-picker>
            </el-form-item>
            <div class="col-12 col-md-3">

            </div>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('rent') ?? 'Rent'">
              <el-input type="number" class="w-100" controls-position="right"
                        v-model.number="rent">
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('commission') ?? 'Commission'">
              <el-input type="number" class="w-100" controls-position="right"
                        v-model.number="commission">
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('deposit') ?? 'Deposit'">
              <el-input type="number" class="w-100" controls-position="right"
                        v-model.number="securityDeposit">
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-3" :label="getMessageByCode('deposit_day') ?? 'Payment Day'">
              <el-input type="number" class="w-100" controls-position="right" :max="30" v-model="agreement.paymentDay"
                        @input="validatePaymentDay"></el-input>
              <el-collapse-transition>
                <span v-if="paymentDayError" class="text-small text-danger">Payment day must be between 1 and 30</span>
              </el-collapse-transition>
            </el-form-item>

            <el-form-item class="col-12" :label="getMessageByCode('notes') ?? 'Notes'">
              <el-input type="textarea" :rows="3" v-model="agreement.notes"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addAgreementDialog = false">{{
              getMessageByCode('cancel') ?? 'Cancel'
            }}
          </el-button>
          <el-button v-if="agreement.id < 1" type="primary" class="ms-2"
                     @click="addAgreementDialog = false; addAgreement()"
                     :disabled="agreement.tenantId < 1 || (agreement.paymentDay < 1 || agreement.paymentDay > 30)">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="addAgreementDialog = false; updateAgreement()"
                     :disabled="agreement.tenantId < 1 || (agreement.paymentDay < 1 || agreement.paymentDay > 30)">
            {{ getMessageByCode('update') ?? 'Update' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
        v-model="deleteAgreementDialog"
        :title="getMessageByCode('delete') ?? 'Delete' +' '+ getMessageByCode('agreement') ?? 'Agreement'"
        width="30%"
    >
      <template #header>
        <span>{{ getMessageByCode('delete') ?? ' Delete' }} </span>
        <span> {{ getMessageByCode('agreement') ?? ' Agreement' }}</span>
      </template>
      <span>{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }}</span> <span
        class="text-danger"> selected agreement?</span>

      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteAgreementDialog = false">{{
            getMessageByCode('cancel') ?? 'Cancel'
          }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteAgreementDialog = false; deleteAgreement()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
    <!--    Money Trail Dialogs-->
    <el-dialog v-model="addTrailDialog" style="width: auto; max-width: 600px" :align-center="true"
               :title="trail.id < 1 ? getMessageByCode('addTrial') ?? 'Add Trail' : getMessageByCode('updateTrail') ?? 'Update Trail'">
      <template #default>
        <el-form class="row" label-position="top">
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('contact') ?? 'Contact'">
            <el-select class="w-100" filterable
                       v-model="trail.contactId" placeholder="Select Contact">
              <el-option
                  v-for="item in contacts"
                  :key="item.id"
                  :label="item.firstName +' ' + item.surname ?? ''"
                  :value="item.id"
              >
                <template #default>
                  <div class="row">
                    <span class="col-7">{{ item.firstName + ' ' + item.surname ?? '' }}</span>
                    <span class="text-small col-5">{{ item.category }}</span>
                  </div>
                </template>
              </el-option>
            </el-select>
            <el-collapse-transition>
              <span v-if="!validateForm.contactValid" class="text-small text-danger">Please select a contact</span>
            </el-collapse-transition>
          </el-form-item>

          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('property') ?? 'Property'">
            <el-input disabled v-model="property.propertyName"></el-input>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('type') ?? 'Type'">
            <el-select v-model="trail.categoryId">
              <el-option
                  v-for="item in moneyCategories"
                  :key="item.optId"
                  :label="item.optTitle"
                  :value="item.optId"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('date') ?? 'Entry Date'">
            <el-date-picker class="w-100" :format="dateFormat" :editable="false"
                            v-model="trail.entryDate"></el-date-picker>
          </el-form-item>
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between">
            <el-form-item class="col-12 col-md-6 w-auto" :label="getMessageByCode('amount') ?? 'Amount'">
              <el-input
                  type="number"
                  v-model="formattedAmount"
              >
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-checkbox v-model="trail.in">In?</el-checkbox>
          </div>

          <el-form-item class="col-12" :label="getMessageByCode('description') ?? 'Description'">
            <el-input type="textarea" :rows="3" v-model="trail.description"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="d-flex justify-content-between w-100">
              <el-upload id="upload-photo" accept="image/*" :key="fileInputKey" :auto-upload="false"
                         @input="onFileInput" :show-file-list="false"
                         class="mb-0">
                <template #trigger>
                  <el-button bg text class="py-5 upload-btn">{{
                      getMessageByCode('selectFile') ?? 'Select File'
                    }}
                  </el-button>
                </template>
              </el-upload>
              <el-image v-if="trail.image" fit="fill" style="width: 125px; height: 95px" :src="trail.image"></el-image>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="addTrailDialog = false" class="me-2">Cancel</el-button>
          <el-button v-if="trail.id < 1" type="primary" @click=" addTrail()">Add Trail</el-button>
          <el-button v-else type="primary" @click="updateTrail()">Update Trail</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
        v-model="deleteTrailDialog"
        class="text-capitalize"
        :title="getMessageByCode('delete') ?? 'Delete'"
        width="30%"
    >
      <span>{{
          getMessageByCode('do_u_del') ?? 'Do you want to delete'
        }} </span><span> selected trail?</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteTrailDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteTrailDialog = false;deleteTrail()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>


<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {getLanguage} from "@/Utility/getLanguage";
import propertyService from "@/Services/ComponentsServices/pmsPropertiesService";
import {ShowMessage} from "@/Utility/Utility";
import store from "@/store";
import ClientService from "@/Services/ComponentsServices/clientService";
import contactService from "@/Services/ComponentsServices/contactService";
import optionService from "@/Services/ComponentsServices/optionService";
import {NImage, NSpin, NButton, NList, NListItem} from "naive-ui";
import moment from "moment/moment";
import configService from "@/Services/ComponentsServices/configService";
import pmsPropertiesService from "@/Services/ComponentsServices/pmsPropertiesService";
import pmsTenantService from "@/Services/ComponentsServices/pmsTenantService";
import receiptService from "@/Services/ComponentsServices/receiptService";
import pmsEmailService from "@/Services/ComponentsServices/emailService";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {NPopover} from "naive-ui";
import {NTable,NTag} from "naive-ui";
import emailService from "@/Services/ComponentsServices/emailService";
import SizeCalc from "../Services/SizeCalc";
import tenancyAgreementService from "@/Services/ComponentsServices/tenancyAgreementService";
import moneyTrailService from "@/Services/ComponentsServices/moneyTrailService";

// import SizeCalc from "@/components/Services/SizeCalc";

export default {
  name: "AddPmsProperties",
  components: {NTable,NTag, NImage, BreadCrumb, NSpin, ckeditor: CKEditor.component, NPopover, NButton, NList, NListItem},
  data() {
    return {
      loading: false,
      edit: false,
      addAgreementDialog: false,
      addTrailDialog: false,
      viewAttachments: false,
      deleteAgreementDialog: false,
      uploadImage: false,
      uploadingFiles: false,
      addNoteDialog: false,
      addReceiptDialog: false,
      addDocumentDialog: false,
      addEmailDialog: false,
      deleteEmailDialog: false,
      deleteDocumentDialog: false,
      deleteReceiptDialog: false,
      deleteTrailDialog: false,
      deleteNoteDialog: false,
      editor: ClassicEditor,
      currency: "£",
      currencyPosition: "left",
      paymentDayError: false,
      editorConfig: {
        height: 500
      },
      validateForm: {
        contactValid: true,
        propertyValid: true,
      },
      clientMarket: sessionStorage.getItem("market") ?? "",
      activeTab: 0,
      translations: [],
      fileInputKey: new Date(),
      clientId: sessionStorage.getItem("clientId"),
      clients: [],
      moneyCategories: [],
      landords: [],
      moneyTrails: [],
      pmsTenants: [],
      contacts: [],
      agreements: [],
      documentCategories: [],
      categories: [
        {key: 1, value: "To Let"},
        {key: 2, value: "For Sale"}
      ],
      tenants: [],
      agreement: {
        "id": 0,
        "clientId": "",
        "tenantId": "",
        "startDate": new Date(),
        "endDate": null,
        "propertyId": 0,
        "landlordContactId": 0,
        "rent": 0.00,
        "commission": 0.00,
        "paymentDay": 1,
        "notes": "",
        "securityDeposit": 0,
        "leaveDate": null,
        "isActive": true,
      },
      trailContacts: [],
      contactCategories: [],
      trail: {
        "id": 0,
        "tenantId": "",
        "contactId": 0,
        "clientId": 0,
        "propertyId": 0,
        "entryDate": new Date(),
        "categoryId": 0,
        "description": "",
        "amount": 0,
        "in": true,
        "notes": "",
        "isDeleted": false,
        "image": "",
      },
      beds: [
        {key: 1, value: 1},
        {key: 2, value: 2},
        {key: 3, value: 3},
        {key: 4, value: 4},
        {key: 4, value: 5},
      ],
      bathrooms: [
        {key: 1, value: 1},
        {key: 2, value: 2},
        {key: 3, value: 3},
      ],
      propertyStatus: [],
      status2: [],
      types: [],
      imageCategories: [],
      image: {
        propertyId: 0,
        fileData: "",
        fileName: "",
        fileSize: "",
        fileExtension: "",
        mimeType: "",
        sortOrder: 0,
        categoryId: "",
        description: "",
      },
      property: {
        "id": 0,
        "propertyName": "",
        "landlordId": "",
        "clientId": 0,
        "categoryId": 1,
        "reference": "",
        "price": 0,
        "typeOfProperty": 0,
        "numberOfBeds": 1,
        "numberOfBathrooms": 1,
        "propertyStatus": 0,
        "status": 0,
        "address1": "",
        "address2": "",
        "address3": "",
        "postcode": "",
        "displayOrder": 0,
        "description": "",
        "pets": true,
        "smoke": true,
        "active": true,
        "city": "",
        "region": "",
        images: [],
        notes: [],
        documents: []
      },
      trails: [],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        tenantId: 0,
        propertyId: store.state.pageId,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
      dateFormat: "DD/MM/YYYY",
      fromEmail: "",
      note: {
        "id": 0,
        "propertyId": 0,
        "note": "",
        "addedBy": "",
        "addedDate": new Date(),
        "assignedTo": 0,
        "reminderDate": null,
        "requireCheck": false,
        "isChecked": false,
        "checkedDate": null,
        "pinned": false
      },
      document: {
        "id": 0,
        "propertyId": 0,
        "categoryId": 0,
        "fileName": "",
        "fileExtension": "",
        "fileSize": "",
        "fileData": "",
        "addedDate": new Date(),
        "addedBy": "",
        "mimeType": "",
      },
      receipts: [],
      receipt: {
        "id": 0,
        "propertyId": 0,
        "templateId": 0,
        "tenantId": 0,
        "amount": 0,
        "receiptDate": new Date(),
        "notes": ""
      },
      emails: [],
      email: {
        "id": 0,
        "userId": sessionStorage.getItem("userId"),
        "emailFrom": "",
        "emailTo": "",
        "copyTo": "",
        "subject": "",
        "body": "Email body goes here",
        "propertyId": 0,
        "clientId": 0,
        "sentDate": new Date(),
        "status": 0,
        "contactId": "",
        "providerId": 0,
        "addSignature": false,
        attachments: [],
      },
      attachment: {
        "id": 0,
        "emailId": 0,
        "fileName": "",
        "fileExtension": "",
        "fileSize": 0,
        "mimeType": "",
        "fileData": "",
        "isDeleted": false,
        "addedDate": new Date(),
        "addedBy": "",
        "modifiedDate": null,
        "modifiedBy": "",
        "deletedDate": null,
        "deletedBy": ""
      },
      propertyDisplay: false,
      ccDisplay: false,
      dialogFilter: {
        clientId: Number(sessionStorage.getItem("clientId") ?? 0),
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1,
        clientGuid: 0,
        categoryId: 0,
        contactId: "",
      },
    }
  },
  computed: {
    formattedAmount: {
      get() {
        // Format the amount for display
        return this.trail.amount !== null
            ? this.trail.amount.toFixed(2)
            : '';
      },
      set(value) {
        // Remove formatting and update the actual amount
        this.trail.amount = parseFloat(value) || 0;
      },
    },
    rent: {
      get: function () {
        return this.agreement.rent.toFixed(2)
      },
      set: function (newValue) {
        return this.agreement.rent = newValue
      }
    },
    commission: {
      get: function () {
        return this.agreement.commission.toFixed(2)
      },
      set: function (newValue) {
        return this.agreement.commission = newValue
      }
    },
    securityDeposit: {
      get: function () {
        return this.agreement.securityDeposit.toFixed(2)
      },
      set: function (newValue) {
        return this.agreement.securityDeposit = newValue
      }
    },
    SizeCalc() {
      return SizeCalc
    },
    pmsTenantService() {
      return pmsTenantService
    },

    truncateFileName() {
      return function (fileName) {
        return fileName.length > 20 ? fileName.substring(0, 20) + '...' : fileName;
      }
    }
  },
  methods: {
    onFileInput(event) {
      const fileList = event.target.files;

      // Check if a file is selected
      if (fileList.length > 0) {
        const file = fileList.item(0); // Get the first file

        // Convert the file to Base64
        this.convertFileToBase64(file)
            .then((base64Data) => {
              this.trail.image = `data:${file.type};base64,${base64Data}`; // Store Base64 data
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            })
            .finally(() => {

            });
      }
    }
    ,
    getFormattedDateTime(date) {
      if (date === null || date === undefined) return "";
      const formatWithTime = `${this.dateFormat} hh:mm A`;
      return moment.utc(date).local().format(formatWithTime);
    },
    async takeTrail(trail, type) {
      this.trail = JSON.parse(JSON.stringify(trail));
      switch (type) {
        case 'edit':
          this.addTrailDialog = true;
          break;
        case 'delete':
          this.deleteTrailDialog = true;
          break;
      }
    },
    changeLeaveDate() {
      if (this.agreement.leaveDate < this.agreement.startDate) {
        ShowMessage("error", "Leave date cannot be smaller than Start Date");
        this.agreement.leaveDate = new Date();
      }
    },
    changeStartDate() {
      if (this.agreement.endDate !== null && this.agreement.startDate > this.agreement.endDate) {
        ShowMessage("error", "Start date cannot be greater than End Date");
        this.agreement.startDate = new Date();
      }
    },
    changeEndDate() {
      if (this.agreement.endDate !== null && this.agreement.endDate < this.agreement.startDate) {
        ShowMessage("error", "End date cannot be smaller than Start Date");
        this.agreement.endDate = new Date();
      }
    },
    validatePaymentDay() {
      this.paymentDayError = this.agreement.paymentDay > 31;
    },
    editAgreement(agreement, type) {
      this.agreement = JSON.parse(JSON.stringify(agreement));
      switch (type) {
        case 'edit':
          this.addAgreementDialog = true;
          break;
        case 'delete':
          this.deleteAgreementDialog = true;
          break;
      }
    },
    async addAgreement() {
      this.agreement.propertyId = this.property.id;
      this.loading = true;
      try {
        let response = await tenancyAgreementService.addAgreement(this.agreement);
        if (response?.data !== null) {
          ShowMessage("success", "Agreement Added");
          await this.getTenancy();
          await this.getTenants();
        }

      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    }, async updateAgreement() {
      this.loading = true;
      try {
        let response = await tenancyAgreementService.updateAgreement(this.agreement);
        if (response?.data !== null) {
          ShowMessage("success", "Agreement Updated");
          await this.getTenancy();
          await this.getTenantsForAgreement();
        }

      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async deleteAgreement() {
      this.loading = true;
      try {
        let response = await tenancyAgreementService.deleteAgreement(this.agreement.id);
        if (response.status === 200) {
          ShowMessage("success", "Agreement Deleted Successfully");
          await this.getTenancy();
        }

      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response.data.message);
      }
      this.loading = false;
    },
    downloadAttachment(attachment) {
      if (!attachment || !attachment.fileData) {
        console.error("Invalid attachment data");
        return;
      }

      const base64Data = attachment.fileData.split(',')[1];
      const binaryData = atob(base64Data);
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([byteArray], {type: attachment.mimeType});

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = attachment.fileName || "attachment";

      downloadLink.click();

      URL.revokeObjectURL(downloadLink.href);
    },
    onFileInput2(event) {
      const fileList = event.target.files;
      let promises = [];
      if (fileList.length > this.maxFileUploads || this.email.attachments.length > this.maxFileUploads) {
        ShowMessage("error", "Maximum attachments upload limit is " + this.maxFileUploads);
        return;
      }
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        const attachment = {...this.attachment}; // Ensure a new image object for each file
        attachment.fileName = file.name;
        attachment.fileExtension = file.name.split('.').pop();
        attachment.fileSize = file.size;
        attachment.mimeType = file.type;
        if (attachment.fileSize > this.convertMBToBytes(this.fileMaxSize)) {
          ShowMessage("error", attachment.fileName + "'s size is greater than " + this.fileMaxSize + " mb Plz select file between 0 - " + this.fileMaxSize + " mb");

          continue;
        }


        const promise = this.convertFileToBase64(file)
            .then((base64Data) => {
              attachment.fileData = `data:${file.type};base64,${base64Data}`;
              return attachment; // Return the image object after conversion
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            });

        promises.push(promise);
      }

      Promise.all(promises).then((filesList) => {
        // Ensure `attachments` is an array
        if (!Array.isArray(this.email.attachments)) {
          this.email.attachments = [];
        }

        // Concatenate the new images with the existing ones
        this.email.attachments = [...this.email.attachments, ...filesList];
      });
    },
    convertMBToBytes(mb) {
      return mb * 1024 * 1024;
    },

    removeAttachment(objectToRemove) {
      if (!Array.isArray(this.email.attachments)) {
        throw new Error("First argument must be an array");
      }

      const index = this.email.attachments.indexOf(objectToRemove);
      if (index > -1) {
        this.email.attachments.splice(index, 1); // Remove the object from the array
      }
    },
    truncateString(input, maxLength = 20) {
      if (typeof input !== "string") {
        throw new Error("Input must be a string");
      }
      return input.length > maxLength
          ? input.substring(0, maxLength) + "..."
          : input;
    },
    async deleteTrail() {
      this.loading = true;
      try {
        // eslint-disable-next-line no-unused-vars
        let response = await moneyTrailService.softDelete(this.trail.id);
        if (response?.data) {
          ShowMessage("success", "Money trail deleted");
          await this.getTrails();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    pinNote(note) {
      note.pinned = true;
      this.updatePropertyNote(note);
    },
    unPinNote(note) {
      note.pinned = false;
      this.updatePropertyNote(note);
    },
    getTenantName(item) {
      return item.firstName + ' ' + item.surname;
    },
    getDocumentCategory(id) {
      return this.documentCategories.find(x => x.optId === id)?.optTitle ?? "";
    },
    editDocument(document, type) {
      this.document = JSON.parse(JSON.stringify(document));
      switch (type) {
        case 'edit':
          this.addDocumentDialog = true;
          break;
        case 'delete':
          this.deleteDocumentDialog = true;
          break
      }
    },
    editReceipt(receipt, type) {
      this.receipt = JSON.parse(JSON.stringify(receipt));
      switch (type) {
        case 'edit':
          this.addReceiptDialog = true;
          break;
        case 'delete':
          this.deleteReceiptDialog = true;
          break
      }
    },
    editEmail(email, type) {
      this.email = JSON.parse(JSON.stringify(email));
      switch (type) {
        case 'edit':
          this.addEmailDialog = true;
          break;
        case 'delete':
          this.deleteEmailDialog = true;
          break;
        case 'attach':
          this.viewAttachments = true;
          break
      }
    },
    formatFileSize(bytes) {
      bytes = Number(bytes);
      const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

      if (bytes === 0) return '0 Bytes';
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const size = bytes / Math.pow(1024, i);
      return `${size.toFixed(2)} ${units[i]}`;
    },
    getFormattedDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format(this.dateFormat);
    },
    getFormattedCheckedDate(date) {
      if (date === null || date === undefined) return "";
      const formatWithTime = `${this.dateFormat} hh:mm A`;
      return moment.utc(date).local().format(formatWithTime);
    },
    getFormattedTime(date) {
      return moment.utc(date).local().format("LT");
    },
    getEmailFormattedDate(date) {
      return moment(date).format(this.dateFormat + " hh:mm A")
    },
    editNote(note, type) {
      this.note = JSON.parse(JSON.stringify(note));
      switch (type) {
        case 'edit':
          this.addNoteDialog = true;
          break;
        case 'delete':
          this.deleteNoteDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;

    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
    },
    getImageCategoryName(id) {
      if (this.imageCategories.length < 1) {
        return "";
      }
      return this.imageCategories.find(x => x.optId === id).optTitle ?? "";
    },
    getBase64Image(imgData) {
      if (imgData === '' || imgData === null || imgData === undefined) {
        return "";
      }
      if (!imgData.startsWith("data:image/png;base64,")) {
        return "data:image/png;base64," + imgData;
      }
      return imgData;
    },
    removeImage(img) {
      let index = this.property.images.findIndex(function (image) {
        return image === img;
      });
      if (index > -1) {
        this.property.images.splice(index, 1);
      }
    },

    async addProperty() {
      this.loading = true;
      try {
        let validatedMsg = this.validateProperty(this.property);
        if (validatedMsg) {
          ShowMessage("error", validatedMsg);
          this.loading = false;
          return;
        }
        let response = await propertyService.addProperty(this.property);

        if (Number(response.status) === 200) {
          ShowMessage("success", "Property added successfully");
          this.$router.push('pmsproperties')
        }
        this.loading = false;
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response.data.message);
        this.loading = false;
      }
    },
    validateProperty(property) {
      if (property.landlordId < 1) {
        return "Please select the landlord";
      }
      if (!property.propertyName) {
        return "Please enter the property name";
      }
      if (!property.reference) {
        return "Please enter the property reference";
      }
      return "";
    },
    async updateProperty() {
      this.loading = true;
      this.$refs.propertyForm.validate(async (valid) => {
        if (valid) {
          try {

            // eslint-disable-next-line no-unused-vars
            let response = await propertyService.updateProperty(this.property);
            ShowMessage("success", "Property updated successfully");
            this.loading = false;
          } catch (e) {
            console.log(e)
            ShowMessage("error", "Property updating failed");
            this.loading = false;
          }
        } else {
          this.loading = false;
          ShowMessage("error", "Please enter the required fields!")
        }
      });
    },
    getFormattedValue(value) {
      return value.toFixed(2);
    },
    async getTenancy() {
      this.loading = true;
      try {
        let response = await pmsPropertiesService.getPropertyTenancy(this.filter);
        this.agreements = response.data.items;
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getProperty() {
      try {
        this.loading = true;
        let response = await propertyService.getProperty(store.state.pageId);
        this.property = response.data ?? {};
        this.filter.propertyId = this.property.id ?? 0;
        this.edit = !this.edit;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
      this.loading = false;
    },
    async getLandlords() {
      try {
        let response = await contactService.getAllContacts(this.property.clientId, 3);
        this.landords = response?.data?.items ?? []
      }
          // eslint-disable-next-line no-empty
      catch (e) {
      }
    },
    async getListOptions() {
      try {
        this.loader = true;
        let response = await optionService.getOptionsByHeaderId(0, 36)
        this.propertyStatus = response.data ?? {};
        if (this.propertyStatus.length > 0) {
          if (this.property.propertyStatus < 1) {
            this.property.propertyStatus = this.propertyStatus[0].optId;
          }
        }
        let response2 = await optionService.getOptionsByHeaderId(0, 40)
        this.status2 = response2.data ?? {};
        if (this.status2.length > 0) {
          if (this.property.status < 1) {
            this.property.status = this.status2[0].optId;
          }
        }
        let response3 = await optionService.getOptionsByHeaderId(0, 44)
        this.types = response3.data ?? {};
        if (this.types.length > 0) {
          if (this.property.typeOfProperty < 1) {
            this.property.typeOfProperty = this.types[0].optId;
          }
        }
        let response4 = await optionService.getOptionsByHeaderId(0, 42)
        this.imageCategories = response4.data ?? {};
        if (this.imageCategories.length > 0) {
          if (this.image.categoryId < 1) {
            this.image.categoryId = this.imageCategories[0].optId;
          }
        }
        this.loader = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loader = false;
      }
    },
    async getClients() {
      try {
        this.loading = true;
        if (this.clientId == null || Number(this.clientId) < 1) {
          let response = await ClientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            if (this.property.id < 1) {
              this.property.clientId = this.clients[0].id;
              this.filter.clientId = this.clients[0].id;
            }
          }
        } else {
          this.property.clientId = Number(this.clientId);
        }
        await this.getLandlords();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    checkProperty() {
      if (this.property.id < 1) {
        this.$router.push('pmsproperties');
        return;
      }
      this.edit = !this.edit
    },
    getMessageByCode(code) {
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },

    // eslint-disable-next-line no-unused-vars
    onFileChanged(file, fileList) {
      this.fileInputKey = new Date();
      this.fileInputKey = new Date();
      const fileInput = document.getElementById("upload-photo");
      if (fileInput.files && fileInput.files.length) {
        const selectedFile = fileInput.files[0];

        // Extract file properties
        this.image.fileName = selectedFile.name;
        this.image.fileSize = selectedFile.size.toString();
        this.image.fileExt = selectedFile.name.split('.').pop();
        this.image.mimeType = selectedFile.type;
        this.image.lastModifiedAt = new Date(selectedFile.lastModified);
        this.convertFileToBase64(selectedFile)
            .then((base64Data) => {
              this.image.fileData = `data:${selectedFile.type};base64,${base64Data}`;
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            });
      }
    },

    handleFileChange(file) {
      const selectedFile = file.raw;
      this.document.fileName = file.name
      this.document.fileSize = file.size.toString();
      this.document.fileExtension = file.raw.name.split('.').pop();
      this.document.mimeType = selectedFile.type;
      this.returnDocObject(selectedFile, (byteArray) => {
        this.document.fileData = byteArray;
      });
    },
    returnDocObject(file, callback) {
      const reader = new FileReader();
      reader.onload = () => {
        const byteArray = new Uint8Array(reader.result);
        const base64String = btoa(String.fromCharCode(...byteArray));
        callback(base64String);  // Return the base64-encoded string
      };
      reader.readAsArrayBuffer(file);  // Read file as ArrayBuffer
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }

        let response = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response.data.value ?? "DD/MM/YYYY";
        let response2 = await configService.getByKey(id, "FromEmail");
        this.fromEmail = response2.data.value ?? "admin@fdesigns.co.uk";
        let response3 = await configService.getByKey(id, "CurrencySymbol");
        this.currency = response3.data.value ?? "£";
        let response4 = await configService.getByKey(id, "CurrencyPosition");
        this.currencyPosition = response4.data.value ?? "left";
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async getDocumentCategories() {
      try {
        this.loading = true;
        let response = await optionService.getOptionsByKey(this.clientId, "PropertyDocumentCategory");

        this.documentCategories = response.data ?? [];
        if (this.documentCategories.length > 0) {
          if (this.document.categoryId < 1) {
            this.document.categoryId = this.documentCategories[0].optId;
          }
        }

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    downloadDocument(doc) {
      if (!doc || !doc.fileData) {
        console.error("Invalid attachment data");
        return;
      }

      let byteArray;
      if (typeof doc.fileData === "string") {
        // Assuming Base64-encoded string
        const base64Data = doc.fileData.split(',')[1] || doc.fileData; // Handle any prefix
        const binaryData = atob(base64Data);
        byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          byteArray[i] = binaryData.charCodeAt(i);
        }
      } else if (Array.isArray(doc.fileData) || doc.fileData instanceof ArrayBuffer) {
        // Binary data is already an array or buffer
        byteArray = new Uint8Array(doc.fileData);
      } else {
        console.error("Unsupported fileData format");
        return;
      }

      const blob = new Blob([byteArray], {type: doc.documentMimeType});

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = doc.fileName || "attachment";

      downloadLink.click();

      URL.revokeObjectURL(downloadLink.href);
    },
    markChecked(note) {
      note.isChecked = true;
      note.checkedDate = new Date();
      this.updatePropertyNote(note);
    },

    handleTabClick(tabName) {
      let tab = Number(tabName.index);
      switch (tab) {
        case 0:
          this.getProperty();
          break;
        case 1:
          this.getTenantsForAgreement();
          this.getTenancy();
          break;
        case 2:
          this.getContacts();
          this.getMoneyCategories();
          this.getTrails();
          break;
        case 3:
          this.getPropertyNotes();
          break;
          case 4:
          this.getPropertyDocuments();
          break;
        case 5:
          this.getReceipts();
          this.getTenants();
          break;
        case 6:
          this.getContacts();
          this.getEmails();
          this.getEmails();
          break;
      }
    },
    resetDocument() {
      this.document = {
        "id": 0,
        "propertyId": 0,
        "categoryId": this.documentCategories[0].optId,
        "fileName": "",
        "fileExtension": "",
        "fileSize": "",
        "fileData": "",
        "addedDate": new Date(),
        "addedBy": "",
        "mimeType": "",
      }
    },
    resetNote() {
      this.note = {
        "id": 0,
        "propertyId": 0,
        "note": "",
        "addedBy": "",
        "addedDate": new Date(),
        "assignedTo": 0,
        "reminderDate": null,
        "requireCheck": false,
        "isChecked": false,
        "checkedDate": null,
        "pinned": false
      }
    },
    resetReceipt() {
      this.receipt = {
        "id": 0,
        "propertyId": store.state.pageId,
        "templateId": 0,
        "tenantId": this.pmsTenants[0]?.id,
        "amount": 0,
        "receiptDate": new Date().toISOString(),
        "notes": ""
      }
    },
    resetEmail() {
      this.email = {
        "id": 0,
        "emailFrom": "",
        "emailTo": "",
        "copyTo": "",
        "subject": "",
        "body": "Email body goes here",
        "propertyId": null,
        "clientId": null,
        "sentDate": new Date(),
        "status": 0,
        "contactId": null,
        "providerId": null,
        "attachments": [],
        "userId": sessionStorage.getItem("userId"),
      };
      this.propertyDisplay = false;
      this.ccDisplay = false;
    },
    clearImages(type) {
      this.image = {
        propertyId: 0,
        fileData: "",
        fileName: "",
        fileSize: "",
        fileExtension: "",
        mimeType: "",
        sortOrder: 0,
        categoryId: "",
        description: "",
      };
      if (type === 'cancel') {
        this.property.images = [];
      }
    },
    //PMS Property Documents Methods
    async getPropertyDocuments() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.getDocuments(this.filter);
        this.property.documents = response?.data?.items ?? [];

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async addPropertyDocument() {
      try {
        this.document.propertyId = this.property.id;
        this.loading = true;
        let response = await pmsPropertiesService.addDocument(this.document);
        if (response?.data?.id > 0) {
          ShowMessage("success", "Document Addedd");
          await this.getPropertyDocuments();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async updatePropertyDocument() {
      try {
        this.loading = true;
        this.document.propertyId = this.property.id
        let response = await pmsPropertiesService.updateDocument(this.document);
        if (response?.data?.id > 0) {
          ShowMessage("success", "Document Updated");
          await this.getPropertyDocuments();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async deletePropertyDocument() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.deleteDocument(this.document.id);
        if (response?.data) {
          ShowMessage("success", "Document Deleted");
          // await this.getPropertyDocuments();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    //PMS Property Notes Methods
    async getPropertyNotes() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.getNotes(this.filter);
        this.property.notes = response?.data?.items ?? [];
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async addPropertyNote() {
      try {
        this.loading = true;
        this.note.propertyId = this.property.id;
        let response = await pmsPropertiesService.addNote(this.note);
        if (response?.data) {
          ShowMessage("success", "Note Added");
          this.resetNote();
          await this.getPropertyNotes();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async getContacts() {
      try {

        let response = await contactService.getAllContacts(this.filter.clientId, 0);
        this.contacts = response?.data?.items ?? [];
        if (this.contacts.length > 0) {
          this.email.emailTo = this.contacts[0].id;
          this.trail.contactId = this.contacts[0].id;

        } else {
          this.email.emailTo = "";
        }
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    resetAgreement() {
      this.agreement = {
        "id": 0,
        "clientId": this.clients[0]?.id ?? this.clientId,
        "contactId": 0,
        "tenantId": this.pmsTenants[1]?.id ?? "",
        "startDate": new Date(),
        "endDate": null,
        "propertyId": this.property.id,
        "landlordContactId": 0,
        "rent": 0,
        "commission": 0,
        "paymentDay": 1,
        "notes": "",
        "securityDeposit": 0,
        "leaveDate": null,
        "isActive": true,
      };
    },
    async updatePropertyNote(note) {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.updateNote(note);
        if (response?.data) {
          ShowMessage("success", "Note Updated");
          await this.getPropertyNotes();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async deletePropertyNote() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.deleteNote(this.note.id);
        if (response?.data) {
          ShowMessage("success", "Note Deleted");
          await this.getPropertyNotes();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    // Receipts

    async getReceipts() {
      this.loading = true;
      try {
        let response = await receiptService.getReceipts(this.filter);
        this.receipts = response?.data?.items ?? [];
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async addReceipt() {
      this.loading = true;
      try {

        this.receipt.propertyId = this.receipt.propertyId < 1 ? this.property.id : this.receipt.propertyId;
        let response = await receiptService.addReceipt(this.receipt);
        if (response.status === 200) {
          ShowMessage("success", "Receipt added");
          await this.getReceipts();
        }
      } catch (e) {
        ShowMessage("error", "Receipt adding failed, try again later");
      }
      this.loading = false;
    },
    async updateReceipt() {
      this.loading = true;
      try {
        let response = await receiptService.updateReceipt(this.receipt);
        if (response.status === 200) {
          ShowMessage("success", "Receipt updated");
          await this.getReceipts();
        }
      } catch (e) {
        ShowMessage("error", "Receipt updating failed, try again later");
      }
      this.loading = false;
    },
    async deleteReceipt() {
      this.loading = true;
      try {
        let response = await receiptService.deleteReceipt(this.receipt.id);
        if (response.status === 200) {
          ShowMessage("success", "Receipt deleted");
          await this.getReceipts();
        }
      } catch (e) {
        ShowMessage("error", "Receipt deleting failed, try again later");
      }
      this.loading = false;
    },

// Emails

    async getEmails() {
      this.loading = true;
      try {

        let response = await pmsEmailService.getEmails(this.filter);
        this.emails = response?.data?.items ?? [];
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async addEmail() {
      this.loading = true;
      try {
        if (this.email.contactId < 1) {
          ShowMessage("warning", "Please select a recipient first");
          this.loading = false;
          return;
        }
        if (!this.email.subject || !this.email.body) {
          ShowMessage("warning", "Subject and body are mandatory");
          this.loading = false;
          return;
        }
        this.addEmailDialog = false;
        this.email.propertyId = this.property.id;
        this.email.clientId = this.filter.clientId ?? 0;
        this.email.emailFrom = this.fromEmail;
        if (this.email.propertyId < 1) {
          this.email.propertyId = 0;
        }
        let response = await emailService.addEmail(this.email);
        if (response.status === 200) {
          ShowMessage("success", "Email added");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email adding failed, try again later");
      }
      this.loading = false;
    },
    async updateEmail() {
      this.loading = true;
      try {
        let response = await pmsEmailService.updateEmail(this.email);
        if (response.status === 200) {
          ShowMessage("success", "Email updated");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email updating failed, try again later");
      }
      this.loading = false;
    },
    async deleteEmail() {
      this.loading = true;
      try {
        let response = await pmsEmailService.deleteEmail(this.email.id);
        if (response.status === 200) {
          ShowMessage("success", "Email deleted");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email deleting failed, try again later");
      }
      this.loading = false;
    },


    async getTenants() {
      try {
        let response = await contactService.getTenantsAndLandlords(this.property.clientId);
        this.pmsTenants = response?.data?.items ?? [];
        if (this.pmsTenants.length > 0) {
          this.receipt.tenantId = this.pmsTenants[0].id;
        } else {
          this.receipt.tenantId = "";
        }

        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    async getTenantsForAgreement() {
      try {
        let response = await contactService.getAllContacts(this.property.clientId, 1);
        this.tenants = response?.data?.items ?? [];
        if (this.tenants.length > 0) {
          this.agreement.tenantId = this.tenants[0].id;
        } else {
          this.agreement.tenantId = "";
        }

        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    //MoneyTrail


    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result.split(',')[1]); // Get the Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async getMoneyCategories() {
      try {
        this.loading = true;
        let response = await optionService.getOptionsByHeaderId(0, 46)
        this.moneyCategories = response.data ?? {};
        if (this.moneyCategories.length > 0) {
          this.trail.categoryId = this.moneyCategories[0].optId;

        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    },

    async resetTrail() {
      this.trail = {
        "id": 0,
        "contactId": this.contacts[0]?.id ?? "",
        "propertyId": this.property.id,
        "entryDate": new Date(),
        "categoryId": this.moneyCategories[0]?.optId ?? "",
        "description": "",
        "amount": 0,
        "in": true,
        "notes": "",
        "isDeleted": false,
        "image": "",
      };
      await this.getContacts('add');

    },
    async getContactCategories() {
      try {
        let response = await contactService.getAllCategories(this.clientId);

            this.contactCategories = this.contactCategories.concat(response?.data ?? []);
            if (this.contactCategories.length > 1) {
              this.filter.categoryId = this.contactCategories[0].id;
              await this.getContacts();
            }

      } catch (e) {
        console.log(e)
      }
    },
    validateFormMethod(formData) {
      let isValid = true;
      if (formData.contactId < 1) {
        this.validateForm.contactValid = false;
        isValid = false;
      }
      /*if (formData.propertyId < 1) {
        this.validateForm.propertyValid = false;
        isValid = false;
      }*/
      return isValid;
    },

    async getTrailContacts() {
      this.loading = true;
      try {

        // eslint-disable-next-line no-case-declarations
        let response1 = await contactService.getAllContacts(this.dialogFilter.clientId, this.dialogFilter.categoryId);
        this.trailContacts = response1?.data?.items ?? [];
        if (this.trailContacts.length > 0) {
          this.trail.contactId = this.dialogContacts[0].id;
        } else {
          this.trail.contactId = ""
        }
      } catch (e) {
        ShowMessage("error", e?.response?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async getTrails() {
      this.loading = true;
      try {
        let response = await pmsPropertiesService.getPropertyMoneyTrail(this.filter);
        this.trails = response?.data?.items.length > 0 ? response?.data?.items : [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async addTrail() {
      this.loading = true;
      if (!this.validateFormMethod(this.trail)) {
        this.loading = false;
        return;
      }
      // eslint-disable-next-line no-unreachable
      try {
        if (this.trail.propertyId < 1) {
          this.trail.propertyId = 0
        }
        this.trail.clientId = this.dialogFilter.clientId;
        // eslint-disable-next-line no-unreachable
        let res = await moneyTrailService.addTrail(this.trail);
        if (res.status === 200) {
          ShowMessage("success", "Money Trail added");
          await this.getTrails();
        }
      } catch (e) {
        ShowMessage("error", e?.msg ?? "Money trail adding failed");
      }
      this.addTrailDialog = false;
      this.loading = false;
    },
    async updateTrail() {
      this.loading = true;
      if (!this.validateFormMethod(this.trail)) {
        this.loading = false;
        return;
      }
      // eslint-disable-next-line no-unreachable
      try {
        if (this.trail.propertyId < 1) {
          this.trail.propertyId = 0
        }
        let res = await moneyTrailService.updateTrail(this.trail);
        if (res.status === 200) {
          ShowMessage("success", "Money Trail update");
          await this.getTrails();
        }
      } catch (e) {
        ShowMessage("error", e?.msg ?? "Money trail adding failed");
      }
      this.addDialog = false;
      this.loading = false;
    },
  },
  created() {
    const encodedId = this.$route.query.id;
    if (encodedId) {

      const decodedId = atob(encodedId);
      this.$store.state.pageId = decodedId;
      this.getProperty(decodedId)
    }
    this.translations = getLanguage();
    this.getClients();
    this.getListOptions();
    this.getConfigs();
    this.getDocumentCategories();
  }
}
</script>
<style scoped>
.fileUpload {
  position: absolute !important;
  top: 50% !important;
  left: 50%;
}

.ck-editor__editable_inline {
  min-height: 500px; /* Set the minimum height to 500px */
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: 200px !important;
}
</style>