<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row py-2 align-items-center">
        <div class="col-12 col-md-8">
          <BreadCrumb :view="edit" :id="page.id" :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="staticpages">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
            </el-button>
          </router-link>
          <el-button v-else @click="checkPage()" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
          </el-button>
          <el-button v-if="edit" text bg class="border-0 mbtn-primary ms-2" @click="edit = !edit">
            <span class="text-capitalize">{{ getMessageByCode('edit') ?? 'Edit' }}</span>
          </el-button>
          <el-button :disabled="!page.pageCode || !page.pageContent" v-if="page.id < 1 && pageCreate" @click="addPage()"
                     class="border-0 mbtn-primary ms-2"><span
              class="text-capitalize">{{ getMessageByCode('addPage') ?? 'Add Page' }}</span>
          </el-button>
          <el-button v-if="page.id > 0 && pageEdit && !edit" :disabled="edit" @click="updatePage()"
                     class="border-0 mbtn-primary ms-2 text-capitalize">
            {{ getMessageByCode('updatePage') ?? 'Update Page' }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <el-form label-position="top" status-icon require-asterisk-position="right">
          <el-form-item v-if="clientId === null || clientId < 1">
            <template #label>
              <span class="required">{{ getMessageByCode('client') ?? 'Client' }}</span>
            </template>
            <el-select v-model="page.clientId" filterable class="w-100"
                       placeholder="Select"
            >
              <el-option
                  v-for="item in clients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item required :label="getMessageByCode('target') ?? 'Target'">
            <el-select v-model="page.target">
              <el-option v-for="item in pageCategories" :key="item.value" :label="item.text"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item required :label="getMessageByCode('pageCode') ?? 'Page Code'">
            <el-input @keydown.space.prevent v-model="page.pageCode"></el-input>
          </el-form-item>
          <el-form-item :label="getMessageByCode('pageTitle') ?? 'Page Title'">
            <el-input v-model="page.pageTitle"></el-input>
          </el-form-item>
          <div class="d-flex justify-content-between">
            <el-form-item :label="getMessageByCode('isActive') ?? 'Is Active'">
              <el-switch v-model="page.isActive"></el-switch>
            </el-form-item>
            <el-form-item :label="getMessageByCode('sortOrder') ?? 'Sort Order'">
              <el-input type="number" v-model="page.sortOrder"></el-input>
            </el-form-item>
          </div>

        </el-form>
      </div>
      <div class="col-12 col-md-8">
        <el-form label-position="top" status-icon require-asterisk-position="right">
          <el-form-item :label="getMessageByCode('pageContent') ?? 'Page Content' + ' (HTML)'">
            <el-input :rows="20" type="textarea" v-model="page.pageContent"></el-input>

          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {getMessageByCode} from "@/Utility/getMessagebyCode";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {checkPermission} from "@/Utility/CheckPermission";
import staticPagesService from "@/Services/ComponentsServices/staticPagesService";
import {ShowMessage} from "@/Utility/Utility";
import ClientService from "@/Services/ComponentsServices/clientService";

export default {
  name: "StaticPage",

  components: {BreadCrumb,},
  data() {
    return {
      loading: true,
      edit: false,
      pageView: false,
      pageCreate: false,
      pageEdit: false,
      pageDelete: false,
      clientId: sessionStorage.getItem("clientId") ?? 0,
      pageCategories: [
        {text: "WEB", value: "WEB"},
        {text: "APP", value: "APP"},
      ],
      clients: [
        {id: 0, name: "Admin"},
      ],
      page: {
        "id": 0,
        "target": "WEB",
        "pageCode": "",
        "pageTitle": "",
        "pageContent": "",
        "isActive": true,
        "sortOrder": 0,
        "clientId": sessionStorage.getItem("clientId") ?? 0,
      }
    }
  },
  methods: {
    getMessageByCode,
    checkPage() {
      if (this.page.id < 1) {
        this.$router.push('staticpages');
        return;
      }
      this.edit = !this.edit
    },
    async addPage() {
      this.loading = true;
      try {
        let response = await staticPagesService.addPage(this.page);
        if (response.status === 200) {
          ShowMessage("success", "Page Added");
          this.page = response?.data;
        }
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Adding page failed");

      }
    },
    async updatePage() {
      this.loading = true;
      try {
        let response = await staticPagesService.updatePage(this.page);
        if (response.status === 200) {
          ShowMessage("success", "Page updated");
          this.page = response?.data;
        }
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Updating page failed");

      }
    },
    async getPage(id) {
      this.loading = true;
      try {
        let response = await staticPagesService.getPage(id);
        this.page = response?.data ?? {};
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Fetching page details failed");

      }
    },
    async getClients() {
      try {
        this.loading = true;
        if (this.clientId == null || this.clientId < 1) {
          let response = await ClientService.getAll();
          this.clients = this.clients.concat(response.data.items ?? []);
          if (this.clients.length > 0) {
            if (this.page.id < 1) {
              this.page.clientId = this.clients[0].id;

            }
          }

        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    }
  },
  created() {

    this.pageView = checkPermission("STATIC_PAGE_VIEW");
    this.pageCreate = checkPermission("STATIC_PAGE_CREATE");
    this.pageEdit = checkPermission("STATIC_PAGE_EDIT");
    this.pageDelete = checkPermission("STATIC_PAGE_DEL");
    this.getClients();
    const encodedId = this.$route.query.id;
    if (encodedId) {

      const decodedId = atob(encodedId);
      this.$store.state.pageId = decodedId;
      this.edit = false;
      this.getPage(decodedId)
    }
  }
}
</script>

<style scoped>

</style>