<template>
  <el-scrollbar class="custom-scrollable">
    <el-menu
        :collapse="collapsed"
        :router="true"
        background-color="#242d37"
        class="el-menu-vertical-demo d-flex flex-column justify-content-between h-100"
        text-color="#fff"
    >
      <div>
        <el-menu-item
            class="d-flex justify-content-center align-items-center"
            style="height: 60px"
        >
          <!--          <span class="mt-2" v-if="!$store.state.collapsed">LIGHT CRM</span>
                    <span class="mt-2" v-else>CRM</span>-->
          <img alt="logo" v-if="!collapsed" :src="logo" width="100"/>
          <img alt="logo" v-else :src="icon" width="25"/>
        </el-menu-item>
        <el-divider border-style="dashed" class="mt-0"></el-divider>
        <el-menu-item
            v-if="market.toLowerCase() === ''"
            :class="{ 'active-menu-item': $route.path === '/dashboard' }"
            index="1"
            route="dashboard"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-grid"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("dashboard") ?? "Dashboard"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="market.toLowerCase() === 'housing management'"
            :class="{ 'active-menu-item': $route.path === '/housing' }"
            index="2"
            route="housing"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-grid"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("dashboard") ?? "Dashboard"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="market.toLowerCase() === 'accountancy'"
            :class="{ 'active-menu-item': $route.path === '/accountancy' }"
            index="65"
            route="accountancy"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-grid"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("dashboard") ?? "Dashboard"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="market.toLowerCase() === 'property management'"
            :class="{ 'active-menu-item': $route.path === '/pms' }"
            index="2"
            route="pms"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-grid"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("dashboard") ?? "Dashboard"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="market === 'Accountancy'"
            :class="{ 'active-menu-item': $route.path === '/customers' }"
            index="4"
            route="customers"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-people"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("customers") ?? "Customers"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="market === 'Accountancy'"
            :class="{ 'active-menu-item': $route.path === '/app-users' }"
            index="51"
            route="app-users"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-phone"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("appUsers") ?? "App Users"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="clientId <= 1 && clientView"
            :class="{ 'active-menu-item': $route.path === '/clients' }"
            index="3"
            route="clients"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-check"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("clients") ?? "Clients"
            }}
          </template>
        </el-menu-item>

        <el-menu-item
            v-if="pmsEmailsView"
            :class="{ 'active-menu-item': $route.path === '/emails' }"
            index="9"
            route="emails"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-envelope-arrow-up"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("emails") ?? "Emails"
            }}
          </template>
        </el-menu-item>

        <el-menu-item
            v-if="contactView"
            :class="{ 'active-menu-item': $route.path === '/contacts' }"
            index="5"
            route="contacts"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-people"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("contacts") ?? "Contacts"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="docsView"
            :class="{ 'active-menu-item': $route.path === '/documents' }"
            index="20"
            route="documents"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-folder"></i>
          </el-icon>
          <template #title
          ><span class="text-capitalize">{{
              getMessageByCode("documents") ?? "Documents"
            }}</span>
          </template>
        </el-menu-item>

        <el-sub-menu index="51" v-if="clientId < 1">
          <template #title>
            <el-icon>
              <i class="bi bi-card-checklist"></i>
            </el-icon>
            <span
                v-if="!$store.state.sidebarCollapsed"
                class="text-capitalize"
            >{{
                getMessageByCode("acc_market") ?? "Accountancy Market"
              }}</span
            >
          </template>
<!--          <el-menu-item-->
<!--              v-if="clientId <= 1 && clientView"-->
<!--              :class="{ 'active-menu-item': $route.path === '/clientcontacts' }"-->
<!--              index="33"-->
<!--              route="clientcontacts"-->
<!--              @click="$emit('closeSidebar')"-->
<!--          >-->
<!--            <el-icon>-->
<!--              <i class="bi bi-person-exclamation"></i>-->
<!--            </el-icon>-->
<!--            <template #title>{{-->
<!--                getMessageByCode("clientContacts") ?? "Client Contacts"-->
<!--              }}-->
<!--            </template>-->
<!--          </el-menu-item>-->
          <el-menu-item
              v-if="clientId <1 || market === 'Accountancy'"
              :class="{ 'active-menu-item': $route.path === '/customers' }"
              index="4"
              route="customers"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-people"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("customers") ?? "Customers"
              }}
            </template>
          </el-menu-item>
          <el-menu-item
              v-if="clientId <= 1 && clientView"
              :class="{ 'active-menu-item': $route.path === '/app-users' }"
              index="100"
              route="app-users"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-phone"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("appUsers") ?? "App Users"
              }}
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="38" v-if="clientId < 1">
          <template #title>
            <el-icon>
              <i class="bi bi-building"></i>
            </el-icon>
            <span
                v-if="!$store.state.sidebarCollapsed"
                class="text-capitalize"
            >{{ getMessageByCode("housing") ?? "Housing Market" }}</span
            >
          </template>
          <el-menu-item
              v-if="providerView"
              :class="{ 'active-menu-item': $route.path === '/providers' }"
              index="6"
              route="providers"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-person-check"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("providers") ?? "Providers"
              }}
            </template>
          </el-menu-item>
          <el-menu-item
              v-if="propertyView"
              :class="{ 'active-menu-item': $route.path === '/properties' }"
              index="7"
              route="properties"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-building-check"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("properties") ?? "Properties"
              }}
            </template>
          </el-menu-item>
          <el-menu-item
              v-if="tenantView"
              :class="{ 'active-menu-item': $route.path === '/tenants' }"
              index="12"
              route="tenants"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-person-add"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("tenants") ?? "Tenants"
              }}
            </template>
          </el-menu-item>
          <el-menu-item
              v-if="roomView && clientId > 0"
              :class="{ 'active-menu-item': $route.path === '/empty_rooms' }"
              class="mt-auto"
              index="11"
              route="empty_rooms"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-gear"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("rooms") ?? "Rooms"
              }}
            </template>
          </el-menu-item>
          <el-sub-menu
              index="14"
              v-if="
              (reportView &&
                market.toLowerCase() === 'Housing Management'.toLowerCase()) ||
              clientId < 1
            "
          >
            <template #title>
              <el-icon>
                <i class="bi bi-printer"></i>
              </el-icon>
              <span v-if="!$store.state.sidebarCollapsed">{{
                  getMessageByCode("reports") ?? "Reports"
                }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                  v-if="docExpView"
                  :class="{
                  'active-menu-item': $route.path === '/document_expiry',
                }"
                  class="mt-auto"
                  index="15"
                  route="document_expiry"
                  @click="$emit('closeSidebar')"
              >
                <el-icon>
                  <i class="bi bi-file-earmark-x"></i>
                </el-icon>
                <template #title>{{
                    getMessageByCode("doc_exp") ?? "Documents Expiry"
                  }}
                </template>
              </el-menu-item>
              <el-menu-item
                  v-if="missDocView"
                  :class="{
                  'active-menu-item': $route.path === '/missing_documents',
                }"
                  class="mt-auto"
                  index="16"
                  route="missing_documents"
                  @click="$emit('closeSidebar')"
              >
                <el-icon>
                  <i class="bi bi-file-earmark"></i>
                </el-icon>
                <template #title>{{
                    getMessageByCode("miss_documents") ?? "Missing Documents"
                  }}
                </template>
              </el-menu-item>
              <el-menu-item
                  v-if="tenantReportView"
                  :class="{
                  'active-menu-item': $route.path === '/tenants_report',
                }"
                  class="mt-auto"
                  index="17"
                  route="tenants_report"
                  @click="$emit('closeSidebar')"
              >
                <el-icon>
                  <i class="bi bi-people"></i>
                </el-icon>
                <template #title>{{
                    getMessageByCode("tenants") ?? "Tenants"
                  }}
                </template>
              </el-menu-item>
              <el-menu-item
                  v-if="roomReportView"
                  :class="{ 'active-menu-item': $route.path === '/empty-rooms' }"
                  class="mt-auto"
                  index="18"
                  route="empty-rooms"
                  @click="$emit('closeSidebar')"
              >
                <el-icon>
                  <i class="bi bi-house-lock"></i>
                </el-icon>
                <template #title>{{
                    getMessageByCode("empt_rooms") ?? "Empty Rooms"
                  }}
                </template>
              </el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
          <el-menu-item
              v-if="clientId <= 1 && complianceView"
              :class="{ 'active-menu-item': $route.path === '/compliances' }"
              index="19"
              route="compliances"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-file-earmark-medical"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("compliances") ?? "Compliances"
              }}
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="39" v-if="clientId < 1">
          <template #title>
            <el-icon>
              <i class="bi bi-building"></i>
            </el-icon>
            <span
                v-if="!$store.state.sidebarCollapsed"
                class="text-capitalize"
            >{{ getMessageByCode("pms") ?? "Property Management" }}</span
            >
          </template>


          <el-menu-item
              v-if="pmsPropertyView"
              :class="{ 'active-menu-item': $route.path === '/pmsproperties' }"
              index="8"
              route="pmsproperties"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-house-check"></i>
            </el-icon>

            <template #title>{{
                getMessageByCode("properties") ?? "Properties"
              }}
            </template>
          </el-menu-item>
          <el-menu-item
              v-if="tenantView"
              :class="{ 'active-menu-item': $route.path === '/pmstenants' }"
              index="48"
              route="pmstenants"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-person-add"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("tenants") ?? "Tenants"
              }}
            </template>
          </el-menu-item>
          <el-menu-item
              v-if="pmsRentView"
              :class="{ 'active-menu-item': $route.path === '/rent' }"
              index="34"
              route="rent"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-currency-pound"></i>
            </el-icon>
            <template #title>{{ getMessageByCode("rent") ?? "Rent" }}</template>
          </el-menu-item>
          <el-menu-item
              v-if="moneyTrailView"
              :class="{ 'active-menu-item': $route.path === '/moneytrails' }"
              index="10"
              route="moneytrails"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-currency-pound"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("moneytrails") ?? "Money Trail"
              }}
            </template>
          </el-menu-item>
          <el-menu-item
              v-if="tenancyAgreementView"
              :class="{ 'active-menu-item': $route.path === '/agreements' }"
              index="13"
              route="agreements"
              @click="$emit('closeSidebar')"
          >
            <el-icon>
              <i class="bi bi-card-checklist"></i>
            </el-icon>
            <template #title>{{
                getMessageByCode("agree") ?? "Tenancy Agreements"
              }}
            </template>
          </el-menu-item>
        </el-sub-menu>

        <el-menu-item
            v-if="providerView && clientId > 1"
            :class="{ 'active-menu-item': $route.path === '/providers' }"
            index="6"
            route="providers"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-check"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("providers") ?? "Providers"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="propertyView && clientId > 1"
            :class="{ 'active-menu-item': $route.path === '/properties' }"
            index="7"
            route="properties"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-building-check"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("properties") ?? "Properties"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="pmsPropertyView && clientId > 0"
            :class="{ 'active-menu-item': $route.path === '/pmsproperties' }"
            index="8"
            route="pmsproperties"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-house-check"></i>
          </el-icon>

          <template #title>{{
              getMessageByCode("properties") ?? "Properties"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="pmsTenantsView && clientId > 0"
            :class="{ 'active-menu-item': $route.path === '/pmstenants' }"
            index="35"
            route="pmstenants"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-add"></i>
          </el-icon>

          <template #title>{{
              getMessageByCode("tenants") ?? "Tenants"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="pmsRentView && clientId > 0"
            :class="{ 'active-menu-item': $route.path === '/rent' }"
            index="34"
            route="rent"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-currency-pound"></i>
          </el-icon>
          <template #title>{{ getMessageByCode("rent") ?? "Rent" }}</template>
        </el-menu-item>

        <el-menu-item
            v-if="moneyTrailView && clientId > 0"
            :class="{ 'active-menu-item': $route.path === '/moneytrails' }"
            index="10"
            route="moneytrails"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-currency-pound"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("moneytrails") ?? "Money Trail"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="roomView && clientId > 0"
            :class="{ 'active-menu-item': $route.path === '/empty_rooms' }"
            class="mt-auto"
            index="11"
            route="empty_rooms"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-gear"></i>
          </el-icon>
          <template #title>{{ getMessageByCode("rooms") ?? "Rooms" }}</template>
        </el-menu-item>
        <el-menu-item
            v-if="tenantView && clientId > 0"
            :class="{ 'active-menu-item': $route.path === '/tenants' }"
            index="12"
            route="tenants"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-add"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("tenants") ?? "Tenants"
            }}
          </template>
        </el-menu-item>
        <el-menu-item
            v-if="tenancyAgreementView && clientId > 0"
            :class="{ 'active-menu-item': $route.path === '/agreements' }"
            index="13"
            route="agreements"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-card-checklist"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("agree") ?? "Tenancy Agreements"
            }}
          </template>
        </el-menu-item>

        <el-menu-item
            v-if="profileEdit || profileView"
            :class="{ 'active-menu-item': $route.path === '/profile' }"
            index="21"
            route="profile"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-gear"></i>
          </el-icon>
          <template #title>{{
              getMessageByCode("my_prof") ?? "My Profile"
            }}
          </template>
        </el-menu-item>

        <el-sub-menu
            index="22"
            v-if="optionView || userView || roleView || templateView"
        >
          <template #title>
            <el-icon>
              <i class="bi bi-sliders"></i>
            </el-icon>
            <span
                v-if="!$store.state.sidebarCollapsed"
                class="text-capitalize"
            >{{ getMessageByCode("more") ?? "More" }}</span
            >
          </template>
          <el-menu-item-group>
            <el-menu-item
                v-if="optionView"
                :class="{ 'active-menu-item': $route.path === '/options' }"
                class="mt-auto"
                index="23"
                route="options"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-sliders"></i>
              </el-icon>
              <template #title>{{
                  getMessageByCode("optionsList") ?? "Options List"
                }}
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="userView"
                :class="{ 'active-menu-item': $route.path === '/users' }"
                class="mt-auto"
                index="24"
                route="users"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-people"></i>
              </el-icon>
              <template #title>{{
                  getMessageByCode("users") ?? "Users"
                }}
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="roleView"
                :class="{ 'active-menu-item': $route.path === '/roles' }"
                class="mt-auto"
                index="25"
                route="roles"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-people"></i>
              </el-icon>
              <template #title
              ><span class="text-capitalize">{{
                  getMessageByCode("roles_list") ?? "Roles List"
                }}</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="templateView"
                :class="{ 'active-menu-item': $route.path === '/templates' }"
                class="mt-auto"
                index="26"
                route="templates"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-envelope"></i>
              </el-icon>
              <template #title
              ><span class="text-capitalize">{{
                  getMessageByCode("templates") ?? "Templates"
                }}</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="staticPageView && clientId < 1"
                :class="{ 'active-menu-item': $route.path === '/staticpages' }"
                class="mt-auto"
                index="27"
                route="staticpages"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-filetype-html"></i>
              </el-icon>
              <template #title
              ><span class="text-capitalize">{{
                  getMessageByCode("staticPages") ?? "static Pages"
                }}</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="faqsView"
                :class="{ 'active-menu-item': $route.path === '/faqs' }"
                class="mt-auto"
                index="28"
                route="faqs"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-question-circle"></i>
              </el-icon>
              <template #title
              ><span class="text-capitalize">{{
                  getMessageByCode("faqs") ?? "Faqs"
                }}</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="clientId < 1"
                :class="{ 'active-menu-item': $route.path === '/loginimages' }"
                class="mt-auto"
                index="35"
                route="loginimages"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-image"></i>
              </el-icon>
              <template #title
              ><span class="text-capitalize">{{
                  getMessageByCode("loginimages") ?? "Login Images"
                }}</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="clientId < 1"
                :class="{ 'active-menu-item': $route.path === '/loginquotes' }"
                class="mt-auto"
                index="36"
                route="loginquotes"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-quote"></i>
              </el-icon>
              <template #title
              ><span class="text-capitalize">{{
                  getMessageByCode("loginquotes") ?? "Login Quotes"
                }}</span>
              </template>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="29" v-if="clientId == null || Number(clientId) < 1">
          <template #title>
            <el-icon>
              <i class="bi bi-currency-exchange"></i>
            </el-icon>
            <span v-if="!$store.state.sidebarCollapsed"
            ><span class="text-capitalize">{{
                getMessageByCode("mar_pack") ?? "Markets & Packages"
              }}</span></span
            >
          </template>
          <el-menu-item-group>
            <el-menu-item
                v-if="clientId <= 1"
                :class="{ 'active-menu-item': $route.path === '/markets' }"
                class="mt-auto"
                index="30"
                route="markets"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-shop-window"></i>
              </el-icon>
              <template #title
              ><span class="text-capitalize">{{
                  getMessageByCode("markets") ?? "Markets"
                }}</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="clientId <= 1"
                :class="{ 'active-menu-item': $route.path === '/packages' }"
                class="mt-auto"
                index="31"
                route="packages"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-boxes"></i>
              </el-icon>
              <template #title
              ><span class="text-capitalize">{{
                  getMessageByCode("packages") ?? "Packages"
                }}</span>
              </template>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
      </div>
      <el-menu-item
          v-if="clientId < 1"
          :class="{ 'active-menu-item': $route.path === '/settings' }"
          class="mt-auto"
          index="32"
          route="settings"
          @click="$emit('closeSidebar')"
      >
        <el-icon>
          <i class="bi bi-gear"></i>
        </el-icon>
        <template #title
        ><span class="text-capitalize">{{
            getMessageByCode("settings") ?? "Settings"
          }}</span>
        </template>
      </el-menu-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import configService from "@/Services/ComponentsServices/configService";
import {mapState} from "vuex";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "SideBar",
  data() {
    return {
      clientId: sessionStorage.getItem("clientId"),
      translations: [],
      logo: "",
      icon: "",
      collapsed: false,
      providerView: false,
      propertyView: false,
      reportView: false,
      clientView: false,
      roomView: false,
      tenantView: false,
      complianceView: false,
      staticPageView: false,
      faqsView: false,
      customerView: false,
      contactView: false,
      userView: false,
      roleView: false,
      templateView: false,
      docExpView: false,
      missDocView: false,
      roomReportView: false,
      tenantReportView: false,
      optionView: false,
      docsView: false,
      profileView: false,
      profileEdit: false,
      pmsPropertyView: false,
      pmsTenantsView: false,
      pmsRentView: false,
      pmsEmailsView: false,
      moneyTrailView: false,
      tenancyAgreementView: false,
      loginImagesView: false,
      market: sessionStorage.getItem("market") ?? "",
    };
  },
  computed: {
    ...mapState(["sidebarCollapsed"]), // Map the state to a local computed property
  },
  watch: {
    sidebarCollapsed(newVal) {
      if (newVal) {
        this.collapsed = true;
      } else {
        this.collapsed = false;
      }
    },
  },
  methods: {
    async getTitle(clientId) {
      try {
        const logoNormal = await configService.getByKey(
            clientId,
            "BrandLogoNormal"
        );
        const icon = await configService.getByKey(clientId, "BrandIcon");
        if (logoNormal.data.value != undefined) {
          // Replace with your actual API endpoint
          this.logo = logoNormal.data.value;
        }
        if (icon.data.value != undefined) {
          // Replace with your actual API endpoint
          this.icon = icon.data.value;
        }
      } catch (error) {
        console.error("Error fetching app data", error);
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find((obj) => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
  },
  created() {
    this.translations = getLanguage();
    this.providerView = checkPermission("MD_PRO_VIEW");
    this.propertyView = checkPermission("MD_PROP_VIEW");
    this.reportView = checkPermission("MD_REPORT_VIEW");
    this.clientView = checkPermission("MD_CLIENT_VIEW");
    this.roomView = checkPermission("MD_ROOM_VIEW");
    this.tenantView = checkPermission("MD_TEN_VIEW");
    this.complianceView = checkPermission("MD_COMP_VIEW");
    this.userView = checkPermission("MD_USER_VIEW");
    this.roleView = checkPermission("MD_ROLE_VIEW");
    this.tenantView = checkPermission("MD_TEN_VIEW");
    this.missDocView = checkPermission("MD_DOC_MISS_VIEW");
    this.docExpView = checkPermission("MD_DOC_EXP_VIEW");
    this.tenantReportView = checkPermission("MD_TEN_REPORT_VIEW");
    this.roomReportView = checkPermission("MD_ROOM_REP_VIEW");
    this.templateView = checkPermission("MD_TEMPLATE_VIEW");
    this.optionView = checkPermission("MD_LIST_VIEW");
    this.docsView = checkPermission("MD_DOCUMENT_VIEW");
    this.profileView = checkPermission("MD_USER_PROFILE_VIEW");
    this.profileEdit = checkPermission("MD_USER_PROFILE_EDIT");
    this.staticPageView = checkPermission("STATIC_PAGE_VIEW");
    this.customerView = checkPermission("CUST_VIEW");
    this.contactView = checkPermission("CONTACTS_VIEW");
    this.pmsPropertyView = checkPermission("MD_PMSPROP_VIEW");
    this.pmsRentView = checkPermission("PMS_RENT_VIEW");
    this.pmsTenantsView = checkPermission("PMS_TENANT_VIEW");
    this.pmsEmailsView = checkPermission("PMSEMAIL_VIEW");
    this.moneyTrailView = checkPermission("MD_MONEYTRAIL_VIEW");
    this.tenancyAgreementView = checkPermission("MD_TENAGR_VIEW");
    this.faqsView = checkPermission("FAQS_VIEW");
    this.loginImagesView = checkPermission("FAQS_VIEW");
    this.getTitle(sessionStorage.getItem("clientId") ?? 0);
  },
};
</script>

<style scoped>
.custom-scrollable {
  height: 100vh !important;
}

.active-menu-item {
  background-color: #171b21 !important;
  padding: 20px;
  color: #00b8ff !important;
}

.el-menu-item {
  height: 46px !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 100vh !important;
}

.el-scrollbar__view {
  height: 100% !important;
}

.el-menu-item {
  font-size: 13.5px;
}

.el-sub-menu__title * {
  font-size: 13.5px;
}

.el-menu-item i {
  font-size: 15px;
}

.el-sub-menu .el-icon {
  font-size: 15px;
  margin-right: 5px;
  text-align: center;
  vertical-align: middle;
  width: var(--el-menu-icon-width);
}

.el-menu-item.is-active {
  color: #0b91d2 !important;
}
</style>
