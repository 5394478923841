<template>
  <div class="container-fluid p-2 p-md-0 main-wrapper" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
    <div class="row main-wrapper justify-content-center mx-5 mx-lg-0 align-items-center">
      <div class="col-12 col-lg-5 bg-white shadow rounded py-5 px-md-5">
        <div class="text-center">
          <el-steps :active="activeStep" align-center finish-status="success">
            <el-step>
              <template #title>
                <span class="fw-medium">Account</span>
              </template>
            </el-step>
            <el-step>
              <template #title>
                <span class="fw-medium">Company</span>
              </template>
            </el-step>
          </el-steps>
          <div v-if="activeStep === 0" class="mt-2">
            <div class="df-flex justify-content-center mt- 2">
              <h3 class="fw-bold primary-color">Let's get started</h3>
              <span class="reg-desc">First you will need to create an account.</span>
            </div>
            <div class="row justify-content-center mt-3">
              <div class="col-10 col-md-10 text-start">
                <el-input disabled class="w-100" placeholder="Email" size="large" v-model="Client.userEmail"></el-input>
              </div>
              <div class="col-10 col-md-10 text-start mt-3">
                <el-input class="w-100" placeholder="Name" size="large" v-model="Client.name"></el-input>
              </div>
              <div class="col-10 col-md-10 text-start mt-3">
                <el-select size="large" v-model="Client.language" filterable
                           placeholder="Select Language"
                           class="w-100">
                  <el-option
                      v-for="item in languages"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                      style="font-weight: normal; color: #0a0a0a"
                  />
                </el-select>
              </div>


              <div class="col-10 col-md-10 text-start mt-3 d-flex">
                <el-select size="large" class="me-2 w-25" v-model="countryCode" filterable>
                  <el-option v-for="(country,i) in countryCodes" :key="i" :value="country.dial_code">
                    <span>{{ country.code }} {{ country.dial_code }}</span>
                  </el-option>
                </el-select>
                <el-input class="w-100" placeholder="Phone" size="large" v-model="Client.mobile">
                  <!--             <template #prepend>
                                 <el-select>
                                   <el-option v-for="(country,i) in countryCodes" :key="i">
                                     <span>{{country.code}} {{country.dial_code}}</span>
                                   </el-option>
                                 </el-select>
                               </template>-->
                </el-input>
              </div>
              <div class="col-10 col-md-10 text-start mt-3">
                <el-input class="w-100" placeholder="Password" type="password" show-password size="large"
                          @input="containsSpecialCharacters(Client.password)" v-model="Client.password"></el-input>
                <div class="pswd-indicator">
                  <span>Check your password strength</span>
                  <el-popover
                      placement="top-start"
                      :width="230"
                      trigger="hover"
                      content="Show password"
                  >
                    <template #reference>
                      <i class="bi bi-info-circle ms-2" style="cursor: pointer"></i>
                    </template>
                    <template #default>
                      <div class="pswd-indicator">
                        <div>
                          <i v-if="Client.password.length < 8" class="bi bi-x-circle-fill me-2 text-danger"></i>
                          <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                          <span :class="Client.password.length < 8 ? 'text-secondary' : 'text-success'">At least five characters</span>
                        </div>
                        <div>
                          <i v-if="!specialCh" class="bi bi-x-circle-fill me-2 text-danger"></i>
                          <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                          <span
                              :class="!specialCh ? 'text-secondary' : 'text-success'">At least one special character</span>
                        </div>
                        <div>
                          <i v-if="!hasCapitalChar" class="bi bi-x-circle-fill me-2 text-danger"></i>
                          <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                          <span :class="!hasCapitalChar ? 'text-secondary' : 'text-success'">At least one capital character</span>
                        </div>
                        <div>
                          <i v-if="!hasNumber" class="bi bi-x-circle-fill me-2 text-danger"></i>
                          <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                          <span :class="!hasNumber ? 'text-secondary' : 'text-success'">At least One number</span>
                        </div>
                      </div>
                    </template>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeStep >= 1" class="mt-3">
            <div class="df-flex justify-content-center mt-2">
              <h3 class="fw-bold primary-color my-1">About your Company</h3>
              <span class="reg-desc">We'll use this info to personalize your experience.</span>
            </div>
            <div class="row justify-content-center mt-3">
              <div class="col-10 col-md-10 text-start">
                <el-input class="w-100" placeholder="Company Name" size="large" v-model="Client.companyName"></el-input>
              </div>
              <div class="col-10 col-md-10 text-start mt-3">
                <el-input class="w-100" placeholder="Address" size="large" v-model="Client.address1"></el-input>
              </div>
              <div class="col-10 col-md-10 text-start mt-3">
                <el-input class="w-100" placeholder="City" size="large" v-model="Client.city"></el-input>
              </div>
              <div class="col-10 col-md-10 text-start mt-3">
                <el-input class="w-100" placeholder="Postcode" size="large" v-model="Client.postCode"></el-input>
              </div>
              <div class="col-10 col-md-10 text-start mt-3">
                <el-select placeholder="Company Industry" size="large" class="w-100" v-model="Client.companyIndustry">
                  <el-option v-for="item in companiesList" :key="item.value" :value="item.value">
                    {{ item.text }}
                  </el-option>
                </el-select>
              </div>
              <div class="col-10 col-md-10 text-start mt-3">
                <el-select placeholder="How big is your company" size="large" class="w-100"
                           v-model="Client.companySize">
                  <el-option v-for="item in noOfEmployees" :key="item.value" :value="item.value">
                    {{ item.text }}
                  </el-option>
                </el-select>
              </div>

            </div>

          </div>
          <div class="row justify-content-center">
            <div class="col-10 mt-3">
              <div class="d-flex flex-column flex-md-row">
                <el-button text bg v-if="activeStep > 0" @click="activeStep -- " size="large" type="primary"
                           class="w-100  ms-0 me-md-2">
                  Back
                </el-button>
                <el-button v-if="activeStep === 1" size="large" type="primary" class="w-100 ms-0 mt-2 mt-md-0"
                           @click="register()">Register
                </el-button>
                <el-button v-else :disabled="!passwordOk()" @click="activeStep ++ " size="large" type="primary"
                           class="w-100 ms-0 mt-2 mt-md-0">
                  Next
                </el-button>

              </div>


            </div>
          </div>

          <!--      <el-form :model="Client" :rules="formRules" hide-required-asterisk label-position="top" class="w-100">-->
          <!--        <div class="row">-->
          <!--          <div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="companyName">-->
          <!--              <template #label>-->
          <!--                <span>Name</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.name" ></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div><div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="companyName">-->
          <!--              <template #label>-->
          <!--                <span>Company Name</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.companyName" ></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <!--          <div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="address1">-->
          <!--              <template #label>-->
          <!--                <span>Address 1</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.address1"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <!--          <div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="address2">-->
          <!--              <template #label>-->
          <!--                <span>Address 2</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.address2"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <!--          <div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="address3">-->
          <!--              <template #label>-->
          <!--                <span>Address 3</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.address3"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div><div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="mobile">-->
          <!--              <template #label>-->
          <!--                <span>Phone</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.mobile"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <!--          <div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="mobile">-->
          <!--              <template #label>-->
          <!--                <span>Postcode</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.postCode"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div><div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="mobile">-->
          <!--              <template #label>-->
          <!--                <span>City</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.city"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <!--          <div class="col-12 col-md-6">-->
          <!--            <el-form-item>-->
          <!--              <template #label>-->
          <!--                <span>No of Employees</span>-->
          <!--              </template>-->
          <!--              <el-select  class="w-100" v-model="Client.companySize">-->
          <!--                <el-option v-for="item in noOfEmployees" :key="item.value" :value="item.value">-->
          <!--                  {{ item.text }}-->
          <!--                </el-option>-->
          <!--              </el-select>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <!--          <div class="col-12 col-md-6">-->
          <!--            <el-form-item>-->
          <!--              <template #label>-->
          <!--                <span>Industry</span>-->
          <!--              </template>-->
          <!--              <el-select  class="w-100" v-model="Client.companyIndustry">-->
          <!--                <el-option v-for="item in companiesList" :key="item.value" :value="item.value">-->
          <!--                  {{ item.text }}-->
          <!--                </el-option>-->
          <!--              </el-select>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <!--          <div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="password">-->
          <!--              <template #label>-->
          <!--                <span>Password</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.password"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <!--          <div class="col-12 col-md-6">-->
          <!--            <el-form-item prop="confirmPassword" :rules="formRules.retypePassword">-->
          <!--              <template #label>-->
          <!--                <span>Confirm Password</span>-->
          <!--              </template>-->
          <!--              <el-input v-model="Client.confirmPassword"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      <div class="d-flex justify-content-center align-items-center mt-3">-->
          <!--        <el-button :disabled="!Client.companyName" @click="register()" :loading="loading" type="primary" size="large" class="w-100 w-md-50">-->
          <!--          Continue-->
          <!--          <i class="bi bi-chevron-right ms-2"></i>-->
          <!--        </el-button>-->
          <!--      </div>-->
          <!--      </el-form>-->
        </div>
        <div class="text-center text-small">
                    <span>
                      <span>&#169;</span><span>Light CRM</span> <span>{{ new Date().getFullYear() }}</span>
                    </span>
        </div>
      </div>
      <div v-if="loading" class="spinner-border register-loader"></div>
    </div>
  </div>
</template>
<script>
import clientService from "@/Services/ComponentsServices/clientService";
import {ShowMessage} from "@/Utility/Utility";
import userService from "@/Services/ComponentsServices/userService";

import loginServices from "@/Services/loginServices";
import countriesList from "@/Utility/countriesList";
import countries from "@/Utility/countriesList";
import loginCoreService from "@/Services/ComponentsServices/loginCoreService";

export default {
  name: "RegisterUser",
  data() {
    return {
      loading: false,
      activeStep: 0,
      specialCh: false,
      hasCapitalChar: false,
      hasNumber: false,
      countryCode: "+44",
      imageUrl: "",
      countryCodes: countriesList,
      guid: sessionStorage.getItem("guid"),
      languages: [
        {text: "English", value: "English"},
        {text: "French", value: "French"},
        {text: "Arabic", value: "Arabic"},
      ],
      Client: {
        "name": "",
        "language": "English",
        "userEmail": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "postCode": "",
        "city": "",
        "mobile": "",
        "companyName": "",
        "companySize": "",
        "companyIndustry": "",
        "password": ""
      },
      companiesList: [
        {text: "IT", value: "IT"},
        {text: "Medical", value: "Medical"},
        {text: "Automobile", value: "Automobile"}
      ],
      noOfEmployees: [
        {text: "1-5", value: "1-5"},
        {text: "5-10", value: "5-10"},
        {text: "11-50", value: "11-50"},
        {text: "51+", value: "51+"},
      ],
      formRules: {
        password: [
          {required: true, message: 'Please enter your password', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        username: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ],
        retypePassword: [
          {required: true, message: 'Password does not match', trigger: 'blur'},
          /*  {type: 'password', message: 'Password does not match', trigger: ['blur', 'change']},*/
          {validator: this.validatePassword, trigger: ['blur', 'change']}
        ],
      },
    }
  },
  methods: {
    countries() {
      return countries
    },
    passwordOk() {
      return this.specialCh &&
          this.hasCapitalChar && this.hasNumber && this.Client.password.length >= 8;
    },
    containsSpecialCharacters(inputString) {
      // Define a regular expression to match any character that is not alphanumeric or whitespace
      const regex = /[^a-zA-Z0-9\s]/;
      const capitalCharRegex = /[A-Z]/;
      const numberRegex = /[0-9]/;
      // Test the input string against the regular expression
      this.specialCh = regex.test(inputString);
      this.hasCapitalChar = capitalCharRegex.test(inputString);
      this.hasNumber = numberRegex.test(inputString);
    },
    validatePassword(rule, value, callback) {
      if (value === this.Client.password) {
        callback();
      } else {
        callback(new Error('Password does not match'));
      }
    },
    validateEmail(rule, value, callback) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        callback();
      } else {
        callback(new Error('Please enter a valid email address'));
      }
    },
    async register() {
      try {
        if (this.Client.mobile !== "") {
          this.Client.mobile = this.countryCode + this.Client.mobile;
        }
        // eslint-disable-next-line no-unreachable
        if (this.Client.email === "" || this.Client.email) {
          ShowMessage("error", "Please signup first to register");
        }
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await clientService.register(this.Client);
        if (response?.data) {
          ShowMessage("success", "Registration successful. Please login using your email and password");
          this.$router.push('/')
        }
        // axios.defaults.headers.common["Authorization"] =
        //     "Bearer " + response.data;
        // sessionStorage.setItem("token", response.data);
        //
        // const decodedToken = jwtDecode(response.data);
        //
        // this.getPermissions(decodedToken.primarysid)
        // sessionStorage.setItem("mUserId", decodedToken.primarysid);

        // this.$router.push('/')
        this.loading = false;
      } catch (e) {
        this.loading = false;
        ShowMessage("error", e.message)
      }
    },
    async getPermissions(userId) {
      try {
        let response = await loginServices.getPermissions();
        sessionStorage.setItem("permissions", JSON.stringify(response.data));

        this.getUser(userId);
      } catch (e) {
        console.log(e)
      }
    },
    async getUser(userId) {
      try {
        let response = await loginServices.getUser(userId);
        sessionStorage.setItem("loggedIn", true);
        this.$store.state.loggedIn = true;
        if (response.data.clientId <= 1) {
          this.getTitle(response.data.clientId);
          this.$router.push('dashboard')
        } else {
          sessionStorage.setItem("clientId", response.data.clientId);

          this.$router.push('home')
        }
      } catch (e) {
        console.log(e)
      }
    },

    async VerifySignup() {
      try {
        if (this.guid === "null" || this.guid === null) {
          //this.$router.push('/')
          return;
        }
        this.loading = true;
        let response = await userService.verifySignup(this.guid);

        if (response?.data?.user?.isValid) {
          this.Client.userEmail = response.data.user.username;
        } else {
          ShowMessage("error", response.data.message + ". Signup again");
          this.$router.push('/signup')
        }
        this.loading = false;
        sessionStorage.clear();
      } catch (e) {
        sessionStorage.clear();
        this.loading = false;
        console.log(e)
        ShowMessage("error", "Email already registered");
      }
    },
  },
  beforeCreate() {
    let urlParams = new URLSearchParams(window.location.search);
    const guid = urlParams.get('guid');
    if (guid !== null) {
      sessionStorage.setItem("guid", guid)
    }
    // else {
    //   this.$router.push('/')
    // }
    // Remove the token from the URL
    setTimeout(function () {
      let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({path: newUrl}, '', newUrl);
    }, 1);
  },
  created() {
    this.VerifySignup();
    loginCoreService.getLoginImage("[LoginBackground]").then((res) => {
      this.imageUrl = res.data.imageData;
    });

  }
}
</script>

<style scoped>
.main-wrapper {
  height: 100vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.register-loader {
  position: absolute;
  z-index: 999;
  left: 49%;
  top: 48%;
}

.el-step__head.is-process {
  color: #2A5699 !important;
  border-color: #2A5699 !important;
}

.el-tabs__nav-scroll {
  overflow: hidden !important;
  display: none !important;
}
</style>