import config from "@/Configuration/Config"
import axios from 'axios';

export default {
    getFaqsUrl: config.apiBaseUrl + "faq/getfaqs?search=",
    getFaqUrl: config.apiBaseUrl + "faq/getfaqbyid?pageId=",
    addFaqUrl: config.apiBaseUrl + "faq/add",
    updateFaqUrl: config.apiBaseUrl + "faq/update",
    deleteFaqUrl: config.apiBaseUrl + "faq/delete?pageId=",

    //Categories
    getCategoriesUrl: config.apiBaseUrl + "faq/getcategories?search=",
    getCategoryUrl: config.apiBaseUrl + "faq/getcategorybyid?catId=",
    getCategoryByTargetUrl: config.apiBaseUrl + "faq/getcategoriesbytarget?target=",
    addCategoryUrl: config.apiBaseUrl + "faq/addcategory",
    updateCategoryUrl: config.apiBaseUrl + "faq/updatecategory",
    deleteCategoryUrl: config.apiBaseUrl + "faq/deletecategory?catId=",


    getFaqs(filter) {
        return axios.get(this.getFaqsUrl + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getFaq(id) {
        return axios.get(this.getFaqUrl + id);
    },
    addFaq(faq) {
        return axios.post(this.addFaqUrl, faq
        );
    },
    updateFaq(faq) {
        return axios.put(this.updateFaqUrl, faq);
    },
    deleteFaq(id) {
        return axios.delete(this.deleteFaqUrl + id);
    },
    //Categories
    getCategories(filter) {
        return axios.get(this.getCategoriesUrl + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getAllCategories(filter) {
        return axios.get(this.getCategoriesUrl + filter.search + "&page=" +1+ "&limit=" + 100);
    },
    getCategory(id) {
        return axios.get(this.getCategoryUrl + id);
    },
    addCategory(category) {
        return axios.post(this.addCategoryUrl, category);
    },
    updateCategory(category) {
        return axios.put(this.updateCategoryUrl, category);
    },
    deleteCategory(id) {
        return axios.delete(this.deleteCategoryUrl + id);
    }


}