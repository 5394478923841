<template>
  <div class="container-fluid">
    <ToolBar :showBread="false" :showSelect="false" :ShowAddBtn="false"/>
    <div class="row">
      <div class="d-flex justify-content-end mb-1">
        <div class="col-12 col-md-4 ">
          <div class="d-flex justify-content-end">
            <el-select filterable v-if="clientId === null || clientId < 1" v-model="filter.clientId" class="me-2"
                       @change="getConfigs()">
              <el-option v-for="item in Clients"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
                <template #default>
                  <div class="row">
                    <el-tooltip v-if="item.name.length > 20" :content="item.name">
                      <span class="col-7">{{ getClientName(item.name) }}</span>
                    </el-tooltip>
                    <span v-else class="col-7">{{ getClientName(item.name) }}</span>
                    <span class="text-small col-5">{{ item.market }}</span>
                  </div>
                </template>
              </el-option>
            </el-select>

          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-1 ms-auto p-0 m-0 d-flex justify-content-end">

          <el-input v-model="filter.search" class="align-self-end" clearable
                    :placeholder="getMessageByCode('search') ?? 'Search here...'"
                    @clear="getConfigs"
                    @keyup="getConfigs">
            <template #append>
              <el-button @click="getConfigs">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <el-table :data="Configs" :default-sort="{ prop: 'key', order: 'ascending' }" header-cell-class-name="tbl-header">
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              size="small"
          >
          </el-pagination>
        </template>
        <el-table-column :label="getMessageByCode('key') ?? 'Key'" prop="key" min-width="250" sortable>
          <el-table-column min-width="250">
            <template #header>
              <el-input
                  :disabled="Config.key.toString() === 'CurrencySymbol' || Config.key.toString()==='CurrencyPosition' || Config.key.toString()==='DateFormat'"
                  v-if="configCreate && Config.id === 0" @keydown.space.prevent placeholder="Type here..."
                  v-model="Config.key"></el-input>
              <el-input
                  :disabled="Config.key.toString() === 'CurrencySymbol' || Config.key.toString()==='CurrencyPosition' || Config.key.toString()==='DateFormat'"
                  v-if="configEdit && Config.id > 0" @keydown.space.prevent placeholder="Type here..."
                  v-model="Config.key"></el-input>
            </template>
            <template #default="scope">
              <div style="min-width: 150px !important;">
                {{ scope.row.key }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="getMessageByCode('value') ?? 'Value'" prop="value" min-width="350" sortable>
          <el-table-column min-width="350">
            <template #header>
              <div class="d-flex justify-content-end">
                <el-input v-if="configCreate && Config.id === 0 && Config.value.length < 500" class="w-100" clearable
                          placeholder="Type here..." v-model="Config.value"></el-input>
                <el-input v-else-if="configEdit && Config.id > 0 && Config.value.length < 500" class="w-100" clearable
                          placeholder="Type here..." v-model="Config.value"></el-input>
                <div v-else class="w-100 d-flex align-items-center">
                  <span>{{ fileName }}</span>
                  <i v-if="Config.value.length > 0" class="bi bi-x action-btn fs-3 me-2" @click="Config.value = ''"></i>
                </div>
                <input
                    :disabled="!configCreate"
                    :key="fileInputKey"
                    ref="uploader1"
                    class="d-none"
                    type="file"
                    @change="onFileChanged"
                />
                <el-tooltip
                    class="box-item "
                    effect="dark"
                    content="Upload Image"
                    placement="top"
                >
                  <el-button v-if="configCreate" :disabled="!configCreate" :disable="configCreate" type="primary"
                             class="ms-2 border-0" @click="handleFileImport()">
                    <el-icon>
                      <i class="bi bi-image"></i>
                    </el-icon>
                  </el-button>
                </el-tooltip>
              </div>
            </template>
            <template #default="scope">
              <div v-if="scope.row.value.length < 100" style="min-width: 150px !important;">
                {{ scope.row.value }}
              </div>
              <div v-else style="min-width: 150px !important;">
                <img :src="scope.row.value" width="25"/>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="getMessageByCode('desc') ?? 'Description'" prop="description" min-width="350" sortable>
          <el-table-column min-width="350">
            <template #header>
              <el-input v-if="configCreate && Config.id === 0" placeholder="Type here..."
                        v-model="Config.description"></el-input>
              <el-input v-if="configEdit && Config.id > 0" placeholder="Type here..."
                        v-model="Config.description"></el-input>
            </template>
            <template #default="scope">
              <div style="width: 250px !important;"> {{ scope.row.description }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="getMessageByCode('action') ?? 'Action'" fixed="right" min-width="220" align="center">
          <el-table-column fixed="right" min-width="220" align="right">
            <template #header>
              <el-space>
                <!--                <el-button type="primary" :disabled="!Config.key|| !Config.value" class="px-2"
                                           @click="AddOrUpdateConfig">
                                  <span class="mgc_add_line me-1"></span>
                                  {{ addbtnText }}
                                </el-button> -->
                <el-button v-if="Config.id < 1 && configCreate" type="primary"
                           :disabled="!Config.key|| !Config.value || !configCreate" class="px-2"
                           @click="AddConfig()">
                  <span class="mgc_add_line me-1"></span>
                  {{ getMessageByCode('add') ?? 'Add' }}
                </el-button>
                <el-button v-if="Config.id > 0 && configEdit" type="primary"
                           :disabled="!Config.key|| !Config.value || !configEdit" class="px-2"
                           @click="UpdateConfig">
                  <span class="mgc_add_line me-1"></span>
                  {{ getMessageByCode('update') ?? 'Update' }}
                </el-button>
                <el-button @click="resetConfig" class="bg-warning text-white ps-2 pe-2">
                  <span class="bi bi-x-octagon-fill me-2"></span>
                  {{ getMessageByCode('cancel') ?? 'Cancel' }}
                </el-button>
              </el-space>
            </template>
            <template #default="scope">
              <el-space>
                <button v-if="configEdit" @click="takeData(scope.row)" class="btn btn-sm btn-success">
                  <span class="bi bi-pencil-square me-2"></span>
                  {{ getMessageByCode('edit') ?? 'Edit' }}
                </button>
                <button v-if="configDel" class="btn btn-sm btn-danger" @click="deleteConfig(scope.row)">
                  <span class="mgc_delete_2_line me-1"></span>
                  {{ getMessageByCode('delete') ?? 'Delete' }}
                </button>
              </el-space>
            </template>
          </el-table-column>

        </el-table-column>
      </el-table>
    </div>
    <div v-if="loading" class="spinner-border"></div>

  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import ApiService from "@/Services/ApiService";
import {ShowMessage} from "@/Utility/Utility";
import clientService from "@/Services/ComponentsServices/clientService";
import configService from "@/Services/ComponentsServices/configService";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";
import getClientName from "../../Utility/getClientName";

export default {
  name: "SettingsComp",
  components: {ToolBar},
  data() {
    return {
      addbtnText: "Add",
      configCreate: false,
      configView: false,
      configEdit: false,
      configDel: false,
      edit: false,
      fileInputKey: Date.now(),
      loading: true,
      translations: [],
      Configs: [],
      clientId: sessionStorage.getItem("clientId"),
      Clients: [
        {
          id: 0,
          name: "Super Admin"
        }
      ],
      fileName: "",
      Config: {
        id: 0,
        clientId: sessionStorage.getItem("clientId") ?? 0,
        key: "",
        value: "",
        description: ""
      },
      configColumns: [
        {"label": "Key", "prop": "key", "width": "250"},
        {"label": "Value", "prop": "value", "width": "150"},
        {"label": "Description", "prop": "description", "width": "400"},
      ],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    getClientName,
    handleFileImport() {
      this.fileInputKey = Date.now();
      this.$refs.uploader1.click();
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.fileName = file.name;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.Config.value = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    takeData(row) {
      this.Config = {...row};
      this.addbtnText = "Update"
    },
    resetConfig() {
      this.Config.description = "";
      this.Config.value = "";
      this.Config.key = "";
      this.Config.id = 0;
      this.addbtnText = "Add";
      this.Config.clientId = sessionStorage.getItem("clientId") ?? 0;
    },
    AddOrUpdateConfig() {
      switch (this.Config.id) {
        case 0:
          this.AddConfig();
          break;
        case null:
          this.Config.id = 0;
          this.AddConfig();
          break;
        default:
          if (this.Config.id > 0) {
            this.UpdateConfig();
          }
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getConfigs();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getConfigs();
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') < 1) {
          let response = await clientService.getAll();
          this.Clients = this.Clients.concat(response.data.items);
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
          }
        }
        await this.getConfigs();
      } catch (e) {
        console.error(e)
        this.$store.state.loading = false;
      }
    },
    async getConfigs() {
      try {
        this.loading = true;
        let response = await configService.all(this.filter);
        this.Configs = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;

        this.$store.state.percentage = 100
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async AddConfig() {
      try {
        await configService.add(this.Config);

        this.resetConfig();
        this.getConfigs();
        ShowMessage("success", "Config added successfully")
      } catch (e) {
        console.log(e)
        ShowMessage("error", "Something went wrong!")
      }
    }
    ,
    async UpdateConfig() {
      try {
        console.log(this.Config);
        //return;
        // eslint-disable-next-line no-unused-vars,no-unreachable
        const response = await ApiService.put("config", "update", this.Config);
        if ((this.Config.key.toString() === 'AppTitle' || this.Config.key.toString() === 'FavIcon') && this.clientId === this.filter.clientId) {
          await this.getTitle(this.filter.clientId);
        }
        this.resetConfig();
        await this.getConfigs();
        ShowMessage("success", "Config updated successfully")
      } catch (e) {
        console.log(e)
        ShowMessage("error", "Something went wrong!")
      }
    },
    async deleteConfig(row) {
      try {
        // eslint-disable-next-line no-unused-vars
        let response = await ApiService.delete("config", "configId", row.id);

        this.getConfigs();
      } catch (e) {
        console.log(e)
      }
    }
    ,
    async getTitle(clientId) {
      try {

        const response = await configService.getByKey(clientId, 'AppTitle');
        const response2 = await configService.getByKey(clientId, 'FavIcon');

        if (response.data.value !== undefined) {
          const title = response.data.value
          document.title = title;
        }
        if (response2.data.value !== undefined) {
          const icon = response2.data.value;
          this.updateFavicon(icon)
        }

      } catch (error) {
        console.error('Error fetching app data', error);
      }
    },
    updateFavicon(imageString) {
      // Create a link element
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link");

      // Set the attributes for the link element
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = imageString;

      // Update the favicon in the document's head
      document.head.appendChild(link);
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.configView = checkPermission("MD_CONFIG_VIEW")
    this.configCreate = checkPermission("MD_CONFIG_CREATE")
    this.configDel = checkPermission("MD_CONFIG_DEL")
    this.configEdit = checkPermission("MD_CONFIG_EDIT")
    this.getClients();
  }

}
</script>

<style scoped>

</style>