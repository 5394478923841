<template>
  <div class="container-fluid p-2 p-md-0 main-wrapper" style="background-repeat: no-repeat;
  background-size: cover;" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
    <div class="row login-wrapper justify-content-center align-items-center">
      <div class="col-12 col-md-9 bg-white shadow rounded">
        <div class="row">
          <div class="col-12 col-lg-6 px-5 py-5 text-center sign-in-col">
            <div class="mb-3">
              <div class="logo py-4">
                <span v-if="!logo">Logo</span>
                <img v-else :src="logo" :class="['responsive-logo']" alt="logo" :width="logoWidth" :height="logoHeight">
              </div>
              <h3 class="fw-bold">Sign In</h3>
              <span>New Here?
              <router-link to="signup">
             Create an account
              </router-link>

              </span>
            </div>
            <div class="col-12 col-md-11 m-0 p-0">
              <el-form>
                <el-form-item>
                  <label>Email</label>
                  <el-input
                      v-model="User.userName"
                      class="inpt-bg"
                      size="large"
                      tabindex="1"
                      @keydown.space.prevent
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="d-flex justify-content-between w-100">
                    <label>Password</label>
                    <router-link to="forgot-password">
                      <a href="">Forgot password?</a>
                    </router-link>
                  </div>
                  <el-input
                      v-model="User.password"
                      :type="showPass ? 'text' : 'password'"
                      size="large"
                      tabindex="2"
                      @keyup.enter="Login"
                  >
                    <template #append>
                      <el-button class="apndBtn" @click="showPass = !showPass">
                        <i v-if="showPass" class="bi bi-eye"></i>
                        <i v-else class="bi bi-eye-slash"></i>
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item class="mt-5">
                  <el-button
                      :disabled="loading || (!User.userName || !User.password)"
                      :loading="loading"
                      class="mbtn-primary rounded-1 w-100"
                      size="large"
                      @click="Login"
                  >{{ loading ? "Signing In" : "Continue" }}
                  </el-button
                  >
                </el-form-item>

                <!--                <el-divider>OR</el-divider>-->
                <!--                <el-form-item>-->
                <!--                  <el-button bg class="btn btn-light w-100" size="large" text>-->
                <!--                    <img-->
                <!--                        alt=""-->
                <!--                        src="../../assets/svg/brand-logos/google-icon.svg"-->
                <!--                        width="18"-->
                <!--                    />-->
                <!--                    <span class="ms-2">Continue with Google</span>-->
                <!--                  </el-button>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item>-->
                <!--                  <el-button bg class="btn btn-light w-100" size="large" text>-->
                <!--                    <img-->
                <!--                        alt=""-->
                <!--                        src="../../assets/svg/brand-logos/facebook-4.svg"-->
                <!--                        width="18"-->
                <!--                    />-->
                <!--                    <span class="ms-2">Continue with Facebook</span>-->
                <!--                  </el-button>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item>-->
                <!--                  <el-button bg class="btn btn-light w-100" size="large" text>-->
                <!--                    <img-->
                <!--                        alt=""-->
                <!--                        src="../../assets/svg/brand-logos/apple-black.svg"-->
                <!--                        width="18"-->
                <!--                    />-->
                <!--                    <span class="ms-2">Continue with Apple</span>-->
                <!--                  </el-button>-->
                <!--                </el-form-item>-->
              </el-form>
            </div>
            <div class="text-center text-small">
                     <span>
                      <span>&#169;</span><span>{{ ' ' + product.productName }}</span> <span>{{
                         ' ' + product.edition
                       }}</span> <span>{{
                         new Date().getFullYear()
                       }}</span><span>{{ ' ' + product.productVersion }}</span>
                    </span>
            </div>
          </div>
          <div class="col-12 col-md-6 d-none d-lg-block login-side-section p-2" style="background-repeat: no-repeat;
  background-size: cover;" :style="{ backgroundImage: 'url(' + sideImage + ')' }">
            <!--            <img src="../../assets/images/login.png" alt="side-img"/>-->
            <div class="d-flex flex-column">
              <span class="quote">{{ quote.quote }}</span>
              <span class="author mt-2">{{ quote.author }}</span>
              <!--                <div class="d-flex justify-content-end mt-2">-->
              <!--                -->
              <!--                </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border login-loader"></div>
    <!--    <div class="myspinner">

        </div>-->
  </div>
</template>
<script>
import axios from "axios";
import {ShowMessage} from "@/Utility/Utility";
import {jwtDecode} from "jwt-decode";
import loginServices from "@/Services/loginServices";
import configService from "@/Services/ComponentsServices/configService";
import clientService from "@/Services/ComponentsServices/clientService";
import loginCoreService from "@/Services/ComponentsServices/loginCoreService";

export default {
  name: "LogIn",
  data() {
    return {
      loading: false,
      showPass: false,
      imageUrl: '',
      sideImage: '',
      logo: "",
      logoWidth: 150,
      logoHeight: 150,
      productVersion: "v1.01.1",
      productName: "Light CRM",
      product: {
        "id": 0,
        "productName": "Light CRM",
        "productVersion": "v1.01.1",
        "edition": "AUT",
        "release": 0,
        "build": 0,
        "patch": 0
      },
      quote: {
        "id": 0,
        "author": "",
        "quote": "",
        "isActive": true
      },
      User: {
        userName: "",
        password: "",
      },
    };
  },
  methods: {
    Login: async function () {
      try {
        this.loading = true;
        let response = await loginServices.authenticate(this.User);
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data;
        sessionStorage.setItem("token", response.data);

        const decodedToken = jwtDecode(response.data);
        sessionStorage.setItem("market", decodedToken.given_name)
        await this.getPermissions(decodedToken.primarysid)
        sessionStorage.setItem("mUserId", decodedToken.primarysid);
      } catch (e) {
        if (e.response !== undefined) {
          ShowMessage("error", e.response.data.message);
        } else {
          ShowMessage("error", "Something went wrong!")
        }
      }
      this.loading = false;
    },
    async getUser(userId) {
      try {
        const response = await loginServices.getUser(userId);

        // Ensure sessionStorage and state are set only after successful user retrieval

        sessionStorage.setItem("providerId", response?.data?.providerId)
        sessionStorage.setItem("clientId", response?.data?.clientId)
        sessionStorage.setItem("roleName", response?.data?.roleName)
        sessionStorage.setItem("userId", response?.data?.id);
        sessionStorage.setItem("clientGuid", response?.data?.clientGuid);
        let market = sessionStorage.getItem("market");
        if (response.data.clientId <= 1) {
          await this.getTitle(response.data.clientId);
          await this.getLanguage(response.data.iso);  // Await the language retrieval

        } else {
          sessionStorage.setItem("clientId", response.data.clientId);
          await this.getTitle(response.data.clientId);
          await this.getLanguage(response.data.iso); // Await the language retrieval
        }
        if (market.toLowerCase() === "property management") {
          this.$router.push('/pms')
        } else if (market.toLowerCase() === 'housing management') {
          this.$router.push('/housing')
        } else if (market.toLowerCase() === 'accountancy') {
          this.$router.push('/accountancy')
        } else {
          this.$router.push('/dashboard')
        }
        sessionStorage.setItem("loggedIn", true);
        this.$store.state.loggedIn = true;

      } catch (error) {
        console.error("Error fetching user or setting language:", error);
      }
      this.loading = false;
    },
    async getProduct() {
      try {
        let response = await loginCoreService.getProduct();
        this.product = response?.data ?? {
          "id": 0,
          "productName": "Light CRM",
          "productVersion": "v1.01.1",
          "edition": "AUT",
          "release": 0,
          "build": 0,
          "patch": 0
        }
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    async getLanguage(iso) {
      try {
        if (iso === null || iso === undefined) {
          iso = 'en';
        }
        const response = await clientService.getClientLanguage(iso);
        sessionStorage.setItem("language", JSON.stringify(response.data));
        return response;
      } catch (error) {
        console.error("Error fetching language:", error);
      }
    },
    async getPermissions(userId) {
      try {
        sessionStorage.removeItem("permissions");
        let response = await loginServices.getPermissions();
        sessionStorage.setItem("permissions", JSON.stringify(response.data));
        this.getUser(userId);
      } catch (e) {
        console.log(e)
      }
    },

    async getTitle(clientId) {
      try {

        const response = await configService.getByKey(clientId, 'AppTitle');
        const response2 = await configService.getByKey(clientId, 'FavIcon');
        if (response.data.value !== undefined) {
          // Replace with your actual API endpoint
          const title = response.data.value
          /*const favicon = document.getElementById('favicon');
          if (favicon) {
            favicon.href = faviconUrl;
          }

          // Set title dynamically*/
          document.title = title;
        }
        if (response2.data.value !== undefined) {
          const icon = response2.data.value;
          // Convert base64 to Blob
          this.updateFavicon(icon)

        }

      } catch (error) {
        console.error('Error fetching app data', error);
      }
    },
    updateFavicon(imageString) {
      // Create a link element
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link");

      // Set the attributes for the link element
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = imageString;

      // Update the favicon in the document's head
      document.head.appendChild(link);
    },
    base64ToBlob(base64) {
      const binaryString = window.atob(base64);
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const byteArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      return new Blob([arrayBuffer], {type: "image/png"}); // Update type based on your favicon format
    },
    // async getBackground(){
    //    try {
    //      let response = await configService.getByKey(0, "LoginBackground");
    //      console.log(response)
    //    }
    //    catch (e) {
    //      console.log(e)
    //    }
    //  },
    ClearUserData() {
      this.$store.state.loggedIn = false;
      sessionStorage.removeItem("loggedIn");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("clientId");
      sessionStorage.removeItem("permissions"); // Clear permissions explicitly
      sessionStorage.clear(); // Optional, if you want to clear everything
      localStorage.clear();
    },
    async getLogo() {
      try {
        // Fetch the logo configuration
        let logo = await configService.getByKey(0, "LOGO");

        // Extract the Base64 string (assuming it's in logo.data.value)
        this.logo = logo.data.value;

        const img = new Image();
        img.src = this.logo; // Use the Base64 string as the image source

        // Wait for the image to load
        img.onload = () => {
          this.logoWidth = img.width;
          this.logoHeight = img.height;
        };

        // Handle image loading errors
        img.onerror = () => {
          console.error("Failed to load the Base64 image.");
        };

      } catch (error) {
        console.error("Error fetching the logo:", error);
      }
    }
  },
  beforeCreate() {


  },
  created() {
    this.ClearUserData();
    //this.getBackground();
    loginCoreService.getLoginImage("[LoginBackground]").then((res) => {
      this.imageUrl = res.data.imageData;
    });
    loginCoreService.getLoginImage("[LoginSide]").then((res) => {
      this.sideImage = res.data.imageData;
    });
    loginCoreService.getLoginQuote().then((res) => {
      this.quote = res.data;
    });
    this.getLogo();
    loginCoreService.getLoginImage("[LoginBackground]").then((res) => {
      this.imageUrl = res.data.imageData;
    });
    loginCoreService.getLoginImage("[LoginBackground]").then((res) => {
      this.imageUrl = res.data.imageData;
    });
    this.getProduct();
    // configService.getByKey(0, "LoginBackground").then((res) => {
    //   this.imageUrl = res.data.value;
    // })
  }
};
</script>
<style scoped>
.main-wrapper {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-wrapper {
  height: 100vh;
  overflow: hidden !important;
  z-index: 999 !important;
}

.login-loader {
  position: absolute;
  z-index: 999;
  left: 30%;
  top: 55%;
}

.quote {
  font-size: 24px;
  margin: 35px;
}

.author {
  font-size: 21px;
  font-style: italic;
  margin: 35px;
}

.apndBtn :hover {
  outline: none !important;
  border: 0px !important;
}

.el-input-group__append button.el-button, .el-input-group__append button.el-button:hover, .el-input-group__append div.el-select .el-select__wrapper, .el-input-group__append div.el-select:hover .el-select__wrapper, .el-input-group__prepend button.el-button, .el-input-group__prepend button.el-button:hover, .el-input-group__prepend div.el-select .el-select__wrapper, .el-input-group__prepend div.el-select:hover .el-select__wrapper {
  border: none !important;
  outline: none !important;
}

.myspinner {
  position: absolute !important;
  top: 50% !important;
  left: 30% !important;
  width: 50px;
  height: 50px;
  border: 7px solid;
  border-radius: 50%;
  border-color: red black black;
}
</style>
