<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="false" :show-bread="false" :show-select="false"/>
    <div>
      <el-tabs v-model="activeTab" type="border-card" @tab-click="handleTabClick">
        <el-tab-pane :name="1">
          <template #label>
            <span class="custom-tabs-label">
              <i class="bi bi-person-vcard me-1"></i>
               <span class="text-capitalize">{{ getMessageByCode('customers') ?? 'Customers' }}</span>
            </span>
          </template>
          <div class="container-fluid mx-0 px-0 mt-2">
            <div class="col-12 bg-white">
              <div class="row d-flex justify-content-between align-items-center py-2">
                <div class="col-12 col-md-8">
                  <span class="fw-500">{{ getMessageByCode('linkedCustomers') ?? 'Linked customers' }}</span>
                </div>
                <div class="col-12 col-md-4">
                  <el-input v-model="linkedFilter.search" placeholder="Search here..." @keyup="getLinkedUsers">
                    <template #append>
                      <el-button @click="getLinkedUsers">
                        <template #icon>
                          <i class="bi bi-search"></i>
                        </template>
                      </el-button>
                    </template>
                  </el-input>
                </div>
              </div>
              <el-table :data="linkedCustomers" class-name="rounded-bottom" :fit="true" :flexible="true"
                        :selectable="(row) => row.status !== 'disabled'" border
                        header-cell-class-name="tbl-header" size="small"
                        stripe>
                <template #append>
                  <el-pagination
                      @size-change="handleUnlinkedSizeChange"
                      @current-change="handleUnlinkedCurrentChange"
                      :total="unlinkedFilter.totalCount"
                      :page="unlinkedFilter.currentPage"
                      :page-size="unlinkedFilter.pageSize"
                      :page-sizes="[6, 12, 18, 24]"
                      layout=" prev, pager, next,sizes"
                      class="bg-gray p-2"
                      background
                      size="small"
                  >
                  </el-pagination>
                </template>
                <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('name') ?? 'Name'" width="180">
                  <template #default="scope">
                    <div class="d-flex align-items-center">
                      <!--                <el-avatar :size="35">-->
                      <!--                  <i class="bi bi-person-fill"></i>-->
                      <!--                </el-avatar>-->
                      <div class="ms-2 d-flex flex-column">

                  <span @click="takeCustomer(scope.row, 'view')"
                        class="cursor-hand text-primary text-decoration-underline">{{
                      getCustomerName(scope.row)
                    }}</span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip sortable width="220"
                                 :label="getMessageByCode('email') ?? 'Email'"
                                 prop="email"></el-table-column>
                <el-table-column show-overflow-tooltip sortable width="180"
                                 :label="getMessageByCode('mobile') ?? 'Mobile'"
                                 prop="mobile"></el-table-column>
                <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('address') ?? 'Address'">
                  <template #default="scope">
                    <span>{{ formatAddress(scope.row) }}</span>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip sortable width="150"
                                 :label="getMessageByCode('company') ?? 'Company'"
                                 prop="companyName"></el-table-column>
                <el-table-column show-overflow-tooltip width="100" sortable
                                 :label="getMessageByCode('status') ?? 'Status'">
                  <template #default="scope">
                    <el-tag v-if="scope.row.appUserId > 0" type="success">Linked</el-tag>
                  </template>
                </el-table-column>
                <el-table-column width="80">
                  <template #header>
                    <div class="text-center">
                      <span>{{ getMessageByCode('action') ?? 'Action' }}</span>
                    </div>
                  </template>
                  <template #default="scope">
                    <div class="text-center">
                      <el-space>
                        <el-tooltip :content="getMessageByCode('view') ?? 'View'">
                          <i @click="takeCustomer(scope.row, 'view')"
                             class="action-btn text-primary mgc_eye_2_line"></i>
                        </el-tooltip>
                        <el-tooltip :content="getMessageByCode('unlink') ?? 'Unlink'">
                          <i @click="takeCustomer(scope.row, 'unlink')"
                             class="action-btn text-danger mgc_unlink_line"></i>
                        </el-tooltip>
                      </el-space>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="container-fluid mx-0 px-0 mt-5">
            <div class="col-12 bg-white">
              <div class="row d-flex justify-content-between align-items-center py-2">
                <div class="col-12 col-md-8">
                  <span class="fw-500">{{ getMessageByCode('awaitingCustomers') ?? 'Awaiting customers' }}</span>
                </div>
                <div class="col-12 col-md-4">
                  <el-input v-model="unlinkedFilter.search" placeholder="Search here..." @keyup="getUnlinkedUsers">
                    <template #append>
                      <el-button @click="getUnlinkedUsers">
                        <template #icon>
                          <i class="bi bi-search"></i>
                        </template>
                      </el-button>
                    </template>
                  </el-input>
                </div>
              </div>
              <el-table :data="unlinkedCustomers" class-name="rounded-bottom" :fit="true" :flexible="true"
                        :selectable="(row) => row.status !== 'disabled'" border
                        header-cell-class-name="tbl-header" size="small"
                        stripe>
                <template #append>
                  <el-pagination
                      @size-change="handleUnlinkedSizeChange"
                      @current-change="handleUnlinkedCurrentChange"
                      :total="unlinkedFilter.totalCount"
                      :page="unlinkedFilter.currentPage"
                      :page-size="unlinkedFilter.pageSize"
                      :page-sizes="[6, 12, 18, 24]"
                      layout=" prev, pager, next,sizes"
                      class="bg-gray p-2"
                      background
                      size="small"
                  >
                  </el-pagination>
                </template>
                <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('name') ?? 'Name'" prop="name">
                </el-table-column>
                <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('email') ?? 'Email'"
                                 prop="email">
                </el-table-column>
                <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('phone') ?? 'Phone'"
                                 prop="phone"></el-table-column>
                <el-table-column show-overflow-tooltip width="100" sortable
                                 :label="getMessageByCode('status') ?? 'Status'">
                  <template #default>
                    <el-tag type="danger">Unlinked</el-tag>
                  </template>
                </el-table-column>
                <el-table-column width="100">
                  <template #header>
                    <div class="text-center">
                      <span>{{ getMessageByCode('action') ?? 'Action' }}</span>
                    </div>
                  </template>
                  <template #default="scope">
                    <div class="text-center">
                      <el-space>
                        <el-tooltip :content="getMessageByCode('link') ?? 'Link'">
                          <i @click="takeUser(scope.row, 'link')" class="action-btn text-primary mgc_link_line"></i>
                        </el-tooltip>
                        <el-tooltip :content="getMessageByCode('create') ?? 'Create'">
                          <i @click="addCustomer(scope.row)" class="action-btn text-success mgc_add_circle_line"></i>
                        </el-tooltip>
                      </el-space>

                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

        </el-tab-pane>
        <el-tab-pane :name="2">
          <template #label>
            <span class="custom-tabs-label">
              <i class="bi bi-code-slash me-1"></i>
               <span class="text-capitalize">{{ getMessageByCode('authCodes') ?? 'Auth Codes' }}</span>
            </span>
          </template>
          <div class="col-12">
            <div class="row justify-content-end py-1">
              <div class="col-12 col-lg-4">
                <div class="d-flex justify-content-end">
                  <el-input v-model="filter.search" @input="getAuthCodes()"
                            placeholder="Search here..." clearable @clear="getAuthCodes()"></el-input>
                  <el-button  class="ms-2" type="primary"
                             @click="addCodeDialog = true; resetAuthCode()">
                    {{ getMessageByCode('addCode') ?? 'Add Code' }}
                  </el-button>

                </div>
              </div>
            </div>
            <el-table :data="codes" :fit="true" :flexible="true"
                      :selectable="(row) => row.status !== 'disabled'" border
                      header-cell-class-name="tbl-header"
                      stripe>
              <template #append>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :total="filter.totalCount"
                    :page="filter.currentPage"
                    :page-size="filter.pageSize"
                    :page-sizes="[10, 20, 30, 40]"
                    layout=" prev, pager, next,sizes"
                    class="bg-gray p-2"
                    background
                    size="small"
                >
                </el-pagination>
              </template>
              <el-table-column :label="getMessageByCode('authCode') ?? 'Auth Code'" prop="authCode">
              </el-table-column>
              <el-table-column :label="getMessageByCode('expiryDate') ?? 'Expiry Date'" prop="expiryDate">
                <template #default="scope">
                  <span>{{ getFormattedDate(scope.row.expiryDate) }}</span>
                </template>
              </el-table-column>
              <el-table-column width="100" :label="getMessageByCode('valid') ?? 'Valid' + '?'" prop="authCode">
                <template #default="scope">
                  <el-tag size="small" v-if="scope.row.isValid" type="success" class="px-3">Valid</el-tag>
                  <el-tag size="small" v-else type="warning" class="px-2">InValid</el-tag>
                </template>
              </el-table-column>
              <el-table-column fixed="right" width="120" :label="getMessageByCode('action') ?? 'Action'"
                               prop="authCode">
                <template #default="scope">
                  <div class="d-flex justify-content-center">
                    <i class="action-btn mgc_edit_line text-primary"
                       @click="this.authCode = JSON.parse(JSON.stringify(scope.row));this.addCodeDialog = true"></i>
                    <div v-if="scope.row.isValid">
                      <el-tooltip :content="getMessageByCode('disable') ?? 'Disable'">
                        <i class="action-btn mgc_forbid_circle_line text-warning ms-2"
                           @click="disableAuthCode(scope.row.id)"></i>
                      </el-tooltip>
                    </div>
                    <div v-else>
                      <el-tooltip :content="getMessageByCode('enable') ?? 'Enable'">
                        <i class="action-btn mgc_check_circle_line text-success ms-2"
                           @click="enableAuthCode(scope.row.id)"></i>
                      </el-tooltip>
                    </div>
                    <i class="action-btn mgc_delete_2_line text-danger action-btn ms-2 "
                       @click="deleteAuthCode(scope.row.id)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--  Dialogs-->
    <el-dialog v-model="unlinkDialog" width="500" :title="getMessageByCode('cst_unlink_title') ?? 'Unlink App User'">
      <template #default>
        <span>{{
            getMessageByCode('cst_unlink_msg') ?? 'Are you sure you want to unlink the App User from the customer record?'
          }}</span>
        <br>
        <span>{{ getMessageByCode('cst_reunlink_msg') ?? 'You may link again from the App Users menu.' }}</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="unlinkDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="primary" class="ms-2" @click="unlink()">{{ getMessageByCode('unlink') ?? 'Unlink' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="linkDialog" width="500" title="Link App User">
      <template #default>
        <el-form label-position="top">
          <el-form-item :label="getMessageByCode('customer') ??'Customer' ">
            <el-select filterable v-model="freeCustomer.id">
              <el-option v-for="cust in freeCustomers" @click="freeCustomer = cust" :key="cust.id"
                         :label="getCustomerName(cust)" :value="cust.id">
                <template #default>
                  <div class="row">
                    <span class="col-4">{{ getCustomerName(cust) }}</span>
                    <div class="col-8 text-start">
                      <div class="row">
                        <div class="col-7">
                          <span class="ms-1 text-small" v-if="cust.email">{{ cust.email }}</span>
                        </div>
                        <div class="col-5">
                          <span class="ms-1 text-small" v-if="cust.mobile">{{ cust.mobile }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="linkDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button :disabled="freeCustomer.id < 1" type="primary" class="ms-2" @click="link()">
            {{ getMessageByCode('link') ?? 'Link' }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="addCodeDialog" width="500"
               :title="authCode.id < 1 ? getMessageByCode('addCode') ?? 'Add Code' : getMessageByCode('editCode') ?? 'Edit Code'">
      <el-form label-position="top">
        <el-form-item :label="getMessageByCode('authCode') ?? 'Auth Code'">
          <el-input v-model="authCode.authCode" @keydown.space.prevent
                    @input="authCode.authCode = authCode.authCode.toUpperCase()"></el-input>
        </el-form-item>
        <div class="row d-flex justify-content-between align-items-center">
          <el-form-item class="col-8" :label="getMessageByCode('authCode') ?? 'Auth Code'">
            <el-date-picker class="w-100" :format="dateFormat" v-model="authCode.expiryDate"
                            :editable="false" clearable @clear="authCode.expiryDate = null"></el-date-picker>
          </el-form-item>
          <el-form-item class="col" :label="getMessageByCode('isValid') ?? 'Is Valid'  +'?'">
            <el-switch v-model="authCode.isValid"></el-switch>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end align-items-center">
          <el-button text bg @click="addCodeDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button v-if="authCode.id < 1" type="primary" class="ms-2" @click="addCodeDialog = false; addAuthCode()">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="addCodeDialog = false; updateAuthCode()">
            {{ getMessageByCode('update') ?? 'Update' }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <n-spin v-if="loading" size="small" stroke="#000" :stroke-width="15" description="Loading"
            class="cstm-spin"></n-spin>
  </div>
</template>
<script>
import {getMessageByCode} from "@/Utility/getMessagebyCode";
import ToolBar from "@/components/Layout/ToolBar.vue";
import customerService from "@/Services/ComponentsServices/customerService";
import {ShowMessage} from "@/Utility/Utility";
import {NSpin} from "naive-ui";
import syncAppService from "@/Services/ComponentsServices/syncAppService";
import clientService from "@/Services/ComponentsServices/clientService";
import moment from "moment";

export default {
  name: "AppUsers",
  components: {NSpin, ToolBar},
  data() {
    return {
      loading: false,
      addCodeDialog: false,
      linkDialog: false,
      unlinkDialog: false,
      activeTab: 1,
      data: {},
      customer: {},
      appUser: {},
      freeCustomer: {},
      freeCustomers: [],
      linkedCustomers: [],
      unlinkedCustomers: [],
      clientId: sessionStorage.getItem("clientId") ?? 0,
      clientGuid: sessionStorage.getItem("clientGuid") ?? 0,
      dateFormat: "DD/MM/YYYY",
      authCode: {
        "id": 0,
        "clientGuid": "",
        "authCode": "",
        "expiryDate": new Date(),
        "isValid": true
      },
      unlinkedFilter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        search: "",
        sort: "",
        pageSize: 6,
        currentPage: 1,
        totalCount: 0,
      },
      linkedFilter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        search: "",
        sort: "",
        pageSize: 6,
        currentPage: 1,
        totalCount: 0,
      },
      codes: [],
      filter: {
        clientId: 0,
        providerId: 0,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
        guid: sessionStorage.getItem("clientGuid"),
      },
    }
  },
  methods: {
    handleTabClick(tabName) {

      let tab = tabName.index;
      switch (tab) {
        case "0":

          break;
        case "1":
          this.getAuthCodes();
          break;
      }
    },
    getFormattedDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format(this.dateFormat);
    },
    getMessageByCode,
    takeCustomer(customer, type) {
      this.customer = JSON.parse(JSON.stringify(customer));
      const encodedId = btoa(this.customer.id.toString());
      switch (type) {
        case 'edit':
          break;
        case 'unlink':
          this.unlinkDialog = true;
          break;
        case 'view':
          this.$store.state.pageId = this.customer.id;
          this.$router.push({path: '/customer', query: {id: encodedId}});
          break;
      }
    },
    takeUser(user, type) {
      this.appUser = JSON.parse(JSON.stringify(user));
      this.freeCustomer.appUserId = this.appUser.id;
      switch (type) {
        case 'edit':
          break;
        case 'link':
          this.linkDialog = true;
          break;
      }
    },

    handleUnlinkedSizeChange(newSize) {
      this.unlinkedFilter.pageSize = newSize;
      this.getUnlinkedUsers();
    },
    handleUnlinkedCurrentChange(newPage) {
      this.unlinkedFilter.currentPage = newPage;
      this.getUnlinkedUsers();
    },
    handleLinkedSizeChange(newSize) {
      this.linkedFilter.pageSize = newSize;
      this.getLinkedUsers();
    },
    handleLinkedCurrentChange(newPage) {
      this.linkedFilter.currentPage = newPage;
      this.getLinkedUsers();
    },

    getCustomerName(customer) {
      return customer?.firstName + " " + customer?.middleName ?? "" + " " + customer?.lastName ?? ""
    },
    formatAddress(customer) {
      const addressParts = [];
      if (customer.address1 && customer.address1.trim()) {
        addressParts.push(customer.address1.trim());
      }
      if (customer.address2 && customer.address2.trim()) {
        addressParts.push(customer.address2.trim());
      }
      if (customer.address3 && customer.address3.trim()) {
        addressParts.push(customer.address3.trim());
      }
      return addressParts.join(", ");
    },
    async unlink() {
      this.loading = true;
      this.unlinkDialog = false;
      try {
        let response = await customerService.unlink(this.customer.id);
        if (response?.data) {
          ShowMessage("success", "Customer Unlinked");
          await this.getUnlinkedUsers();
          await this.getLinkedUsers();
          await this.getFreeCustomers();
        }
      } catch (e) {
        ShowMessage("error", "Customer Unlink failed");
        console.log(e)
      }
      this.loading = false;
    },
    async link() {
      this.loading = true;
      this.linkDialog = false;
      try {
        this.freeCustomer.appUserId = this.appUser.id;

        // eslint-disable-next-line no-unreachable
        let response = await customerService.update(this.freeCustomer);
        if (response?.data) {
          ShowMessage("success", "AppUser Linked");
          await this.getUnlinkedUsers();
          await this.getLinkedUsers();
          await this.getFreeCustomers();
          this.resetCustomer();
        }
      } catch (e) {
        ShowMessage("error", "AppUser link failed");
        console.log(e)
      }
      this.loading = false;
    },
    async addCustomer(user) {
      this.loading = true;
      try {
        this.customer = {
          firstName: user.name,
          mobile: user.phone,
          email: user.email,
          isActive: true,
          isDeleted: false,
          appUserId: user.id,
          clientId: this.clientId,
          companyName: "",
          registrationNumber: "",
          address1: "",
          address2: "",
          address3: "",
          companyEmail: "",
          companyType: 166,
          vatRegistrationNumber: "",
          region: "",
          town: "",
          postCode: "",
          telephone: "",
          website: "",
          middleName: "",
          lastName: "",
          country: "",

        }
        let response = await customerService.add(this.customer);

        if (response?.data !== null) {
          ShowMessage("success", "App User Linked");
          await this.getUnlinkedUsers();
          await this.getLinkedUsers();
          await this.getFreeCustomers();
          this.resetCustomer();
        }
      } catch (e) {
        ShowMessage("error", e?.response?.data?.message ?? "Add Customer Failed");
      }
      this.loading = false;
    },
    async getUnlinkedUsers() {
      try {
        this.loading = true;
        let response = await syncAppService.getUnlinkedUsers(this.unlinkedFilter);
        this.unlinkedCustomers = response?.data?.items ?? [];
        this.unlinkedFilter.pageSize = response?.data?.pageSize ?? 10;
        this.unlinkedFilter.totalCount = response?.data?.totalCount ?? 0;
        this.unlinkedFilter.currentPage = response?.data?.currentPage ?? 0;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getLinkedUsers() {
      try {
        this.loading = true;
        let response = await syncAppService.getLinkedUsers(this.linkedFilter);
        this.linkedCustomers = response?.data?.items ?? [];
        this.linkedFilter.pageSize = response?.data?.pageSize ?? 10;
        this.linkedFilter.totalCount = response?.data?.totalCount ?? 0;
        this.linkedFilter.currentPage = response?.data?.currentPage ?? 0;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getFreeCustomers() {
      try {
        this.loading = true;
        let response = await syncAppService.getFreeCustomers(this.clientId);
        this.freeCustomers = response?.data ?? [];
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetCustomer() {
      this.freeCustomer = {
        firstName: "",
        mobile: "",
        email: "",
        isActive: true,
        isDeleted: false,
        appUserId: 0,
        clientId: this.clientId,
        companyName: "",
        registrationNumber: "",
        address1: "",
        address2: "",
        address3: "",
        companyEmail: "",
        companyType: 166,
        vatRegistrationNumber: "",
        region: "",
        town: "",
        postCode: "",
        telephone: "",
        website: "",
        middleName: "",
        lastName: "",
        country: "",

      }
    },

    //Auth Codes
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getClientUsers();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getClientUsers();
    },
    async deleteAuthCode(id) {
      this.loading = true;
      try {
        let response = await clientService.deleteAuthCode(id);
        if (response?.data) {
          await this.getAuthCodes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code deletion failed")
      }
      this.loading = false;
    },
    async getAuthCodes() {
      this.loading = true;
      try {
        let response = await clientService.getAuthCodes(this.filter);
        this.codes = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async enableAuthCode(id) {
      this.loading = true;
      try {
        let response = await clientService.activateAuthCode(id);
        if (response?.data) {
          await this.getAuthCodes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code enable failed")
      }
    },
    async disableAuthCode(id) {
      this.loading = true;
      try {
        let response = await clientService.deActivateAuthCode(id);
        if (response?.data) {
          await this.getAuthCodes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code disable failed")
      }
    },
    async addAuthCode() {
      this.loading = true;
      try {
        this.authCode.clientGuid = this.clientGuid;
        let response = await clientService.addAuthCode(this.authCode);
        this.codes.push(response?.data);
        this.resetAuthCode();
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code adding failed")
      }
      this.loading = false;
    },
    async updateAuthCode() {
      this.loading = true;
      try {
        let response = await clientService.updateAuthCode(this.authCode);
        if (response?.data !== null) {
          await this.getAuthCodes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code update failed")
      }
      this.loading = false;
    },
    resetAuthCode() {
      this.authCode = {
        "id": 0,
        "clientGuid": "",
        "authCode": "",
        "expiryDate": null,
        "isValid": true
      }
    },
  },
  created() {
    this.getUnlinkedUsers();
    this.getLinkedUsers();
    this.getFreeCustomers();
  }
}
</script>


<style scoped>
.bg-dashboard {
  background: #faf8fb !important;
  height: 100%;
}

.f-card-heading {
  font-size: 50px;
  font-weight: 500;
}

.cstm-span {
  line-height: normal !important;

}
</style>