<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" clear-id="customerId"
             :showSelect="false" :ShowAddBtn="false" routeName="customer"
             :btnText="getMessageByCode('add') ?? 'Add'">
      <template v-slot:select>
        <el-select v-if="clientId === null || clientId === 0" class="w-100" filterable
                   @change="getContacts()"
                   v-model="filter.clientId">
          <el-option
              v-for="item in clients"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
            <template #default>
              <div class="row">
                <el-tooltip v-if="item.name.length > 20" :content="item.name">
                  <span class="col-7">{{ getClientName(item.name) }}</span>
                </el-tooltip>
                <span v-else class="col-7">{{ getClientName(item.name) }}</span>
                <span class="text-small col-5">{{ item.market }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
        <el-button type="primary" class="ms-2" @click="addDialog = true; resetTrail()">
          {{ getMessageByCode('add') ?? "Add" }}
        </el-button>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row justify-content-end">
        <div class="row justify-content-end align-items center py-2">
          <div class="col-12 col-md-6 d-flex justify-content-end align-items-center">
            <div class="w-100">
              <span class="text-small">{{ getMessageByCode('category') ?? 'Category' }}</span>
              <el-select class="w-100" filterable @change="getContacts()"
                         v-model="filter.categoryId">
                <el-option
                    v-for="item in contactCategories"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                />
              </el-select>
            </div>
            <div class="w-100 ms-2">
              <span class="text-small">{{ getMessageByCode('contact') ?? 'Contact' }}</span>
              <el-select class="w-100" filterable @change="getTrails()"
                         v-model="filter.contactId">
                <el-option
                    v-for="item in contacts"
                    :key="item.id"
                    :label="item.firstName +' ' + item.surname ?? ''"
                    :value="item.id"
                >
                  <template #default>
                    <div class="row">
                      <span class="col-7">{{ item.firstName + ' ' + item.surname ?? '' }}</span>
                      <span class="text-small col-5">{{ item.category }}</span>
                    </div>
                  </template>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-12">
          <el-table :data="trails"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column :show-overflow-tooltip="true" :label="getMessageByCode('tenant') ?? 'Contact'"
                             prop="contact" min-width="120"
                             sortable>
            </el-table-column>
            <el-table-column sortable :show-overflow-tooltip="true" :label="getMessageByCode('property') ?? 'Property'"
                             prop="property" min-width="250"/>
            <el-table-column sortable :show-overflow-tooltip="true" :label="getMessageByCode('category') ?? 'Category'"
                             prop="category" min-width="120"/>
            <el-table-column :show-overflow-tooltip="true" sortable
                             :label="getMessageByCode('rent') ?? 'Rent'+ ' ('+currency+')'"
                             prop="amount" width="100">
              <template #default="scope">
                <div class="text-end">
                  <span>{{ scope.row.amount.toFixed(2) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column sortable :label="getMessageByCode('date') ?? 'Date'" prop="date" min-width="120">
              <template #default="scope">
                <span>{{ getFormattedDateTime(scope.row.addedDate) }}</span>
              </template>
            </el-table-column>

            <el-table-column sortable :label="getMessageByCode('income') ?? 'Income' + '?'" prop="in" min-width="100">
              <template #default="scope">
                <div class="text-center">
                  <n-tag size="small" :bordered="false" type="success" v-if="scope.row.in">
                    Yes
                  </n-tag>
                  <n-tag v-else size="small" :bordered="false" type="error">
                    No
                  </n-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions') ?? 'Actions'" width="100">
              <template #default="scope">
                <div class="d-flex justify-content-center">
                  <el-tooltip content="Export Pdf">
                    <i
                        class="mgc_pdf_line text-warning action-btn"
                        @click="takeTrail(scope.row,'pdf')"
                    ></i>
                  </el-tooltip>
                  <el-tooltip content="Edit">
                    <i @click="takeTrail(scope.row, 'edit')"
                       class="mgc_edit_line text-primary action-btn ms-2"
                    ></i>
                  </el-tooltip>
                  <el-tooltip content="Delete">
                    <i @click="takeTrail(scope.row, 'delete')"
                       class="mgc_delete_2_line text-danger action-btn ms-2"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!--    Dialogs-->
    <el-dialog v-model="addDialog" style="width: auto; max-width: 600px" :align-center="true"
               :title="trail.id < 1 ? getMessageByCode('addTrial') ?? 'Add Trail' : getMessageByCode('updateTrail') ?? 'Update Trail'">
      <template #default>
        <el-form class="row" label-position="top">
          <el-form-item v-if="clientId === null || clientId === 0" class="col-12"
                        :label="getMessageByCode('client') ?? 'Client'">
            <el-select class="w-100" filterable @change="getContacts('add');getPmsProperties();"
                       v-model="dialogFilter.clientId">
              <el-option
                  v-for="item in clients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('category') ?? 'Category'">
            <el-select class="w-100" filterable @change="getContacts('add')"
                       v-model="dialogFilter.categoryId" placeholder="Select Category">
              <el-option

                  v-for="item in contactCategories"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('contact') ?? 'Contact'">
            <el-select class="w-100" filterable
                       v-model="trail.contactId" placeholder="Select Contact">
              <el-option
                  v-for="item in dialogContacts"
                  :key="item.id"
                  :label="item.firstName +' ' + item.surname ?? ''"
                  :value="item.id"
              >
                <template #default>
                  <div class="row">
                    <span class="col-7">{{ item.firstName + ' ' + item.surname ?? '' }}</span>
                    <span class="text-small col-5">{{ item.category }}</span>
                  </div>
                </template>
              </el-option>
            </el-select>
            <el-collapse-transition>
              <span v-if="!validateForm.contactValid" class="text-small text-danger">Please select a contact</span>
            </el-collapse-transition>
          </el-form-item>

          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('property') ?? 'Property'">
            <el-select filterable v-model="trail.propertyId" clearable @clear="trail.propertyId = ''">
              <el-option
                  v-for="item in properties"
                  :key="item.id"
                  :label="item.propertyName"
                  :value="item.id"
              />
            </el-select>
            <el-collapse-transition>
              <span v-if="!validateForm.propertyValid" class="text-small text-danger">Please select a property</span>
            </el-collapse-transition>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('type') ?? 'Type'">
            <el-select v-model="trail.categoryId">
              <el-option
                  v-for="item in moneyCategories"
                  :key="item.optId"
                  :label="item.optTitle"
                  :value="item.optId"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('date') ?? 'Entry Date'">
            <el-date-picker class="w-100" :format="dateFormat" :editable="false"
                            v-model="trail.entryDate"></el-date-picker>
          </el-form-item>
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between">
            <el-form-item class="col-12 col-md-6 w-auto" :label="getMessageByCode('amount') ?? 'Amount'">
              <el-input
                  type="number"
                  v-model="formattedAmount"
              >
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-checkbox v-model="trail.in">In?</el-checkbox>
          </div>

          <el-form-item class="col-12" :label="getMessageByCode('description') ?? 'Description'">
            <el-input type="textarea" :rows="3" v-model="trail.description"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="d-flex justify-content-between w-100">
              <el-upload id="upload-photo" accept="image/*" :key="fileInputKey" :auto-upload="false"
                         @input="onFileInput" :show-file-list="false"
                         class="mb-0">
                <template #trigger>
                  <el-button bg text class="py-5 upload-btn">{{
                      getMessageByCode('selectFile') ?? 'Select File'
                    }}
                  </el-button>
                </template>
              </el-upload>
              <el-image v-if="trail.image" fit="fill" style="width: 125px; height: 95px" :src="trail.image"></el-image>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="addDialog = false" class="me-2">Cancel</el-button>
          <el-button v-if="trail.id < 1" type="primary" @click=" addTrail()">Add Trail</el-button>
          <el-button v-else type="primary" @click="updateTrail()">Update Trail</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
        v-model="deleteDialog"
        class="text-capitalize"
        :title="getMessageByCode('delete') ?? 'Delete'+' '+getMessageByCode('moneyTrail') ?? 'Money Trail'"
        width="30%"
    >
      <span>{{
          getMessageByCode('do_u_del') ?? 'Do you want to delete' + ' selected trail?'
        }} </span>
      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteDialog = false;softDelete()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getLanguage} from "@/Utility/getLanguage";
import moment from "moment/moment";
import configService from "@/Services/ComponentsServices/configService";
import moneyTrailService from "@/Services/ComponentsServices/moneyTrailService";
import contactService from "@/Services/ComponentsServices/contactService";
import {NTag} from "naive-ui";
import {ShowMessage} from "@/Utility/Utility";
import clientService from "@/Services/ComponentsServices/clientService";
import pmsPropertiesService from "@/Services/ComponentsServices/pmsPropertiesService";
import optionService from "@/Services/ComponentsServices/optionService";
import getClientName from "../../Utility/getClientName";

export default {
  name: "MoneyTrails",
  components: {NTag, ToolBar},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      isContact: true,
      addMoneyTrail: true,
      translations: [],
      dialogContacts: [],
      contacts: [
      ],
      clients: [],
      properties: [],
      addProperties: [],
      moneyCategories: [],
      contactCategories: [
        {id: 0, title: "All"}
      ],
      dialogContactCategories: [],
      addContacts: [],
      contactAddCategories: [],
      trails: [],
      dateFormat: "DD/MM/YYYY",
      currency: "£",
      currencyPosition: "left",
      clientId: Number(sessionStorage.getItem("clientId")) ?? 0,
      fileInputKey: new Date(),
      trail: {
        "id": 0,
        "tenantId": "",
        "contactId": 0,
        "clientId": 0,
        "propertyId": 0,
        "entryDate": new Date(),
        "categoryId": 0,
        "description": "",
        "amount": 0,
        "in": true,
        "notes": "",
        "isDeleted": false,
        "image": "",
      },
      dialogFilter: {
        clientId: Number(sessionStorage.getItem("clientId") ?? 0),
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1,
        clientGuid: 0,
        categoryId: 0,
        contactId: "",
      },
      filter: {
        clientId: Number(sessionStorage.getItem("clientId") ?? 0),
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1,
        clientGuid: 0,
        categoryId: 0,
        contactId: "",
      },
      validateForm: {
        contactValid: true,
        propertyValid: true,
      }
    }
  },
  computed: {
    formattedAmount: {
      get() {
        // Format the amount for display
        return this.trail.amount !== null
            ? this.trail.amount.toFixed(2)
            : '';
      },
      set(value) {
        // Remove formatting and update the actual amount
        this.trail.amount = parseFloat(value) || 0;
      },
    },
  },
  methods: {
    getClientName,
    formatAmount() {
      // Ensure the input is always displayed as two decimal places
      this.trail.amount = parseFloat(this.trail.amount.toFixed(2)) || 0;
    },
    getTenantName(item) {
      return item.firstName + ' ' + item.surname;
    },
    getContactName(item) {
      return item.firstName + ' ' + item.surname;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getTrails();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getTrails();
    },
    async takeTrail(trail, type) {
      this.trail = JSON.parse(JSON.stringify(trail));
      switch (type) {
        case 'edit':
          await this.getContacts('add');
          await this.getPmsProperties();
          this.addDialog = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
      }
    },
    getFormattedDateTime(date) {
      if (date === null || date === undefined) return "";
      const formatWithTime = `${this.dateFormat} hh:mm A`;
      return moment.utc(date).local().format(formatWithTime);
    },
    getFormattedTime(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format("LT")
    },
    getFormattedDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format(this.dateFormat);
    },
    async getTrails() {
      this.loading = true;
      try {
        let response = await moneyTrailService.getTrails(this.filter);
        this.trails = response?.data?.items.length > 0 ? response?.data?.items : [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
        if (this.contacts.length === 1) {
          this.trails = [];
          this.filter.totalCount = 0;
          this.filter.currentPage = 1;
          this.filter.pageSize = 10;
        }

        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },

    async softDelete() {
      this.loading = true;
      try {
        // eslint-disable-next-line no-unused-vars
        let response = await moneyTrailService.softDelete(this.trail.id);
        if (response?.data) {
          ShowMessage("success", "Money trail deleted");
          await this.getTrails();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async hardDelete() {
      this.loading = true;
      try {
        let response = await moneyTrailService.hardDelete(this.trail.id);
        console.log(response)
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async getContactCategories() {
      try {
        let response = await contactService.getAllCategories(this.clientId);
        this.contactCategories = this.contactCategories.concat(response?.data ?? []);
        if (this.contactCategories.length > 1) {
          this.dialogFilter.categoryId = this.contactCategories[0].id;
          await this.getContacts('add');
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getContacts(type) {
      this.loading = true;
      try {
        switch (type) {
          case 'add':
            // eslint-disable-next-line no-case-declarations
            let response1 = await contactService.getAllContacts(this.dialogFilter.clientId, this.dialogFilter.categoryId);
            this.dialogContacts = response1?.data?.items ?? [];
            if (this.dialogContacts.length > 0) {
              this.trail.contactId = this.dialogContacts[0].id;
            } else {
              this.trail.contactId = ""
            }
            break;
          default:
            // eslint-disable-next-line no-case-declarations
            let response2 = await contactService.getAllContacts(this.filter.clientId, this.filter.categoryId);
            this.contacts = [
            ];
            this.contacts = this.contacts.concat(response2?.data?.items ?? []);
            if (this.contacts.length > 0) {
              this.filter.contactId = this.contacts[0].id;

            } else {
              this.filter.contactId = 0;
            }
            await this.getTrails();
            break;
        }


      } catch (e) {
        ShowMessage("error", e?.response?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }
        let response = await configService.getByKey(id, "CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey(id, "CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getClients() {
      try {
        this.loading = true;
        if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
          let response = await clientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            this.filter.clientId = this.clients[0].id;
            this.dialogFilter.clientId = this.clients[0].id;
          }
        }
        await this.getContactCategories();
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async getPmsProperties() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.getAllProperties(this.dialogFilter.clientId);
        this.properties = response.data.items ?? [];
        if (this.properties.length > 0) {
          this.trail.propertyId = this.properties[0].id;
        } else {
          this.trail.propertyId = "";
        }

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    onFileInput(event) {
      const fileList = event.target.files;

      // Check if a file is selected
      if (fileList.length > 0) {
        const file = fileList.item(0); // Get the first file

        // Convert the file to Base64
        this.convertFileToBase64(file)
            .then((base64Data) => {
              this.trail.image = `data:${file.type};base64,${base64Data}`; // Store Base64 data
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            })
            .finally(() => {

            });
      }
    }
    ,

    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result.split(',')[1]); // Get the Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async getMoneyCategories() {
      try {
        this.loader = true;
        let response = await optionService.getOptionsByHeaderId(0, 46)
        this.moneyCategories = response.data ?? {};
        if (this.moneyCategories.length > 0) {
          this.trail.categoryId = this.moneyCategories[0].optId;

        }
        this.loader = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loader = false;
      }
    },
    async resetTrail() {
      this.trail = {
        "id": 0,
        "contactId": this.contacts[0]?.id ?? "",
        "propertyId": this.properties[0]?.id ?? "",
        "entryDate": new Date(),
        "categoryId": this.moneyCategories[0]?.optId ?? "",
        "description": "",
        "amount": 0,
        "in": true,
        "notes": "",
        "isDeleted": false,
        "image": "",
      };
      await this.getContacts('add');
      await this.getPmsProperties();
    },


    async addTrail() {
      this.loading = true;
      if (!this.validateFormMethod(this.trail)) {
        this.loading = false;
        return;
      }
      // eslint-disable-next-line no-unreachable
      try {
        if (this.trail.propertyId < 1) {
          this.trail.propertyId = 0
        }
        this.trail.clientId = this.dialogFilter.clientId;
        // eslint-disable-next-line no-unreachable
        let res = await moneyTrailService.addTrail(this.trail);
        if (res.status === 200) {
          ShowMessage("success", "Money Trail added");
          await this.getTrails();
        }
      } catch (e) {
        ShowMessage("error", e?.msg ?? "Money trail adding failed");
      }
      this.addDialog = false;
      this.loading = false;
    },
    async updateTrail() {
      this.loading = true;
      if (!this.validateFormMethod(this.trail)) {
        this.loading = false;
        return;
      }
      // eslint-disable-next-line no-unreachable
      try {
        if (this.trail.propertyId < 1) {
          this.trail.propertyId = 0
        }
        let res = await moneyTrailService.updateTrail(this.trail);
        if (res.status === 200) {
          ShowMessage("success", "Money Trail update");
          await this.getTrails();
        }
      } catch (e) {
        ShowMessage("error", e?.msg ?? "Money trail adding failed");
      }
      this.addDialog = false;
      this.loading = false;
    },
    validateFormMethod(formData) {
      let isValid = true;
      if (formData.contactId < 1) {
        this.validateForm.contactValid = false;
        isValid = false;
      }
      /*if (formData.propertyId < 1) {
        this.validateForm.propertyValid = false;
        isValid = false;
      }*/
      return isValid;
    }


  },
  created() {
    this.getContacts('');
    this.getConfigs();
    this.getClients();

    this.getMoneyCategories();
  }
}

</script>
<style scoped>

</style>